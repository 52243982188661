@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500&display=swap");

.text-reset:hover {
    color: var(--bs-primary) !important
}

.text-inherit {
    color: var(--bs-gray-800)
}

.text-inherit:hover {
    color: var(--bs-primary) !important
}

.text-white-stable {
    color: #fff !important
}

.text-inverse {
    filter: brightness(1) invert(0)
}

.text-white-inverse {
    filter: brightness(0) invert(1)
}

.icon-xxs {
    height: 1rem;
    line-height: 1rem;
    width: 1rem
}

.icon-xs {
    height: 1.5rem;
    line-height: 1.5rem;
    width: 1.5rem
}

.icon-sm {
    height: 2rem;
    line-height: 2rem;
    width: 2rem
}

.icon-md {
    height: 2.5rem;
    line-height: 2.5rem;
    width: 2.5rem
}

.icon-lg {
    height: 3rem;
    line-height: 3rem;
    width: 3rem
}

.icon-xl {
    height: 3.5rem;
    line-height: 3.5rem;
    width: 3.5rem
}

.icon-xxl {
    height: 4rem;
    line-height: 4rem;
    width: 4rem
}

.icon-xxxl {
    height: 7rem;
    line-height: 7rem;
    width: 7rem
}

.icon-shape {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    vertical-align: middle
}

.border-top-dashed {
    border-top: dashed !important
}

.border-bottom-dashed {
    border-bottom: dashed !important
}

.border-end-dashed {
    border-right: dashed !important
}

.border-start-dashed {
    border-left: dashed !important
}

.bg-primary-gradient {
    background: linear-gradient(180deg, #9b58ff, #8837ff 47.92%, #7a20ff)
}

.color-facebook {
    color: #1877f2
}

.color-twitter {
    color: #1da1f2
}

.color-github {
    color: #000
}

.color-google {
    color: #ea4335
}

.color-linkedin {
    color: #0077b5
}

.color-youtube {
    color: red
}

.color-slack {
    color: #611f69
}

.bg-facebook {
    background-color: #1877f2
}

.bg-twitter {
    background-color: #1da1f2
}

.bg-github {
    background-color: #000
}

.bg-google {
    background-color: #ea4335
}

.bg-linkedin {
    background-color: #0077b5
}

.bg-youtube {
    background-color: red
}

.bg-slack {
    background-color: #611f69
}

.bg-instagram {
    background-color: #405de6
}

.bg-primary-blend-gradient {
    background: var(--bg-gradient-blend, linear-gradient(0deg, #fff 0, hsla(0, 0%, 100%, 0) 100%), linear-gradient(245deg, rgba(108, 73, 250, .41) 0, rgba(211, 38, 142, .1) 100%))
}

@media (min-width:576px) {
    .border-sm {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-sm-0 {
        border: 0 !important
    }

    .border-top-sm {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-top-sm-0 {
        border-top: 0 !important
    }

    .border-end-sm {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-end-sm-0 {
        border-right: 0 !important
    }

    .border-bottom-sm {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-bottom-sm-0 {
        border-bottom: 0 !important
    }

    .border-start-sm {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-start-sm-0 {
        border-left: 0 !important
    }

    .border-sm-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important
    }

    .rounded-top-sm {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-sm-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-top-sm-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-top-sm-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-sm-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-top-sm-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-top-sm-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-top-sm-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-top-sm-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-end-sm {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-sm-0 {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-end-sm-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-end-sm-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-sm-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-end-sm-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-end-sm-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-end-sm-circle {
        border-bottom-right-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-end-sm-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-bottom-sm {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-sm-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-sm-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-bottom-sm-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-sm-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-bottom-sm-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-bottom-sm-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-bottom-sm-circle {
        border-bottom-left-radius: 50% !important;
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-sm-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-start-sm {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-sm-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important
    }

    .rounded-start-sm-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-start-sm-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-sm-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-start-sm-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-start-sm-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-start-sm-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important
    }

    .rounded-start-sm-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important
    }

    .w-sm-25 {
        width: 25% !important
    }

    .w-sm-50 {
        width: 50% !important
    }

    .w-sm-75 {
        width: 75% !important
    }

    .w-sm-100 {
        width: 100% !important
    }

    .w-sm-auto {
        width: auto !important
    }

    .mw-sm-100 {
        max-width: 100% !important
    }

    .vw-sm-100 {
        width: 100vw !important
    }

    .min-vw-sm-100 {
        min-width: 100vw !important
    }

    .h-sm-25 {
        height: 25% !important
    }

    .h-sm-50 {
        height: 50% !important
    }

    .h-sm-75 {
        height: 75% !important
    }

    .h-sm-100 {
        height: 100% !important
    }

    .h-sm-auto {
        height: auto !important
    }

    .mh-sm-100 {
        max-height: 100% !important
    }

    .min-vh-sm-100 {
        min-height: 100vh !important
    }

    .position-sm-static {
        position: static !important
    }

    .position-sm-relative {
        position: relative !important
    }

    .position-sm-absolute {
        position: absolute !important
    }

    .position-sm-fixed {
        position: fixed !important
    }

    .position-sm-sticky {
        position: sticky !important
    }

    .top-sm-0 {
        top: 0 !important
    }

    .top-sm-50 {
        top: 50% !important
    }

    .top-sm-100 {
        top: 100% !important
    }

    .bottom-sm-0 {
        bottom: 0 !important
    }

    .bottom-sm-50 {
        bottom: 50% !important
    }

    .bottom-sm-100 {
        bottom: 100% !important
    }

    .start-sm-0 {
        left: 0 !important
    }

    .start-sm-50 {
        left: 50% !important
    }

    .start-sm-100 {
        left: 100% !important
    }

    .translate-middle-sm {
        transform: translate(-50%, -50%) !important
    }

    .translate-middle-sm-x {
        transform: translateX(-50%) !important
    }

    .translate-middle-sm-y {
        transform: translateY(-50%) !important
    }
}

@media (min-width:768px) {
    .border-md {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-md-0 {
        border: 0 !important
    }

    .border-top-md {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-top-md-0 {
        border-top: 0 !important
    }

    .border-end-md {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-end-md-0 {
        border-right: 0 !important
    }

    .border-bottom-md {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-bottom-md-0 {
        border-bottom: 0 !important
    }

    .border-start-md {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-start-md-0 {
        border-left: 0 !important
    }

    .border-md-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important
    }

    .rounded-top-md {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-md-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-top-md-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-top-md-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-md-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-top-md-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-top-md-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-top-md-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-top-md-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-end-md {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-md-0 {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-end-md-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-end-md-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-md-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-end-md-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-end-md-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-end-md-circle {
        border-bottom-right-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-end-md-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-bottom-md {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-md-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-md-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-bottom-md-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-md-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-bottom-md-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-bottom-md-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-bottom-md-circle {
        border-bottom-left-radius: 50% !important;
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-md-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-start-md {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-md-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important
    }

    .rounded-start-md-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-start-md-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-md-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-start-md-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-start-md-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-start-md-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important
    }

    .rounded-start-md-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important
    }

    .w-md-25 {
        width: 25% !important
    }

    .w-md-50 {
        width: 50% !important
    }

    .w-md-75 {
        width: 75% !important
    }

    .w-md-100 {
        width: 100% !important
    }

    .w-md-auto {
        width: auto !important
    }

    .mw-md-100 {
        max-width: 100% !important
    }

    .vw-md-100 {
        width: 100vw !important
    }

    .min-vw-md-100 {
        min-width: 100vw !important
    }

    .h-md-25 {
        height: 25% !important
    }

    .h-md-50 {
        height: 50% !important
    }

    .h-md-75 {
        height: 75% !important
    }

    .h-md-100 {
        height: 100% !important
    }

    .h-md-auto {
        height: auto !important
    }

    .mh-md-100 {
        max-height: 100% !important
    }

    .min-vh-md-100 {
        min-height: 100vh !important
    }

    .position-md-static {
        position: static !important
    }

    .position-md-relative {
        position: relative !important
    }

    .position-md-absolute {
        position: absolute !important
    }

    .position-md-fixed {
        position: fixed !important
    }

    .position-md-sticky {
        position: sticky !important
    }

    .top-md-0 {
        top: 0 !important
    }

    .top-md-50 {
        top: 50% !important
    }

    .top-md-100 {
        top: 100% !important
    }

    .bottom-md-0 {
        bottom: 0 !important
    }

    .bottom-md-50 {
        bottom: 50% !important
    }

    .bottom-md-100 {
        bottom: 100% !important
    }

    .start-md-0 {
        left: 0 !important
    }

    .start-md-50 {
        left: 50% !important
    }

    .start-md-100 {
        left: 100% !important
    }

    .translate-middle-md {
        transform: translate(-50%, -50%) !important
    }

    .translate-middle-md-x {
        transform: translateX(-50%) !important
    }

    .translate-middle-md-y {
        transform: translateY(-50%) !important
    }
}

@media (min-width:992px) {
    .border-lg {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-lg-0 {
        border: 0 !important
    }

    .border-top-lg {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-top-lg-0 {
        border-top: 0 !important
    }

    .border-end-lg {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-end-lg-0 {
        border-right: 0 !important
    }

    .border-bottom-lg {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-bottom-lg-0 {
        border-bottom: 0 !important
    }

    .border-start-lg {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-start-lg-0 {
        border-left: 0 !important
    }

    .border-lg-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important
    }

    .rounded-top-lg {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-lg-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-top-lg-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-top-lg-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-lg-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-top-lg-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-top-lg-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-top-lg-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-top-lg-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-end-lg {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-lg-0 {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-end-lg-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-end-lg-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-lg-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-end-lg-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-end-lg-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-end-lg-circle {
        border-bottom-right-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-end-lg-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-bottom-lg {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-lg-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-lg-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-bottom-lg-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-lg-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-bottom-lg-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-bottom-lg-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-bottom-lg-circle {
        border-bottom-left-radius: 50% !important;
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-lg-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-start-lg {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-lg-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important
    }

    .rounded-start-lg-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-start-lg-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-lg-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-start-lg-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-start-lg-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-start-lg-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important
    }

    .rounded-start-lg-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important
    }

    .w-lg-25 {
        width: 25% !important
    }

    .w-lg-50 {
        width: 50% !important
    }

    .w-lg-75 {
        width: 75% !important
    }

    .w-lg-100 {
        width: 100% !important
    }

    .w-lg-auto {
        width: auto !important
    }

    .mw-lg-100 {
        max-width: 100% !important
    }

    .vw-lg-100 {
        width: 100vw !important
    }

    .min-vw-lg-100 {
        min-width: 100vw !important
    }

    .h-lg-25 {
        height: 25% !important
    }

    .h-lg-50 {
        height: 50% !important
    }

    .h-lg-75 {
        height: 75% !important
    }

    .h-lg-100 {
        height: 100% !important
    }

    .h-lg-auto {
        height: auto !important
    }

    .mh-lg-100 {
        max-height: 100% !important
    }

    .min-vh-lg-100 {
        min-height: 100vh !important
    }

    .position-lg-static {
        position: static !important
    }

    .position-lg-relative {
        position: relative !important
    }

    .position-lg-absolute {
        position: absolute !important
    }

    .position-lg-fixed {
        position: fixed !important
    }

    .position-lg-sticky {
        position: sticky !important
    }

    .top-lg-0 {
        top: 0 !important
    }

    .top-lg-50 {
        top: 50% !important
    }

    .top-lg-100 {
        top: 100% !important
    }

    .bottom-lg-0 {
        bottom: 0 !important
    }

    .bottom-lg-50 {
        bottom: 50% !important
    }

    .bottom-lg-100 {
        bottom: 100% !important
    }

    .start-lg-0 {
        left: 0 !important
    }

    .start-lg-50 {
        left: 50% !important
    }

    .start-lg-100 {
        left: 100% !important
    }

    .translate-middle-lg {
        transform: translate(-50%, -50%) !important
    }

    .translate-middle-lg-x {
        transform: translateX(-50%) !important
    }

    .translate-middle-lg-y {
        transform: translateY(-50%) !important
    }
}

@media (min-width:1200px) {
    .border-xl {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-xl-0 {
        border: 0 !important
    }

    .border-top-xl {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-top-xl-0 {
        border-top: 0 !important
    }

    .border-end-xl {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-end-xl-0 {
        border-right: 0 !important
    }

    .border-bottom-xl {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-bottom-xl-0 {
        border-bottom: 0 !important
    }

    .border-start-xl {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-start-xl-0 {
        border-left: 0 !important
    }

    .border-xl-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important
    }

    .rounded-top-xl {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-xl-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-top-xl-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-top-xl-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-xl-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-top-xl-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-top-xl-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-top-xl-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-top-xl-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-end-xl {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-xl-0 {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-end-xl-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-end-xl-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-xl-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-end-xl-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-end-xl-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-end-xl-circle {
        border-bottom-right-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-end-xl-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-bottom-xl {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-xl-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-xl-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-bottom-xl-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-xl-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-bottom-xl-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-bottom-xl-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-bottom-xl-circle {
        border-bottom-left-radius: 50% !important;
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-xl-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-start-xl {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-xl-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important
    }

    .rounded-start-xl-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-start-xl-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-xl-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-start-xl-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-start-xl-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-start-xl-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important
    }

    .rounded-start-xl-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important
    }

    .w-xl-25 {
        width: 25% !important
    }

    .w-xl-50 {
        width: 50% !important
    }

    .w-xl-75 {
        width: 75% !important
    }

    .w-xl-100 {
        width: 100% !important
    }

    .w-xl-auto {
        width: auto !important
    }

    .mw-xl-100 {
        max-width: 100% !important
    }

    .vw-xl-100 {
        width: 100vw !important
    }

    .min-vw-xl-100 {
        min-width: 100vw !important
    }

    .h-xl-25 {
        height: 25% !important
    }

    .h-xl-50 {
        height: 50% !important
    }

    .h-xl-75 {
        height: 75% !important
    }

    .h-xl-100 {
        height: 100% !important
    }

    .h-xl-auto {
        height: auto !important
    }

    .mh-xl-100 {
        max-height: 100% !important
    }

    .min-vh-xl-100 {
        min-height: 100vh !important
    }

    .position-xl-static {
        position: static !important
    }

    .position-xl-relative {
        position: relative !important
    }

    .position-xl-absolute {
        position: absolute !important
    }

    .position-xl-fixed {
        position: fixed !important
    }

    .position-xl-sticky {
        position: sticky !important
    }

    .top-xl-0 {
        top: 0 !important
    }

    .top-xl-50 {
        top: 50% !important
    }

    .top-xl-100 {
        top: 100% !important
    }

    .bottom-xl-0 {
        bottom: 0 !important
    }

    .bottom-xl-50 {
        bottom: 50% !important
    }

    .bottom-xl-100 {
        bottom: 100% !important
    }

    .start-xl-0 {
        left: 0 !important
    }

    .start-xl-50 {
        left: 50% !important
    }

    .start-xl-100 {
        left: 100% !important
    }

    .translate-middle-xl {
        transform: translate(-50%, -50%) !important
    }

    .translate-middle-xl-x {
        transform: translateX(-50%) !important
    }

    .translate-middle-xl-y {
        transform: translateY(-50%) !important
    }
}

@media (min-width:1400px) {
    .border-xxl {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-xxl-0 {
        border: 0 !important
    }

    .border-top-xxl {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-top-xxl-0 {
        border-top: 0 !important
    }

    .border-end-xxl {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-end-xxl-0 {
        border-right: 0 !important
    }

    .border-bottom-xxl {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-bottom-xxl-0 {
        border-bottom: 0 !important
    }

    .border-start-xxl {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-start-xxl-0 {
        border-left: 0 !important
    }

    .border-xxl-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important
    }

    .rounded-top-xxl {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-xxl-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-top-xxl-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-top-xxl-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-xxl-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-top-xxl-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-top-xxl-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-top-xxl-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-top-xxl-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-end-xxl {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-xxl-0 {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-end-xxl-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-end-xxl-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-xxl-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-end-xxl-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-end-xxl-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-end-xxl-circle {
        border-bottom-right-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-end-xxl-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-bottom-xxl {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-xxl-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-xxl-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-bottom-xxl-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-xxl-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-bottom-xxl-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-bottom-xxl-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-bottom-xxl-circle {
        border-bottom-left-radius: 50% !important;
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-xxl-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-start-xxl {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-xxl-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important
    }

    .rounded-start-xxl-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-start-xxl-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-xxl-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-start-xxl-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-start-xxl-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-start-xxl-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important
    }

    .rounded-start-xxl-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important
    }

    .w-xxl-25 {
        width: 25% !important
    }

    .w-xxl-50 {
        width: 50% !important
    }

    .w-xxl-75 {
        width: 75% !important
    }

    .w-xxl-100 {
        width: 100% !important
    }

    .w-xxl-auto {
        width: auto !important
    }

    .mw-xxl-100 {
        max-width: 100% !important
    }

    .vw-xxl-100 {
        width: 100vw !important
    }

    .min-vw-xxl-100 {
        min-width: 100vw !important
    }

    .h-xxl-25 {
        height: 25% !important
    }

    .h-xxl-50 {
        height: 50% !important
    }

    .h-xxl-75 {
        height: 75% !important
    }

    .h-xxl-100 {
        height: 100% !important
    }

    .h-xxl-auto {
        height: auto !important
    }

    .mh-xxl-100 {
        max-height: 100% !important
    }

    .min-vh-xxl-100 {
        min-height: 100vh !important
    }

    .position-xxl-static {
        position: static !important
    }

    .position-xxl-relative {
        position: relative !important
    }

    .position-xxl-absolute {
        position: absolute !important
    }

    .position-xxl-fixed {
        position: fixed !important
    }

    .position-xxl-sticky {
        position: sticky !important
    }

    .top-xxl-0 {
        top: 0 !important
    }

    .top-xxl-50 {
        top: 50% !important
    }

    .top-xxl-100 {
        top: 100% !important
    }

    .bottom-xxl-0 {
        bottom: 0 !important
    }

    .bottom-xxl-50 {
        bottom: 50% !important
    }

    .bottom-xxl-100 {
        bottom: 100% !important
    }

    .start-xxl-0 {
        left: 0 !important
    }

    .start-xxl-50 {
        left: 50% !important
    }

    .start-xxl-100 {
        left: 100% !important
    }

    .translate-middle-xxl {
        transform: translate(-50%, -50%) !important
    }

    .translate-middle-xxl-x {
        transform: translateX(-50%) !important
    }

    .translate-middle-xxl-y {
        transform: translateY(-50%) !important
    }
}

/*!
 * Bootstrap  v5.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
    --bs-blue: #ff7a59;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #db1436;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #008008;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #020617;
    --bs-gray: #475569;
    --bs-gray-dark: #1e293b;
    --bs-gray-100: #f1f5f9;
    --bs-gray-200: #e2e8f0;
    --bs-gray-300: #cbd5e1;
    --bs-gray-400: #94a3b8;
    --bs-gray-500: #64748b;
    --bs-gray-600: #475569;
    --bs-gray-700: #334155;
    --bs-gray-800: #1e293b;
    --bs-gray-900: #0f172a;
    --bs-primary: #ff7a59;
    --bs-secondary: #e2e8f0;
    --bs-success: #008008;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #db1436;
    --bs-light: #f1f5f9;
    --bs-dark: #0f172a;
    --bs-white: #fff;
    --bs-primary-rgb: 255, 122, 89;
    --bs-secondary-rgb: 226, 232, 240;
    --bs-success-rgb: 0, 128, 8;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 219, 20, 54;
    --bs-light-rgb: 241, 245, 249;
    --bs-dark-rgb: 15, 23, 42;
    --bs-primary-text-emphasis: #381866;
    --bs-secondary-text-emphasis: #5a5d60;
    --bs-success-text-emphasis: #003303;
    --bs-info-text-emphasis: #055160;
    --bs-warning-text-emphasis: #664d03;
    --bs-danger-text-emphasis: #580816;
    --bs-light-text-emphasis: #334155;
    --bs-dark-text-emphasis: #334155;
    --bs-primary-bg-subtle: #e8d8ff;
    --bs-secondary-bg-subtle: #f9fafc;
    --bs-success-bg-subtle: #cce6ce;
    --bs-info-bg-subtle: #cff4fc;
    --bs-warning-bg-subtle: #fff3cd;
    --bs-danger-bg-subtle: #f8d0d7;
    --bs-light-bg-subtle: #f8fafc;
    --bs-dark-bg-subtle: #94a3b8;
    --bs-primary-border-subtle: #d1b1ff;
    --bs-secondary-border-subtle: #f3f6f9;
    --bs-success-border-subtle: #99cc9c;
    --bs-info-border-subtle: #9eeaf9;
    --bs-warning-border-subtle: #ffe69c;
    --bs-danger-border-subtle: #f1a1af;
    --bs-light-border-subtle: #e2e8f0;
    --bs-dark-border-subtle: #64748b;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 2, 6, 23;
    --bs-font-sans-serif: "Public Sans", sans-serif;
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, hsla(0, 0%, 100%, .15), hsla(0, 0%, 100%, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #64748b;
    --bs-body-color-rgb: 100, 116, 139;
    --bs-body-bg: #fff;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-emphasis-color: #020617;
    --bs-emphasis-color-rgb: 2, 6, 23;
    --bs-secondary-color: rgba(100, 116, 139, .75);
    --bs-secondary-color-rgb: 100, 116, 139;
    --bs-secondary-bg: #e2e8f0;
    --bs-secondary-bg-rgb: 226, 232, 240;
    --bs-tertiary-color: rgba(100, 116, 139, .5);
    --bs-tertiary-color-rgb: 100, 116, 139;
    --bs-tertiary-bg: #f1f5f9;
    --bs-tertiary-bg-rgb: 241, 245, 249;
    --bs-heading-color: var(--bs-gray-800);
    --bs-link-color: #ff7a59;
    --bs-link-color-rgb: 255, 122, 89;
    --bs-link-decoration: none;
    --bs-link-hover-color: #ff7a59;
    --bs-link-hover-color-rgb: 111, 49, 204;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #e2e8f0;
    --bs-border-color-translucent: #e2e8f0;
    --bs-border-radius: 0.5rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.75rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-xxl: 2rem;
    --bs-border-radius-2xl: var(--bs-border-radius-xxl);
    --bs-border-radius-pill: 50rem;
    --bs-box-shadow: 0 0.5rem 1rem rgba(2, 6, 23, .15);
    --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(2, 6, 23, .075);
    --bs-box-shadow-lg: 0 1rem 3rem rgba(2, 6, 23, .175);
    --bs-box-shadow-inset: inset 0 1px 2px rgba(2, 6, 23, .075);
    --bs-focus-ring-width: 0.25rem;
    --bs-focus-ring-opacity: 0.25;
    --bs-focus-ring-color: rgba(255, 122, 89, .25);
    --bs-form-valid-color: #008008;
    --bs-form-valid-border-color: #008008;
    --bs-form-invalid-color: #db1436;
    --bs-form-invalid-border-color: #db1436
}

[data-bs-theme=dark] {
    --bs-body-color: #64748b;
    --bs-body-color-rgb: 100, 116, 139;
    --bs-body-bg: #020617;
    --bs-body-bg-rgb: 2, 6, 23;
    --bs-emphasis-color: #fff;
    --bs-emphasis-color-rgb: 255, 255, 255;
    --bs-secondary-color: rgba(100, 116, 139, .75);
    --bs-secondary-color-rgb: 100, 116, 139;
    --bs-secondary-bg: #1e293b;
    --bs-secondary-bg-rgb: 30, 41, 59;
    --bs-tertiary-color: #475569;
    --bs-tertiary-color-rgb: 71, 85, 105;
    --bs-tertiary-bg: #172033;
    --bs-tertiary-bg-rgb: 23, 32, 51;
    --bs-primary-text-emphasis: #b98bff;
    --bs-secondary-text-emphasis: #eef1f6;
    --bs-success-text-emphasis: #66b36b;
    --bs-info-text-emphasis: #6edff6;
    --bs-warning-text-emphasis: #ffda6a;
    --bs-danger-text-emphasis: #e97286;
    --bs-light-text-emphasis: #f1f5f9;
    --bs-dark-text-emphasis: #cbd5e1;
    --bs-primary-bg-subtle: #532599;
    --bs-secondary-bg-subtle: #2d2e30;
    --bs-success-bg-subtle: #001a02;
    --bs-info-bg-subtle: #032830;
    --bs-warning-bg-subtle: #332701;
    --bs-danger-bg-subtle: #2c040b;
    --bs-light-bg-subtle: #0f172a;
    --bs-dark-bg-subtle: #101829;
    --bs-primary-border-subtle: #532599;
    --bs-secondary-border-subtle: #888b90;
    --bs-success-border-subtle: #004d05;
    --bs-info-border-subtle: #087990;
    --bs-warning-border-subtle: #997404;
    --bs-danger-border-subtle: #830c20;
    --bs-light-border-subtle: #334155;
    --bs-dark-border-subtle: #1e293b;
    --bs-heading-color: #e2e8f0;
    --bs-link-color: #b98bff;
    --bs-link-hover-color: #c7a2ff;
    --bs-link-color-rgb: 185, 139, 255;
    --bs-link-hover-color-rgb: 199, 162, 255;
    --bs-code-color: #e685b5;
    --bs-border-color: #334155;
    --bs-border-color-translucent: #1e293b;
    --bs-form-valid-color: #66b36b;
    --bs-form-valid-border-color: #66b36b;
    --bs-form-invalid-color: #e97286;
    --bs-form-invalid-border-color: #e97286;
    color-scheme: dark
}

*,
:after,
:before {
    box-sizing: border-box
}

@media (prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: smooth
    }
}

body {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(2, 6, 23, 0);
    background-color: var(--bs-body-bg);
    color: var(--bs-body-color);
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    margin: 0;
    text-align: var(--bs-body-text-align)
}

hr {
    border: 0;
    border-top: var(--bs-border-width) solid var(--bs-border-color);
    color: inherit;
    margin: 1rem 0;
    opacity: 1
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--bs-heading-color);
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: .5rem;
    margin-top: 0
}

.h1,
h1 {
    font-size: calc(1.375rem + 1.5vw)
}

@media (min-width:1200px) {

    .h1,
    h1 {
        font-size: 2.5rem
    }
}

.h2,
h2 {
    font-size: calc(1.325rem + .9vw)
}

@media (min-width:1200px) {

    .h2,
    h2 {
        font-size: 2rem
    }
}

.h3,
h3 {
    font-size: calc(1.275rem + .3vw)
}

@media (min-width:1200px) {

    .h3,
    h3 {
        font-size: 1.5rem
    }
}

.h4,
h4 {
    font-size: 1.25rem
}

.h5,
h5 {
    font-size: 1rem
}

.h6,
h6 {
    font-size: .875rem
}

p {
    margin-bottom: 1rem;
    margin-top: 0
}

abbr[title] {
    cursor: help;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    font-style: normal;
    line-height: inherit;
    margin-bottom: 1rem
}

ol,
ul {
    padding-left: 2rem
}

dl,
ol,
ul {
    margin-bottom: 1rem;
    margin-top: 0
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

.small,
small {
    font-size: .875em
}

.mark,
mark {
    background-color: var(--bs-highlight-bg);
    padding: .1875em
}

sub,
sup {
    font-size: .75em;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
    text-decoration: none
}

a:hover {
    --bs-link-color-rgb: var(--bs-link-hover-color-rgb)
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

code,
kbd,
pre,
samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em
}

pre {
    display: block;
    font-size: .875em;
    margin-bottom: 1rem;
    margin-top: 0;
    overflow: auto
}

pre code {
    color: inherit;
    font-size: inherit;
    word-break: normal
}

code {
    word-wrap: break-word;
    color: var(--bs-code-color);
    font-size: .875em
}

a>code {
    color: inherit
}

kbd {
    background-color: var(--bs-body-color);
    border-radius: .25rem;
    color: var(--bs-body-bg);
    font-size: .875em;
    padding: .1875rem .375rem
}

kbd kbd {
    font-size: 1em;
    padding: 0
}

figure {
    margin: 0 0 1rem
}

img,
svg {
    vertical-align: middle
}

table {
    border-collapse: collapse;
    caption-side: bottom
}

caption {
    color: var(--bs-secondary-color);
    padding-bottom: .5rem;
    padding-top: .5rem;
    text-align: left
}

th {
    font-weight: 400;
    text-align: inherit;
    text-align: -webkit-match-parent
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border: 0 solid;
    border-color: inherit
}

label {
    display: inline-block
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0
}

button,
select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

select:disabled {
    opacity: 1
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
    display: none !important
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

::-moz-focus-inner {
    border-style: none;
    padding: 0
}

textarea {
    resize: vertical
}

fieldset {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0
}

legend {
    float: left;
    font-size: calc(1.275rem + .3vw);
    line-height: inherit;
    margin-bottom: .5rem;
    padding: 0;
    width: 100%
}

@media (min-width:1200px) {
    legend {
        font-size: 1.5rem
    }
}

legend+* {
    clear: left
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::file-selector-button {
    -webkit-appearance: button;
    font: inherit
}

output {
    display: inline-block
}

iframe {
    border: 0
}

summary {
    cursor: pointer;
    display: list-item
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none !important
}

.lead {
    font-size: 1.25rem;
    font-weight: 500
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 700;
    line-height: 1.25
}

@media (min-width:1200px) {
    .display-1 {
        font-size: 5rem
    }
}

.display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 700;
    line-height: 1.25
}

@media (min-width:1200px) {
    .display-2 {
        font-size: 4.5rem
    }
}

.display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 700;
    line-height: 1.25
}

@media (min-width:1200px) {
    .display-3 {
        font-size: 4rem
    }
}

.display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 700;
    line-height: 1.25
}

@media (min-width:1200px) {
    .display-4 {
        font-size: 3.5rem
    }
}

.display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 700;
    line-height: 1.25
}

@media (min-width:1200px) {
    .display-5 {
        font-size: 3rem
    }
}

.display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 700;
    line-height: 1.25
}

@media (min-width:1200px) {
    .display-6 {
        font-size: 2.5rem
    }
}

.list-inline,
.list-unstyled {
    list-style: none;
    padding-left: 0
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: .875em;
    text-transform: uppercase
}

.blockquote {
    font-size: 1.25rem;
    margin-bottom: 1rem
}

.blockquote>:last-child {
    margin-bottom: 0
}

.blockquote-footer {
    color: #475569;
    font-size: .875em;
    margin-bottom: 1rem;
    margin-top: -1rem
}

.blockquote-footer:before {
    content: "— "
}

.img-fluid,
.img-thumbnail {
    height: auto;
    max-width: 100%
}

.img-thumbnail {
    background-color: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius-lg);
    padding: .25rem
}

.figure {
    display: inline-block
}

.figure-img {
    line-height: 1;
    margin-bottom: .5rem
}

.figure-caption {
    color: var(--bs-secondary-color);
    font-size: .875em
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    --bs-gutter-x: 2.5rem;
    --bs-gutter-y: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--bs-gutter-x)*.5);
    padding-right: calc(var(--bs-gutter-x)*.5);
    width: 100%
}

@media (min-width:576px) {

    .container,
    .container-sm {
        max-width: 540px
    }
}

@media (min-width:768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 720px
    }
}

@media (min-width:992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px
    }
}

@media (min-width:1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px
    }
}

@media (min-width:1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1320px
    }
}

:root {
    --bs-breakpoint-xs: 0;
    --bs-breakpoint-sm: 576px;
    --bs-breakpoint-md: 768px;
    --bs-breakpoint-lg: 992px;
    --bs-breakpoint-xl: 1200px;
    --bs-breakpoint-xxl: 1400px
}

.row {
    --bs-gutter-x: 2rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--bs-gutter-x)*-.5);
    margin-right: calc(var(--bs-gutter-x)*-.5);
    margin-top: calc(var(--bs-gutter-y)*-1)
}

.row>* {
    flex-shrink: 0;
    margin-top: var(--bs-gutter-y);
    max-width: 100%;
    padding-left: calc(var(--bs-gutter-x)*.5);
    padding-right: calc(var(--bs-gutter-x)*.5);
    width: 100%
}

.col {
    flex: 1 0 0%
}

.row-cols-auto>* {
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
}

.row-cols-4>* {
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333333%
}

.col-2 {
    flex: 0 0 auto;
    width: 16.66666667%
}

.col-3 {
    flex: 0 0 auto;
    width: 25%
}

.col-4 {
    flex: 0 0 auto;
    width: 33.33333333%
}

.col-5 {
    flex: 0 0 auto;
    width: 41.66666667%
}

.col-6 {
    flex: 0 0 auto;
    width: 50%
}

.col-7 {
    flex: 0 0 auto;
    width: 58.33333333%
}

.col-8 {
    flex: 0 0 auto;
    width: 66.66666667%
}

.col-9 {
    flex: 0 0 auto;
    width: 75%
}

.col-10 {
    flex: 0 0 auto;
    width: 83.33333333%
}

.col-11 {
    flex: 0 0 auto;
    width: 91.66666667%
}

.col-12 {
    flex: 0 0 auto;
    width: 100%
}

.offset-1 {
    margin-left: 8.33333333%
}

.offset-2 {
    margin-left: 16.66666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333333%
}

.offset-5 {
    margin-left: 41.66666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333333%
}

.offset-8 {
    margin-left: 66.66666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333333%
}

.offset-11 {
    margin-left: 91.66666667%
}

.g-0,
.gx-0 {
    --bs-gutter-x: 0
}

.g-0,
.gy-0 {
    --bs-gutter-y: 0
}

.g-1,
.gx-1 {
    --bs-gutter-x: 0.25rem
}

.g-1,
.gy-1 {
    --bs-gutter-y: 0.25rem
}

.g-2,
.gx-2 {
    --bs-gutter-x: 0.5rem
}

.g-2,
.gy-2 {
    --bs-gutter-y: 0.5rem
}

.g-3,
.gx-3 {
    --bs-gutter-x: 1rem
}

.g-3,
.gy-3 {
    --bs-gutter-y: 1rem
}

.g-4,
.gx-4 {
    --bs-gutter-x: 1.5rem
}

.g-4,
.gy-4 {
    --bs-gutter-y: 1.5rem
}

.g-5,
.gx-5 {
    --bs-gutter-x: 2rem
}

.g-5,
.gy-5 {
    --bs-gutter-y: 2rem
}

.g-6,
.gx-6 {
    --bs-gutter-x: 2.5rem
}

.g-6,
.gy-6 {
    --bs-gutter-y: 2.5rem
}

.g-7,
.gx-7 {
    --bs-gutter-x: 3rem
}

.g-7,
.gy-7 {
    --bs-gutter-y: 3rem
}

.g-8,
.gx-8 {
    --bs-gutter-x: 4rem
}

.g-8,
.gy-8 {
    --bs-gutter-y: 4rem
}

.g-9,
.gx-9 {
    --bs-gutter-x: 5rem
}

.g-9,
.gy-9 {
    --bs-gutter-y: 5rem
}

.g-10,
.gx-10 {
    --bs-gutter-x: 6rem
}

.g-10,
.gy-10 {
    --bs-gutter-y: 6rem
}

@media (min-width:576px) {
    .col-sm {
        flex: 1 0 0%
    }

    .row-cols-sm-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-sm-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-sm-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-sm-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-sm-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-sm-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333333%
    }

    .offset-sm-2 {
        margin-left: 16.66666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333333%
    }

    .offset-sm-5 {
        margin-left: 41.66666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333333%
    }

    .offset-sm-8 {
        margin-left: 66.66666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333333%
    }

    .offset-sm-11 {
        margin-left: 91.66666667%
    }

    .g-sm-0,
    .gx-sm-0 {
        --bs-gutter-x: 0
    }

    .g-sm-0,
    .gy-sm-0 {
        --bs-gutter-y: 0
    }

    .g-sm-1,
    .gx-sm-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-sm-1,
    .gy-sm-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-sm-2,
    .gx-sm-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-sm-2,
    .gy-sm-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-sm-3,
    .gx-sm-3 {
        --bs-gutter-x: 1rem
    }

    .g-sm-3,
    .gy-sm-3 {
        --bs-gutter-y: 1rem
    }

    .g-sm-4,
    .gx-sm-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-sm-4,
    .gy-sm-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-sm-5,
    .gx-sm-5 {
        --bs-gutter-x: 2rem
    }

    .g-sm-5,
    .gy-sm-5 {
        --bs-gutter-y: 2rem
    }

    .g-sm-6,
    .gx-sm-6 {
        --bs-gutter-x: 2.5rem
    }

    .g-sm-6,
    .gy-sm-6 {
        --bs-gutter-y: 2.5rem
    }

    .g-sm-7,
    .gx-sm-7 {
        --bs-gutter-x: 3rem
    }

    .g-sm-7,
    .gy-sm-7 {
        --bs-gutter-y: 3rem
    }

    .g-sm-8,
    .gx-sm-8 {
        --bs-gutter-x: 4rem
    }

    .g-sm-8,
    .gy-sm-8 {
        --bs-gutter-y: 4rem
    }

    .g-sm-9,
    .gx-sm-9 {
        --bs-gutter-x: 5rem
    }

    .g-sm-9,
    .gy-sm-9 {
        --bs-gutter-y: 5rem
    }

    .g-sm-10,
    .gx-sm-10 {
        --bs-gutter-x: 6rem
    }

    .g-sm-10,
    .gy-sm-10 {
        --bs-gutter-y: 6rem
    }
}

@media (min-width:768px) {
    .col-md {
        flex: 1 0 0%
    }

    .row-cols-md-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-md-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-md-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-md-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-md-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-md-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-md-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-md-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-md-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-md-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-md-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333333%
    }

    .offset-md-2 {
        margin-left: 16.66666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333333%
    }

    .offset-md-5 {
        margin-left: 41.66666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333333%
    }

    .offset-md-8 {
        margin-left: 66.66666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333333%
    }

    .offset-md-11 {
        margin-left: 91.66666667%
    }

    .g-md-0,
    .gx-md-0 {
        --bs-gutter-x: 0
    }

    .g-md-0,
    .gy-md-0 {
        --bs-gutter-y: 0
    }

    .g-md-1,
    .gx-md-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-md-1,
    .gy-md-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-md-2,
    .gx-md-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-md-2,
    .gy-md-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-md-3,
    .gx-md-3 {
        --bs-gutter-x: 1rem
    }

    .g-md-3,
    .gy-md-3 {
        --bs-gutter-y: 1rem
    }

    .g-md-4,
    .gx-md-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-md-4,
    .gy-md-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-md-5,
    .gx-md-5 {
        --bs-gutter-x: 2rem
    }

    .g-md-5,
    .gy-md-5 {
        --bs-gutter-y: 2rem
    }

    .g-md-6,
    .gx-md-6 {
        --bs-gutter-x: 2.5rem
    }

    .g-md-6,
    .gy-md-6 {
        --bs-gutter-y: 2.5rem
    }

    .g-md-7,
    .gx-md-7 {
        --bs-gutter-x: 3rem
    }

    .g-md-7,
    .gy-md-7 {
        --bs-gutter-y: 3rem
    }

    .g-md-8,
    .gx-md-8 {
        --bs-gutter-x: 4rem
    }

    .g-md-8,
    .gy-md-8 {
        --bs-gutter-y: 4rem
    }

    .g-md-9,
    .gx-md-9 {
        --bs-gutter-x: 5rem
    }

    .g-md-9,
    .gy-md-9 {
        --bs-gutter-y: 5rem
    }

    .g-md-10,
    .gx-md-10 {
        --bs-gutter-x: 6rem
    }

    .g-md-10,
    .gy-md-10 {
        --bs-gutter-y: 6rem
    }
}

@media (min-width:992px) {
    .col-lg {
        flex: 1 0 0%
    }

    .row-cols-lg-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-lg-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }

    .g-lg-0,
    .gx-lg-0 {
        --bs-gutter-x: 0
    }

    .g-lg-0,
    .gy-lg-0 {
        --bs-gutter-y: 0
    }

    .g-lg-1,
    .gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-lg-1,
    .gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-lg-2,
    .gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-lg-2,
    .gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-lg-3,
    .gx-lg-3 {
        --bs-gutter-x: 1rem
    }

    .g-lg-3,
    .gy-lg-3 {
        --bs-gutter-y: 1rem
    }

    .g-lg-4,
    .gx-lg-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-lg-4,
    .gy-lg-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-lg-5,
    .gx-lg-5 {
        --bs-gutter-x: 2rem
    }

    .g-lg-5,
    .gy-lg-5 {
        --bs-gutter-y: 2rem
    }

    .g-lg-6,
    .gx-lg-6 {
        --bs-gutter-x: 2.5rem
    }

    .g-lg-6,
    .gy-lg-6 {
        --bs-gutter-y: 2.5rem
    }

    .g-lg-7,
    .gx-lg-7 {
        --bs-gutter-x: 3rem
    }

    .g-lg-7,
    .gy-lg-7 {
        --bs-gutter-y: 3rem
    }

    .g-lg-8,
    .gx-lg-8 {
        --bs-gutter-x: 4rem
    }

    .g-lg-8,
    .gy-lg-8 {
        --bs-gutter-y: 4rem
    }

    .g-lg-9,
    .gx-lg-9 {
        --bs-gutter-x: 5rem
    }

    .g-lg-9,
    .gy-lg-9 {
        --bs-gutter-y: 5rem
    }

    .g-lg-10,
    .gx-lg-10 {
        --bs-gutter-x: 6rem
    }

    .g-lg-10,
    .gy-lg-10 {
        --bs-gutter-y: 6rem
    }
}

@media (min-width:1200px) {
    .col-xl {
        flex: 1 0 0%
    }

    .row-cols-xl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333333%
    }

    .offset-xl-2 {
        margin-left: 16.66666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333333%
    }

    .offset-xl-5 {
        margin-left: 41.66666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333333%
    }

    .offset-xl-8 {
        margin-left: 66.66666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333333%
    }

    .offset-xl-11 {
        margin-left: 91.66666667%
    }

    .g-xl-0,
    .gx-xl-0 {
        --bs-gutter-x: 0
    }

    .g-xl-0,
    .gy-xl-0 {
        --bs-gutter-y: 0
    }

    .g-xl-1,
    .gx-xl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xl-1,
    .gy-xl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xl-2,
    .gx-xl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xl-2,
    .gy-xl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xl-3,
    .gx-xl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xl-3,
    .gy-xl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xl-4,
    .gx-xl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xl-4,
    .gy-xl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xl-5,
    .gx-xl-5 {
        --bs-gutter-x: 2rem
    }

    .g-xl-5,
    .gy-xl-5 {
        --bs-gutter-y: 2rem
    }

    .g-xl-6,
    .gx-xl-6 {
        --bs-gutter-x: 2.5rem
    }

    .g-xl-6,
    .gy-xl-6 {
        --bs-gutter-y: 2.5rem
    }

    .g-xl-7,
    .gx-xl-7 {
        --bs-gutter-x: 3rem
    }

    .g-xl-7,
    .gy-xl-7 {
        --bs-gutter-y: 3rem
    }

    .g-xl-8,
    .gx-xl-8 {
        --bs-gutter-x: 4rem
    }

    .g-xl-8,
    .gy-xl-8 {
        --bs-gutter-y: 4rem
    }

    .g-xl-9,
    .gx-xl-9 {
        --bs-gutter-x: 5rem
    }

    .g-xl-9,
    .gy-xl-9 {
        --bs-gutter-y: 5rem
    }

    .g-xl-10,
    .gx-xl-10 {
        --bs-gutter-x: 6rem
    }

    .g-xl-10,
    .gy-xl-10 {
        --bs-gutter-y: 6rem
    }
}

@media (min-width:1400px) {
    .col-xxl {
        flex: 1 0 0%
    }

    .row-cols-xxl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xxl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%
    }

    .g-xxl-0,
    .gx-xxl-0 {
        --bs-gutter-x: 0
    }

    .g-xxl-0,
    .gy-xxl-0 {
        --bs-gutter-y: 0
    }

    .g-xxl-1,
    .gx-xxl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xxl-1,
    .gy-xxl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xxl-2,
    .gx-xxl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xxl-2,
    .gy-xxl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xxl-3,
    .gx-xxl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xxl-3,
    .gy-xxl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xxl-4,
    .gx-xxl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xxl-4,
    .gy-xxl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xxl-5,
    .gx-xxl-5 {
        --bs-gutter-x: 2rem
    }

    .g-xxl-5,
    .gy-xxl-5 {
        --bs-gutter-y: 2rem
    }

    .g-xxl-6,
    .gx-xxl-6 {
        --bs-gutter-x: 2.5rem
    }

    .g-xxl-6,
    .gy-xxl-6 {
        --bs-gutter-y: 2.5rem
    }

    .g-xxl-7,
    .gx-xxl-7 {
        --bs-gutter-x: 3rem
    }

    .g-xxl-7,
    .gy-xxl-7 {
        --bs-gutter-y: 3rem
    }

    .g-xxl-8,
    .gx-xxl-8 {
        --bs-gutter-x: 4rem
    }

    .g-xxl-8,
    .gy-xxl-8 {
        --bs-gutter-y: 4rem
    }

    .g-xxl-9,
    .gx-xxl-9 {
        --bs-gutter-x: 5rem
    }

    .g-xxl-9,
    .gy-xxl-9 {
        --bs-gutter-y: 5rem
    }

    .g-xxl-10,
    .gx-xxl-10 {
        --bs-gutter-x: 6rem
    }

    .g-xxl-10,
    .gy-xxl-10 {
        --bs-gutter-y: 6rem
    }
}

.table {
    --bs-table-color-type: initial;
    --bs-table-bg-type: initial;
    --bs-table-color-state: initial;
    --bs-table-bg-state: initial;
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: transparent;
    --bs-table-border-color: var(--bs-border-color);
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-striped-bg: var(--bs-gray-100);
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-active-bg: rgba(2, 6, 23, .1);
    --bs-table-hover-color: var(--bs-body-color);
    --bs-table-hover-bg: rgba(2, 6, 23, .075);
    border-color: var(--bs-table-border-color);
    margin-bottom: 1rem;
    vertical-align: top;
    width: 100%
}

.table>:not(caption)>*>* {
    background-color: var(--bs-table-bg);
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
    color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
    padding: .5rem
}

.table>tbody {
    vertical-align: inherit
}

.table>thead {
    vertical-align: bottom
}

.table-group-divider {
    border-top: calc(var(--bs-border-width)*2) solid
}

.caption-top {
    caption-side: top
}

.table-sm>:not(caption)>*>* {
    padding: .25rem
}

.table-bordered>:not(caption)>* {
    border-width: var(--bs-border-width) 0
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 var(--bs-border-width)
}

.table-borderless>:not(caption)>*>* {
    border-bottom-width: 0
}

.table-borderless>:not(:first-child) {
    border-top-width: 0
}

.table-striped-columns>:not(caption)>tr>:nth-child(2n),
.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-color-type: var(--bs-table-striped-color);
    --bs-table-bg-type: var(--bs-table-striped-bg)
}

.table-active {
    --bs-table-color-state: var(--bs-table-active-color);
    --bs-table-bg-state: var(--bs-table-active-bg)
}

.table-hover>tbody>tr:hover>* {
    --bs-table-color-state: var(--bs-table-hover-color);
    --bs-table-bg-state: var(--bs-table-hover-bg)
}

.table-primary {
    --bs-table-color: #020617;
    --bs-table-bg: #e8d8ff;
    --bs-table-border-color: #d1c3e8;
    --bs-table-striped-bg: #ddcef3;
    --bs-table-striped-color: #020617;
    --bs-table-active-bg: #d1c3e8;
    --bs-table-active-color: #020617;
    --bs-table-hover-bg: #d7c8ee;
    --bs-table-hover-color: #020617
}

.table-primary,
.table-secondary {
    border-color: var(--bs-table-border-color);
    color: var(--bs-table-color)
}

.table-secondary {
    --bs-table-color: #020617;
    --bs-table-bg: #f9fafc;
    --bs-table-border-color: #e0e2e5;
    --bs-table-striped-bg: #edeef1;
    --bs-table-striped-color: #020617;
    --bs-table-active-bg: #e0e2e5;
    --bs-table-active-color: #020617;
    --bs-table-hover-bg: #e6e8eb;
    --bs-table-hover-color: #020617
}

.table-success {
    --bs-table-color: #020617;
    --bs-table-bg: #cce6ce;
    --bs-table-border-color: #b8d0bc;
    --bs-table-striped-bg: #c2dbc5;
    --bs-table-striped-color: #020617;
    --bs-table-active-bg: #b8d0bc;
    --bs-table-active-color: #020617;
    --bs-table-hover-bg: #bdd5c0;
    --bs-table-hover-color: #020617
}

.table-info,
.table-success {
    border-color: var(--bs-table-border-color);
    color: var(--bs-table-color)
}

.table-info {
    --bs-table-color: #020617;
    --bs-table-bg: #cff4fc;
    --bs-table-border-color: #bbdce5;
    --bs-table-striped-bg: #c5e8f1;
    --bs-table-striped-color: #020617;
    --bs-table-active-bg: #bbdce5;
    --bs-table-active-color: #020617;
    --bs-table-hover-bg: #c0e2eb;
    --bs-table-hover-color: #020617
}

.table-warning {
    --bs-table-color: #020617;
    --bs-table-bg: #fff3cd;
    --bs-table-border-color: #e6dbbb;
    --bs-table-striped-bg: #f2e7c4;
    --bs-table-striped-color: #020617;
    --bs-table-active-bg: #e6dbbb;
    --bs-table-active-color: #020617;
    --bs-table-hover-bg: #ece1bf;
    --bs-table-hover-color: #020617
}

.table-danger,
.table-warning {
    border-color: var(--bs-table-border-color);
    color: var(--bs-table-color)
}

.table-danger {
    --bs-table-color: #020617;
    --bs-table-bg: #f8d0d7;
    --bs-table-border-color: #dfbcc4;
    --bs-table-striped-bg: #ecc6cd;
    --bs-table-striped-color: #020617;
    --bs-table-active-bg: #dfbcc4;
    --bs-table-active-color: #020617;
    --bs-table-hover-bg: #e6c1c9;
    --bs-table-hover-color: #020617
}

.table-light {
    --bs-table-color: #020617;
    --bs-table-bg: #f1f5f9;
    --bs-table-border-color: #d9dde2;
    --bs-table-striped-bg: #e5e9ee;
    --bs-table-striped-color: #020617;
    --bs-table-active-bg: #d9dde2;
    --bs-table-active-color: #020617;
    --bs-table-hover-bg: #dfe3e8;
    --bs-table-hover-color: #020617
}

.table-dark,
.table-light {
    border-color: var(--bs-table-border-color);
    color: var(--bs-table-color)
}

.table-dark {
    --bs-table-color: #fff;
    --bs-table-bg: #0f172a;
    --bs-table-border-color: #272e3f;
    --bs-table-striped-bg: #1b2335;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #272e3f;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #21283a;
    --bs-table-hover-color: #fff
}

.table-responsive {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto
}

@media (max-width:575.98px) {
    .table-responsive-sm {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto
    }
}

@media (max-width:767.98px) {
    .table-responsive-md {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto
    }
}

@media (max-width:991.98px) {
    .table-responsive-lg {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto
    }
}

@media (max-width:1199.98px) {
    .table-responsive-xl {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto
    }
}

@media (max-width:1399.98px) {
    .table-responsive-xxl {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto
    }
}

.form-label {
    font-size: .875rem;
    margin-bottom: .5rem
}

.col-form-label,
.form-label {
    color: var(--bs-gray-500);
    font-weight: 500
}

.col-form-label {
    font-size: inherit;
    line-height: 1.5;
    margin-bottom: 0;
    padding-bottom: calc(.5rem + var(--bs-border-width));
    padding-top: calc(.5rem + var(--bs-border-width))
}

.col-form-label-lg {
    font-size: 1.25rem;
    padding-bottom: calc(.75rem + var(--bs-border-width));
    padding-top: calc(.75rem + var(--bs-border-width))
}

.col-form-label-sm {
    font-size: .875rem;
    padding-bottom: calc(.25rem + var(--bs-border-width));
    padding-top: calc(.25rem + var(--bs-border-width))
}

.form-text {
    color: var(--bs-secondary-color);
    font-size: .875em;
    margin-top: .25rem
}

.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    color: var(--bs-body-color);
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: .5rem 1rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control[type=file] {
    overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control:focus {
    background-color: var(--bs-body-bg);
    border-color: #c59eff;
    box-shadow: 0 0 0 .25rem rgba(255, 122, 89, .25);
    color: var(--bs-body-color);
    outline: 0
}

.form-control::-webkit-date-and-time-value {
    height: 1.5em;
    margin: 0;
    min-width: 85px
}

.form-control::-webkit-datetime-edit {
    display: block;
    padding: 0
}

.form-control::-moz-placeholder {
    color: var(--bs-input-placeholder-color);
    opacity: 1
}

.form-control::placeholder {
    color: var(--bs-input-placeholder-color);
    opacity: 1
}

.form-control:disabled {
    background-color: var(--bs-input-disabled-bg);
    opacity: 1
}

.form-control::file-selector-button {
    background-color: var(--bs-tertiary-bg);
    border: 0 solid;
    border-color: inherit;
    border-inline-end-width: var(--bs-border-width);
    border-radius: 0;
    color: var(--bs-body-color);
    margin: -.5rem -1rem;
    margin-inline-end: 1rem;
    padding: .5rem 1rem;
    pointer-events: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control::file-selector-button {
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(--bs-secondary-bg)
}

.form-control-plaintext {
    background-color: transparent;
    border: solid transparent;
    border-width: var(--bs-border-width) 0;
    color: var(--bs-body-color);
    display: block;
    line-height: 1.5;
    margin-bottom: 0;
    padding: .5rem 0;
    width: 100%
}

.form-control-plaintext:focus {
    outline: 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-left: 0;
    padding-right: 0
}

.form-control-sm {
    border-radius: var(--bs-border-radius-sm);
    font-size: .875rem;
    min-height: calc(1.5em + .5rem + var(--bs-border-width)*2);
    padding: .25rem .5rem
}

.form-control-sm::file-selector-button {
    margin: -.25rem -.5rem;
    margin-inline-end: .5rem;
    padding: .25rem .5rem
}

.form-control-lg {
    border-radius: var(--bs-border-radius-lg);
    font-size: 1.25rem;
    min-height: calc(1.5em + 1.5rem + var(--bs-border-width)*2);
    padding: .75rem 1.25rem
}

.form-control-lg::file-selector-button {
    margin: -.75rem -1.25rem;
    margin-inline-end: 1.25rem;
    padding: .75rem 1.25rem
}

textarea.form-control {
    min-height: calc(1.5em + 1rem + var(--bs-border-width)*2)
}

textarea.form-control-sm {
    min-height: calc(1.5em + .5rem + var(--bs-border-width)*2)
}

textarea.form-control-lg {
    min-height: calc(1.5em + 1.5rem + var(--bs-border-width)*2)
}

.form-control-color {
    height: calc(1.5em + 1rem + var(--bs-border-width)*2);
    padding: .5rem;
    width: 3rem
}

.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: var(--bs-border-radius)
}

.form-control-color::-webkit-color-swatch {
    border: 0 !important;
    border-radius: var(--bs-border-radius)
}

.form-control-color.form-control-sm {
    height: calc(1.5em + .5rem + var(--bs-border-width)*2)
}

.form-control-color.form-control-lg {
    height: calc(1.5em + 1.5rem + var(--bs-border-width)*2)
}

.form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%231e293b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--bs-body-bg);
    background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
    background-position: right 1rem center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    color: var(--bs-body-color);
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: .5rem 3rem .5rem 1rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%
}

@media (prefers-reduced-motion:reduce) {
    .form-select {
        transition: none
    }
}

.form-select:focus {
    border-color: #c59eff;
    box-shadow: 0 0 0 .25rem rgba(255, 122, 89, .25);
    outline: 0
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
    background-image: none;
    padding-right: 1rem
}

.form-select:disabled {
    background-color: var(--bs-input-disabled-bg)
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 var(--bs-body-color)
}

.form-select-sm {
    border-radius: var(--bs-border-radius-sm);
    font-size: .875rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    padding-top: .25rem
}

.form-select-lg {
    border-radius: var(--bs-border-radius-lg);
    font-size: 1.25rem;
    padding-bottom: .75rem;
    padding-left: 1.25rem;
    padding-top: .75rem
}

[data-bs-theme=dark] .form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%2364748b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E")
}

.form-check {
    display: block;
    margin-bottom: .125rem;
    min-height: 1.5rem;
    padding-left: 1.5em
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.5em
}

.form-check-reverse {
    padding-left: 0;
    padding-right: 1.5em;
    text-align: right
}

.form-check-reverse .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: -1.5em
}

.form-check-input {
    --bs-form-check-bg: var(--bs-body-bg);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--bs-form-check-bg);
    background-image: var(--bs-form-check-bg-image);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border: var(--bs-border-width) solid var(--bs-border-color);
    height: 1em;
    margin-top: .25em;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    vertical-align: top;
    width: 1em
}

.form-check-input[type=checkbox] {
    border-radius: .25em
}

.form-check-input[type=radio] {
    border-radius: 50%
}

.form-check-input:active {
    filter: brightness(90%)
}

.form-check-input:focus {
    border-color: #c59eff;
    box-shadow: 0 0 0 .25rem rgba(255, 122, 89, .25);
    outline: 0
}

.form-check-input:checked {
    background-color: #ff7a59;
    border-color: #ff7a59
}

.form-check-input:checked[type=checkbox] {
    --bs-form-check-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E")
}

.form-check-input:checked[type=radio] {
    --bs-form-check-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E")
}

.form-check-input[type=checkbox]:indeterminate {
    --bs-form-check-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
    background-color: #ff7a59;
    border-color: #ff7a59
}

.form-check-input:disabled {
    filter: none;
    opacity: .5;
    pointer-events: none
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    cursor: default;
    opacity: .5
}

.form-switch {
    padding-left: 2.5em
}

.form-switch .form-check-input {
    --bs-form-switch-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(2, 6, 23, 0.25)'/%3E%3C/svg%3E");
    background-image: var(--bs-form-switch-bg);
    background-position: 0;
    border-radius: 2em;
    margin-left: -2.5em;
    transition: background-position .15s ease-in-out;
    width: 2em
}

@media (prefers-reduced-motion:reduce) {
    .form-switch .form-check-input {
        transition: none
    }
}

.form-switch .form-check-input:focus {
    --bs-form-switch-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23c59eff'/%3E%3C/svg%3E")
}

.form-switch .form-check-input:checked {
    --bs-form-switch-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
    background-position: 100%
}

.form-switch.form-check-reverse {
    padding-left: 0;
    padding-right: 2.5em
}

.form-switch.form-check-reverse .form-check-input {
    margin-left: 0;
    margin-right: -2.5em
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem
}

.btn-check {
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
    position: absolute
}

.btn-check:disabled+.btn,
.btn-check[disabled]+.btn {
    filter: none;
    opacity: .65;
    pointer-events: none
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
    --bs-form-switch-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(255, 255, 255, 0.25)'/%3E%3C/svg%3E")
}

.form-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    height: 1.5rem;
    padding: 0;
    width: 100%
}

.form-range:focus {
    outline: 0
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(255, 122, 89, .25)
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(255, 122, 89, .25)
}

.form-range::-moz-focus-outer {
    border: 0
}

.form-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: #ff7a59;
    border: 0;
    border-radius: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 1rem
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #dcc5ff
}

.form-range::-webkit-slider-runnable-track {
    background-color: var(--bs-tertiary-bg);
    border-color: transparent;
    border-radius: 1rem;
    color: transparent;
    cursor: pointer;
    height: .5rem;
    width: 100%
}

.form-range::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    background-color: #ff7a59;
    border: 0;
    border-radius: 1rem;
    height: 1rem;
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 1rem
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #dcc5ff
}

.form-range::-moz-range-track {
    background-color: var(--bs-tertiary-bg);
    border-color: transparent;
    border-radius: 1rem;
    color: transparent;
    cursor: pointer;
    height: .5rem;
    width: 100%
}

.form-range:disabled {
    pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: var(--bs-secondary-color)
}

.form-range:disabled::-moz-range-thumb {
    background-color: var(--bs-secondary-color)
}

.form-floating {
    position: relative
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
    height: calc(3.5rem + var(--bs-border-width)*2);
    line-height: 1.25;
    min-height: calc(3.5rem + var(--bs-border-width)*2)
}

.form-floating>label {
    border: var(--bs-border-width) solid transparent;
    height: 100%;
    left: 0;
    overflow: hidden;
    padding: 1rem;
    pointer-events: none;
    position: absolute;
    text-align: start;
    text-overflow: ellipsis;
    top: 0;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
    white-space: nowrap;
    z-index: 2
}

@media (prefers-reduced-motion:reduce) {
    .form-floating>label {
        transition: none
    }
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext {
    padding: 1rem
}

.form-floating>.form-control-plaintext::-moz-placeholder,
.form-floating>.form-control::-moz-placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext::placeholder,
.form-floating>.form-control::placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext:not(:-moz-placeholder-shown),
.form-floating>.form-control:not(:-moz-placeholder-shown) {
    padding-bottom: .625rem;
    padding-top: 1.625rem
}

.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown),
.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    padding-bottom: .625rem;
    padding-top: 1.625rem
}

.form-floating>.form-control-plaintext:-webkit-autofill,
.form-floating>.form-control:-webkit-autofill {
    padding-bottom: .625rem;
    padding-top: 1.625rem
}

.form-floating>.form-select {
    padding-bottom: .625rem;
    padding-top: 1.625rem
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    color: rgba(var(--bs-body-color-rgb), .65);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    color: rgba(var(--bs-body-color-rgb), .65);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label:after {
    background-color: var(--bs-body-bg);
    border-radius: var(--bs-border-radius);
    content: "";
    height: 1.5em;
    inset: 1rem .5rem;
    position: absolute;
    z-index: -1
}

.form-floating>.form-control-plaintext~label:after,
.form-floating>.form-control:focus~label:after,
.form-floating>.form-control:not(:placeholder-shown)~label:after,
.form-floating>.form-select~label:after {
    background-color: var(--bs-body-bg);
    border-radius: var(--bs-border-radius);
    content: "";
    height: 1.5em;
    inset: 1rem .5rem;
    position: absolute;
    z-index: -1
}

.form-floating>.form-control:-webkit-autofill~label {
    color: rgba(var(--bs-body-color-rgb), .65);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control-plaintext~label {
    border-width: var(--bs-border-width) 0
}

.form-floating>.form-control:disabled~label,
.form-floating>:disabled~label {
    color: #475569
}

.form-floating>.form-control:disabled~label:after,
.form-floating>:disabled~label:after {
    background-color: var(--bs-input-disabled-bg)
}

.input-group {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%
}

.input-group>.form-control,
.input-group>.form-floating,
.input-group>.form-select {
    flex: 1 1 auto;
    min-width: 0;
    position: relative;
    width: 1%
}

.input-group>.form-control:focus,
.input-group>.form-floating:focus-within,
.input-group>.form-select:focus {
    z-index: 5
}

.input-group .btn {
    position: relative;
    z-index: 2
}

.input-group .btn:focus {
    z-index: 5
}

.input-group-text {
    align-items: center;
    background-color: var(--bs-tertiary-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    color: var(--bs-body-color);
    display: flex;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: .5rem 1rem;
    text-align: center;
    white-space: nowrap
}

.input-group-lg>.btn,
.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text {
    border-radius: var(--bs-border-radius-lg);
    font-size: 1.25rem;
    padding: .75rem 1.25rem
}

.input-group-sm>.btn,
.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text {
    border-radius: var(--bs-border-radius-sm);
    font-size: .875rem;
    padding: .25rem .5rem
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
    padding-right: 4rem
}

.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-control,
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-select,
.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: calc(var(--bs-border-width)*-1)
}

.input-group>.form-floating:not(:first-child)>.form-control,
.input-group>.form-floating:not(:first-child)>.form-select {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.valid-feedback {
    color: var(--bs-form-valid-color);
    display: none;
    font-size: .875em;
    margin-top: .25rem;
    width: 100%
}

.valid-tooltip {
    background-color: var(--bs-success);
    border-radius: var(--bs-border-radius);
    color: #fff;
    display: none;
    font-size: .875rem;
    margin-top: .1rem;
    max-width: 100%;
    padding: .25rem .5rem;
    position: absolute;
    top: 100%;
    z-index: 5
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid,
.was-validated .form-control:valid {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23008008' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-position: right calc(.375em + .25rem) center;
    background-repeat: no-repeat;
    background-size: calc(.75em + .5rem) calc(.75em + .5rem);
    border-color: var(--bs-form-valid-border-color);
    padding-right: calc(1.5em + 1rem)
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25)
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    background-position: top calc(.375em + .25rem) right calc(.375em + .25rem);
    padding-right: calc(1.5em + 1rem)
}

.form-select.is-valid,
.was-validated .form-select:valid {
    border-color: var(--bs-form-valid-border-color)
}

.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23008008' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-position: right 1rem center, center right 3rem;
    background-size: 16px 12px, calc(.75em + .5rem) calc(.75em + .5rem);
    padding-right: 5.5rem
}

.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25)
}

.form-control-color.is-valid,
.was-validated .form-control-color:valid {
    width: calc(4rem + 1.5em)
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
    border-color: var(--bs-form-valid-border-color)
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
    background-color: var(--bs-form-valid-color)
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
    box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25)
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: var(--bs-form-valid-color)
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em
}

.input-group>.form-control:not(:focus).is-valid,
.input-group>.form-floating:not(:focus-within).is-valid,
.input-group>.form-select:not(:focus).is-valid,
.was-validated .input-group>.form-control:not(:focus):valid,
.was-validated .input-group>.form-floating:not(:focus-within):valid,
.was-validated .input-group>.form-select:not(:focus):valid {
    z-index: 3
}

.invalid-feedback {
    color: var(--bs-form-invalid-color);
    display: none;
    font-size: .875em;
    margin-top: .25rem;
    width: 100%
}

.invalid-tooltip {
    background-color: var(--bs-danger);
    border-radius: var(--bs-border-radius);
    color: #fff;
    display: none;
    font-size: .875rem;
    margin-top: .1rem;
    max-width: 100%;
    padding: .25rem .5rem;
    position: absolute;
    top: 100%;
    z-index: 5
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23db1436'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23db1436' stroke='none'/%3E%3C/svg%3E");
    background-position: right calc(.375em + .25rem) center;
    background-repeat: no-repeat;
    background-size: calc(.75em + .5rem) calc(.75em + .5rem);
    border-color: var(--bs-form-invalid-border-color);
    padding-right: calc(1.5em + 1rem)
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25)
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    background-position: top calc(.375em + .25rem) right calc(.375em + .25rem);
    padding-right: calc(1.5em + 1rem)
}

.form-select.is-invalid,
.was-validated .form-select:invalid {
    border-color: var(--bs-form-invalid-border-color)
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23db1436'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23db1436' stroke='none'/%3E%3C/svg%3E");
    background-position: right 1rem center, center right 3rem;
    background-size: 16px 12px, calc(.75em + .5rem) calc(.75em + .5rem);
    padding-right: 5.5rem
}

.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25)
}

.form-control-color.is-invalid,
.was-validated .form-control-color:invalid {
    width: calc(4rem + 1.5em)
}

.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
    border-color: var(--bs-form-invalid-border-color)
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
    background-color: var(--bs-form-invalid-color)
}

.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
    box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25)
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: var(--bs-form-invalid-color)
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em
}

.input-group>.form-control:not(:focus).is-invalid,
.input-group>.form-floating:not(:focus-within).is-invalid,
.input-group>.form-select:not(:focus).is-invalid,
.was-validated .input-group>.form-control:not(:focus):invalid,
.was-validated .input-group>.form-floating:not(:focus-within):invalid,
.was-validated .input-group>.form-select:not(:focus):invalid {
    z-index: 4
}

.btn {
    --bs-btn-padding-x: 1rem;
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 600;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: var(--bs-border-radius);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .15), 0 1px 1px rgba(2, 6, 23, .075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    background-color: var(--bs-btn-bg);
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    color: var(--bs-btn-color);
    cursor: pointer;
    display: inline-block;
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    text-align: center;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    color: var(--bs-btn-hover-color)
}

.btn-check+.btn:hover {
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color);
    color: var(--bs-btn-color)
}

.btn:focus-visible {
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    box-shadow: var(--bs-btn-focus-box-shadow);
    color: var(--bs-btn-hover-color);
    outline: 0
}

.btn-check:focus-visible+.btn {
    border-color: var(--bs-btn-hover-border-color);
    box-shadow: var(--bs-btn-focus-box-shadow);
    outline: 0
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
    color: var(--bs-btn-active-color)
}

.btn-check:checked+.btn:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible,
.btn:first-child:active:focus-visible,
:not(.btn-check)+.btn:active:focus-visible {
    box-shadow: var(--bs-btn-focus-box-shadow)
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    color: var(--bs-btn-disabled-color);
    opacity: var(--bs-btn-disabled-opacity);
    pointer-events: none
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #ff7a59;
    --bs-btn-border-color: #ff7a59;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #fb6d4a;
    --bs-btn-hover-border-color: #ff7a59;
    --bs-btn-focus-shadow-rgb: 156, 90, 255;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #ff7a59;
    --bs-btn-active-border-color: #fb6d4a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #ff7a59;
    --bs-btn-disabled-border-color: #ff7a59
}

.btn-secondary {
    --bs-btn-color: #020617;
    --bs-btn-hover-color: #020617;
    --bs-btn-hover-bg: #e6ebf2;
    --bs-btn-hover-border-color: #e5eaf2;
    --bs-btn-focus-shadow-rgb: 192, 198, 207;
    --bs-btn-active-color: #020617;
    --bs-btn-active-bg: #e8edf3;
    --bs-btn-active-border-color: #e5eaf2;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #020617;
    --bs-btn-disabled-bg: #e2e8f0;
    --bs-btn-disabled-border-color: #e2e8f0
}

.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #008008;
    --bs-btn-border-color: #008008;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #006d07;
    --bs-btn-hover-border-color: #006606;
    --bs-btn-focus-shadow-rgb: 38, 147, 45;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #006606;
    --bs-btn-active-border-color: #006006;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #008008;
    --bs-btn-disabled-border-color: #008008
}

.btn-info {
    --bs-btn-color: #020617;
    --bs-btn-bg: #0dcaf0;
    --bs-btn-border-color: #0dcaf0;
    --bs-btn-hover-color: #020617;
    --bs-btn-hover-bg: #31d2f2;
    --bs-btn-hover-border-color: #25cff2;
    --bs-btn-focus-shadow-rgb: 11, 173, 207;
    --bs-btn-active-color: #020617;
    --bs-btn-active-bg: #3dd5f3;
    --bs-btn-active-border-color: #25cff2;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #020617;
    --bs-btn-disabled-bg: #0dcaf0;
    --bs-btn-disabled-border-color: #0dcaf0
}

.btn-warning {
    --bs-btn-color: #020617;
    --bs-btn-bg: #ffc107;
    --bs-btn-border-color: #ffc107;
    --bs-btn-hover-color: #020617;
    --bs-btn-hover-bg: #ffca2c;
    --bs-btn-hover-border-color: #ffc720;
    --bs-btn-focus-shadow-rgb: 217, 165, 9;
    --bs-btn-active-color: #020617;
    --bs-btn-active-bg: #ffcd39;
    --bs-btn-active-border-color: #ffc720;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #020617;
    --bs-btn-disabled-bg: #ffc107;
    --bs-btn-disabled-border-color: #ffc107
}

.btn-danger {
    --bs-btn-color: #fff;
    --bs-btn-bg: #db1436;
    --bs-btn-border-color: #db1436;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #ba112e;
    --bs-btn-hover-border-color: #af102b;
    --bs-btn-focus-shadow-rgb: 224, 55, 84;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #af102b;
    --bs-btn-active-border-color: #a40f29;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #db1436;
    --bs-btn-disabled-border-color: #db1436
}

.btn-light {
    --bs-btn-color: #020617;
    --bs-btn-hover-color: #020617;
    --bs-btn-hover-bg: #cdd0d4;
    --bs-btn-hover-border-color: #c1c4c7;
    --bs-btn-focus-shadow-rgb: 205, 209, 215;
    --bs-btn-active-color: #020617;
    --bs-btn-active-bg: #c1c4c7;
    --bs-btn-active-border-color: #b5b8bb;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #020617
}

.btn-dark {
    --bs-btn-color: #fff;
    --bs-btn-bg: #0f172a;
    --bs-btn-border-color: #0f172a;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #333a4a;
    --bs-btn-hover-border-color: #272e3f;
    --bs-btn-focus-shadow-rgb: 51, 58, 74;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #3f4555;
    --bs-btn-active-border-color: #272e3f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0f172a;
    --bs-btn-disabled-border-color: #0f172a
}

.btn-white {
    --bs-btn-color: #020617;
    --bs-btn-hover-color: #020617;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #fff;
    --bs-btn-focus-shadow-rgb: 217, 218, 220;
    --bs-btn-active-color: #020617;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #fff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #020617;
    --bs-btn-disabled-bg: #fff;
    --bs-btn-disabled-border-color: #fff
}

.btn-outline-primary {
    --bs-btn-color: #ff7a59;
    --bs-btn-border-color: #ff7a59;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #ff7a59;
    --bs-btn-hover-border-color: #ff7a59;
    --bs-btn-focus-shadow-rgb: 255, 122, 89;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #ff7a59;
    --bs-btn-active-border-color: #ff7a59;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #ff7a59;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #ff7a59;
    --bs-gradient: none
}

.btn-outline-secondary {
    --bs-btn-color: #e2e8f0;
    --bs-btn-border-color: #e2e8f0;
    --bs-btn-hover-color: #020617;
    --bs-btn-hover-bg: #e2e8f0;
    --bs-btn-hover-border-color: #e2e8f0;
    --bs-btn-focus-shadow-rgb: 226, 232, 240;
    --bs-btn-active-color: #020617;
    --bs-btn-active-bg: #e2e8f0;
    --bs-btn-active-border-color: #e2e8f0;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #e2e8f0;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #e2e8f0;
    --bs-gradient: none
}

.btn-outline-success {
    --bs-btn-color: #008008;
    --bs-btn-border-color: #008008;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #008008;
    --bs-btn-hover-border-color: #008008;
    --bs-btn-focus-shadow-rgb: 0, 128, 8;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #008008;
    --bs-btn-active-border-color: #008008;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #008008;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #008008;
    --bs-gradient: none
}

.btn-outline-info {
    --bs-btn-color: #0dcaf0;
    --bs-btn-border-color: #0dcaf0;
    --bs-btn-hover-color: #020617;
    --bs-btn-hover-bg: #0dcaf0;
    --bs-btn-hover-border-color: #0dcaf0;
    --bs-btn-focus-shadow-rgb: 13, 202, 240;
    --bs-btn-active-color: #020617;
    --bs-btn-active-bg: #0dcaf0;
    --bs-btn-active-border-color: #0dcaf0;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #0dcaf0;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0dcaf0;
    --bs-gradient: none
}

.btn-outline-warning {
    --bs-btn-color: #ffc107;
    --bs-btn-border-color: #ffc107;
    --bs-btn-hover-color: #020617;
    --bs-btn-hover-bg: #ffc107;
    --bs-btn-hover-border-color: #ffc107;
    --bs-btn-focus-shadow-rgb: 255, 193, 7;
    --bs-btn-active-color: #020617;
    --bs-btn-active-bg: #ffc107;
    --bs-btn-active-border-color: #ffc107;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #ffc107;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #ffc107;
    --bs-gradient: none
}

.btn-outline-danger {
    --bs-btn-color: #db1436;
    --bs-btn-border-color: #db1436;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #db1436;
    --bs-btn-hover-border-color: #db1436;
    --bs-btn-focus-shadow-rgb: 219, 20, 54;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #db1436;
    --bs-btn-active-border-color: #db1436;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #db1436;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #db1436;
    --bs-gradient: none
}

.btn-outline-light {
    --bs-btn-color: #f1f5f9;
    --bs-btn-border-color: #f1f5f9;
    --bs-btn-hover-color: #020617;
    --bs-btn-hover-bg: #f1f5f9;
    --bs-btn-hover-border-color: #f1f5f9;
    --bs-btn-focus-shadow-rgb: 241, 245, 249;
    --bs-btn-active-color: #020617;
    --bs-btn-active-bg: #f1f5f9;
    --bs-btn-active-border-color: #f1f5f9;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #f1f5f9;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #f1f5f9;
    --bs-gradient: none
}

.btn-outline-dark {
    --bs-btn-color: #0f172a;
    --bs-btn-border-color: #0f172a;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0f172a;
    --bs-btn-hover-border-color: #0f172a;
    --bs-btn-focus-shadow-rgb: 15, 23, 42;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0f172a;
    --bs-btn-active-border-color: #0f172a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #0f172a;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0f172a;
    --bs-gradient: none
}

.btn-outline-white {
    --bs-btn-color: #fff;
    --bs-btn-border-color: #fff;
    --bs-btn-hover-color: #020617;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #fff;
    --bs-btn-focus-shadow-rgb: 255, 255, 255;
    --bs-btn-active-color: #020617;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #fff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #fff;
    --bs-gradient: none
}

.btn-link {
    --bs-btn-font-weight: 400;
    --bs-btn-color: var(--bs-link-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: var(--bs-link-hover-color);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: var(--bs-link-hover-color);
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #475569;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: 0 0 0 #000;
    --bs-btn-focus-shadow-rgb: 156, 90, 255;
    text-decoration: none
}

.btn-link:focus-visible {
    color: var(--bs-btn-color)
}

.btn-link:hover {
    color: var(--bs-btn-hover-color)
}

.btn-group-lg>.btn,
.btn-lg {
    --bs-btn-padding-y: 0.75rem;
    --bs-btn-padding-x: 1.25rem;
    --bs-btn-font-size: 1.25rem;
    --bs-btn-border-radius: var(--bs-border-radius-lg)
}

.btn-group-sm>.btn,
.btn-sm {
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 0.5rem;
    --bs-btn-font-size: 0.875rem;
    --bs-btn-border-radius: var(--bs-border-radius-sm)
}

.fade {
    transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

.collapsing.collapse-horizontal {
    height: auto;
    transition: width .35s ease;
    width: 0
}

@media (prefers-reduced-motion:reduce) {
    .collapsing.collapse-horizontal {
        transition: none
    }
}

.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}


.dropdown-toggle:empty:after {
    margin-left: 0
}

.dropdown-menu {
    --bs-dropdown-zindex: 1000;
    --bs-dropdown-min-width: 10rem;
    --bs-dropdown-padding-x: 0;
    --bs-dropdown-padding-y: 0.5rem;
    --bs-dropdown-spacer: 0.125rem;
    --bs-dropdown-font-size: 0.9375rem;
    --bs-dropdown-color: var(--bs-body-color);
    --bs-dropdown-bg: var(--bs-white);
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-border-radius: var(--bs-border-radius-lg);
    --bs-dropdown-border-width: var(--bs-border-width);
    --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-divider-margin-y: 0.5rem;
    --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(2, 6, 23, .15);
    --bs-dropdown-link-color: var(--bs-gray-600);
    --bs-dropdown-link-hover-color: var(--bs-primary);
    --bs-dropdown-link-hover-bg: none;
    --bs-dropdown-link-active-color: var(--bs-primary);
    --bs-dropdown-link-active-bg: none;
    --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
    --bs-dropdown-item-padding-x: 1rem;
    --bs-dropdown-item-padding-y: 0.25rem;
    --bs-dropdown-header-color: #475569;
    --bs-dropdown-header-padding-x: 1rem;
    --bs-dropdown-header-padding-y: 0.5rem;
    background-clip: padding-box;
    background-color: var(--bs-dropdown-bg);
    border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
    border-radius: var(--bs-dropdown-border-radius);
    color: var(--bs-dropdown-color);
    display: none;
    font-size: var(--bs-dropdown-font-size);
    list-style: none;
    margin: 0;
    min-width: var(--bs-dropdown-min-width);
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    position: absolute;
    text-align: left;
    z-index: var(--bs-dropdown-zindex)
}

.dropdown-menu[data-bs-popper] {
    left: 0;
    margin-top: var(--bs-dropdown-spacer);
    top: 100%
}

.dropdown-menu-start {
    --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
    left: 0;
    right: auto
}

.dropdown-menu-end {
    --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
    left: auto;
    right: 0
}

@media (min-width:576px) {
    .dropdown-menu-sm-start {
        --bs-position: start
    }

    .dropdown-menu-sm-start[data-bs-popper] {
        left: 0;
        right: auto
    }

    .dropdown-menu-sm-end {
        --bs-position: end
    }

    .dropdown-menu-sm-end[data-bs-popper] {
        left: auto;
        right: 0
    }
}

@media (min-width:768px) {
    .dropdown-menu-md-start {
        --bs-position: start
    }

    .dropdown-menu-md-start[data-bs-popper] {
        left: 0;
        right: auto
    }

    .dropdown-menu-md-end {
        --bs-position: end
    }

    .dropdown-menu-md-end[data-bs-popper] {
        left: auto;
        right: 0
    }
}

@media (min-width:992px) {
    .dropdown-menu-lg-start {
        --bs-position: start
    }

    .dropdown-menu-lg-start[data-bs-popper] {
        left: 0;
        right: auto
    }

    .dropdown-menu-lg-end {
        --bs-position: end
    }

    .dropdown-menu-lg-end[data-bs-popper] {
        left: auto;
        right: 0
    }
}

@media (min-width:1200px) {
    .dropdown-menu-xl-start {
        --bs-position: start
    }

    .dropdown-menu-xl-start[data-bs-popper] {
        left: 0;
        right: auto
    }

    .dropdown-menu-xl-end {
        --bs-position: end
    }

    .dropdown-menu-xl-end[data-bs-popper] {
        left: auto;
        right: 0
    }
}

@media (min-width:1400px) {
    .dropdown-menu-xxl-start {
        --bs-position: start
    }

    .dropdown-menu-xxl-start[data-bs-popper] {
        left: 0;
        right: auto
    }

    .dropdown-menu-xxl-end {
        --bs-position: end
    }

    .dropdown-menu-xxl-end[data-bs-popper] {
        left: auto;
        right: 0
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    bottom: 100%;
    margin-bottom: var(--bs-dropdown-spacer);
    margin-top: 0;
    top: auto
}



.dropup .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropend .dropdown-menu[data-bs-popper] {
    left: 100%;
    margin-left: var(--bs-dropdown-spacer);
    margin-top: 0;
    right: auto;
    top: 0
}



.dropend .dropdown-toggle:empty:after {
    margin-left: 0
}



.dropstart .dropdown-menu[data-bs-popper] {
    left: auto;
    margin-right: var(--bs-dropdown-spacer);
    margin-top: 0;
    right: 100%;
    top: 0
}



.dropstart .dropdown-toggle:before {
    border-bottom: .3em solid transparent;
    border-right: .3em solid;
    border-top: .3em solid transparent;
    content: "";
    vertical-align: .255em
}

.dropstart .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropstart .dropdown-toggle:before {
    vertical-align: 0
}

.dropdown-divider {
    border-top: 1px solid var(--bs-dropdown-divider-bg);
    height: 0;
    margin: var(--bs-dropdown-divider-margin-y) 0;
    opacity: 1;
    overflow: hidden
}

.dropdown-item {
    background-color: transparent;
    border: 0;
    border-radius: var(--bs-dropdown-item-border-radius, 0);
    clear: both;
    color: var(--bs-dropdown-link-color);
    display: block;
    font-weight: 400;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    text-align: inherit;
    white-space: nowrap;
    width: 100%
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: var(--bs-dropdown-link-hover-bg);
    color: var(--bs-dropdown-link-hover-color)
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--bs-dropdown-link-active-bg);
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    background-color: transparent;
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    color: var(--bs-dropdown-header-color);
    display: block;
    font-size: .875rem;
    margin-bottom: 0;
    padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
    white-space: nowrap
}

.dropdown-item-text {
    color: var(--bs-dropdown-link-color);
    display: block;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)
}

.dropdown-menu-dark {
    --bs-dropdown-color: #cbd5e1;
    --bs-dropdown-bg: #1e293b;
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-box-shadow: ;
    --bs-dropdown-link-color: #cbd5e1;
    --bs-dropdown-link-hover-color: #fff;
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-link-hover-bg: hsla(0, 0%, 100%, .15);
    --bs-dropdown-link-active-color: var(--bs-primary);
    --bs-dropdown-link-active-bg: none;
    --bs-dropdown-link-disabled-color: #64748b;
    --bs-dropdown-header-color: #64748b
}

.btn-group,
.btn-group-vertical {
    display: inline-flex;
    position: relative;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    flex: 1 1 auto;
    position: relative
}

.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group {
    border-radius: var(--bs-border-radius)
}

.btn-group>.btn-group:not(:first-child),
.btn-group>:not(.btn-check:first-child)+.btn {
    margin-left: calc(var(--bs-border-width)*-1)
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn.dropdown-toggle-split:first-child,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:nth-child(n+3),
.btn-group>:not(.btn-check)+.btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.dropdown-toggle-split {
    padding-left: .75rem;
    padding-right: .75rem
}

.dropdown-toggle-split:after,
.dropend .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
    margin-left: 0
}

.dropstart .dropdown-toggle-split:before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-left: .375rem;
    padding-right: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-left: .9375rem;
    padding-right: .9375rem
}

.btn-group-vertical {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
    margin-top: calc(var(--bs-border-width)*-1)
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn~.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav {
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-body-color);
    --bs-nav-link-hover-color: var(--bs-body-color);
    --bs-nav-link-disabled-color: var(--bs-secondary-color);
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0
}

.nav-link {
    background: none;
    border: 0;
    color: var(--bs-nav-link-color);
    display: block;
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .nav-link {
        transition: none
    }
}

.nav-link:focus,
.nav-link:hover {
    color: var(--bs-nav-link-hover-color)
}

.nav-link:focus-visible {
    box-shadow: 0 0 0 .25rem rgba(255, 122, 89, .25);
    outline: 0
}

.nav-link.disabled,
.nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    cursor: default;
    pointer-events: none
}

.nav-tabs {
    --bs-nav-tabs-border-width: var(--bs-border-width);
    --bs-nav-tabs-border-color: var(--bs-border-color);
    --bs-nav-tabs-border-radius: var(--bs-border-radius);
    --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
    --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
    --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
    --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
    border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color)
}

.nav-tabs .nav-link {
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius);
    margin-bottom: calc(var(--bs-nav-tabs-border-width)*-1)
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: var(--bs-nav-tabs-link-hover-border-color);
    isolation: isolate
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color);
    color: var(--bs-nav-tabs-link-active-color)
}

.nav-tabs .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: calc(var(--bs-nav-tabs-border-width)*-1)
}

.nav-pills {
    --bs-nav-pills-border-radius: var(--bs-border-radius);
    --bs-nav-pills-link-active-color: var(--bs-body-color);
    --bs-nav-pills-link-active-bg: var(--bs-gray-100)
}

.nav-pills .nav-link {
    border-radius: var(--bs-nav-pills-border-radius)
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: var(--bs-nav-pills-link-active-bg);
    color: var(--bs-nav-pills-link-active-color)
}

.nav-underline {
    --bs-nav-underline-gap: 1rem;
    --bs-nav-underline-border-width: 0.125rem;
    --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
    gap: var(--bs-nav-underline-gap)
}

.nav-underline .nav-link {
    border-bottom: var(--bs-nav-underline-border-width) solid transparent;
    padding-left: 0;
    padding-right: 0
}

.nav-underline .nav-link:focus,
.nav-underline .nav-link:hover {
    border-bottom-color: currentcolor
}

.nav-underline .nav-link.active,
.nav-underline .show>.nav-link {
    border-bottom-color: currentcolor;
    color: var(--bs-nav-underline-link-active-color);
    font-weight: 700
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
    width: 100%
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    --bs-navbar-padding-x: 0;
    --bs-navbar-padding-y: 0.5rem;
    --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
    --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
    --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
    --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
    --bs-navbar-brand-padding-y: 0.3125rem;
    --bs-navbar-brand-margin-end: 1rem;
    --bs-navbar-brand-font-size: 1.25rem;
    --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
    --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
    --bs-navbar-nav-link-padding-x: 0.5rem;
    --bs-navbar-toggler-padding-y: 0.25rem;
    --bs-navbar-toggler-padding-x: 0.75rem;
    --bs-navbar-toggler-font-size: 1.25rem;
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(100, 116, 139, 0.75)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
    --bs-navbar-toggler-border-radius: var(--bs-border-radius);
    --bs-navbar-toggler-focus-width: 0.25rem;
    --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
    position: relative
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
    align-items: center;
    display: flex;
    flex-wrap: inherit;
    justify-content: space-between
}

.navbar-brand {
    color: var(--bs-navbar-brand-color);
    font-size: var(--bs-navbar-brand-font-size);
    margin-right: var(--bs-navbar-brand-margin-end);
    padding-bottom: var(--bs-navbar-brand-padding-y);
    padding-top: var(--bs-navbar-brand-padding-y);
    white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
    color: var(--bs-navbar-brand-hover-color)
}

.navbar-nav {
    --bs-nav-link-padding-x: 0;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-navbar-color);
    --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
    --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: var(--bs-navbar-active-color)
}

.navbar-nav .dropdown-menu {
    position: static
}

.navbar-text {
    color: var(--bs-navbar-color);
    padding-bottom: .5rem;
    padding-top: .5rem
}

.navbar-text a,
.navbar-text a:focus,
.navbar-text a:hover {
    color: var(--bs-navbar-active-color)
}

.navbar-collapse {
    align-items: center;
    flex-basis: 100%;
    flex-grow: 1
}

.navbar-toggler {
    background-color: transparent;
    border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
    border-radius: var(--bs-navbar-toggler-border-radius);
    color: var(--bs-navbar-color);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    transition: var(--bs-navbar-toggler-transition)
}

@media (prefers-reduced-motion:reduce) {
    .navbar-toggler {
        transition: none
    }
}

.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:focus {
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
    outline: 0;
    text-decoration: none
}

.navbar-toggler-icon {
    background-image: var(--bs-navbar-toggler-icon-bg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
    height: 1.5em;
    vertical-align: middle;
    width: 1.5em
}

.navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto
}

@media (min-width:576px) {
    .navbar-expand-sm {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-left: var(--bs-navbar-nav-link-padding-x);
        padding-right: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }

    .navbar-expand-sm .offcanvas {
        background-color: transparent !important;
        border: 0 !important;
        flex-grow: 1;
        height: auto !important;
        position: static;
        transform: none !important;
        transition: none;
        visibility: visible !important;
        width: auto !important;
        z-index: auto
    }

    .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-left: var(--bs-navbar-nav-link-padding-x);
        padding-right: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }

    .navbar-expand-md .offcanvas {
        background-color: transparent !important;
        border: 0 !important;
        flex-grow: 1;
        height: auto !important;
        position: static;
        transform: none !important;
        transition: none;
        visibility: visible !important;
        width: auto !important;
        z-index: auto
    }

    .navbar-expand-md .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: var(--bs-navbar-nav-link-padding-x);
        padding-right: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }

    .navbar-expand-lg .offcanvas {
        background-color: transparent !important;
        border: 0 !important;
        flex-grow: 1;
        height: auto !important;
        position: static;
        transform: none !important;
        transition: none;
        visibility: visible !important;
        width: auto !important;
        z-index: auto
    }

    .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-left: var(--bs-navbar-nav-link-padding-x);
        padding-right: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }

    .navbar-expand-xl .offcanvas {
        background-color: transparent !important;
        border: 0 !important;
        flex-grow: 1;
        height: auto !important;
        position: static;
        transform: none !important;
        transition: none;
        visibility: visible !important;
        width: auto !important;
        z-index: auto
    }

    .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0
    }
}

@media (min-width:1400px) {
    .navbar-expand-xxl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xxl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-left: var(--bs-navbar-nav-link-padding-x);
        padding-right: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xxl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xxl .navbar-toggler {
        display: none
    }

    .navbar-expand-xxl .offcanvas {
        background-color: transparent !important;
        border: 0 !important;
        flex-grow: 1;
        height: auto !important;
        position: static;
        transform: none !important;
        transition: none;
        visibility: visible !important;
        width: auto !important;
        z-index: auto
    }

    .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0
    }
}

.navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x)
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-expand .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto
}

.navbar-expand .offcanvas .offcanvas-header {
    display: none
}

.navbar-expand .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
    --bs-navbar-color: hsla(0, 0%, 100%, .55);
    --bs-navbar-hover-color: hsla(0, 0%, 100%, .75);
    --bs-navbar-disabled-color: hsla(0, 0%, 100%, .25);
    --bs-navbar-active-color: #fff;
    --bs-navbar-brand-color: #fff;
    --bs-navbar-brand-hover-color: #fff;
    --bs-navbar-toggler-border-color: hsla(0, 0%, 100%, .1)
}

.navbar-dark,
.navbar[data-bs-theme=dark],
[data-bs-theme=dark] .navbar-toggler-icon {
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.card {
    --bs-card-spacer-y: 1.5rem;
    --bs-card-spacer-x: 1.5rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-title-color: ;
    --bs-card-subtitle-color: ;
    --bs-card-border-width: var(--bs-border-width);
    --bs-card-border-color: var(--bs-border-color);
    --bs-card-border-radius: var(--bs-border-radius-lg);
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
    --bs-card-cap-padding-y: 0.75rem;
    --bs-card-cap-padding-x: 1.5rem;
    --bs-card-cap-bg: var(--bs-card-bg);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: var(--bs-white);
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 1rem;
    word-wrap: break-word;
    background-clip: border-box;
    background-color: var(--bs-card-bg);
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    color: var(--bs-body-color);
    display: flex;
    flex-direction: column;
    height: var(--bs-card-height);
    min-width: 0;
    position: relative
}

.card>hr {
    margin-left: 0;
    margin-right: 0
}

.card>.list-group {
    border-bottom: inherit;
    border-top: inherit
}

.card>.list-group:first-child {
    border-top-left-radius: var(--bs-card-inner-border-radius);
    border-top-right-radius: var(--bs-card-inner-border-radius);
    border-top-width: 0
}

.card>.list-group:last-child {
    border-bottom-left-radius: var(--bs-card-inner-border-radius);
    border-bottom-right-radius: var(--bs-card-inner-border-radius);
    border-bottom-width: 0
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
    border-top: 0
}

.card-body {
    color: var(--bs-card-color);
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x)
}

.card-title {
    color: var(--bs-card-title-color);
    margin-bottom: var(--bs-card-title-spacer-y)
}

.card-subtitle {
    color: var(--bs-card-subtitle-color);
    margin-top: calc(var(--bs-card-title-spacer-y)*-.5)
}

.card-subtitle,
.card-text:last-child {
    margin-bottom: 0
}

.card-link+.card-link {
    margin-left: var(--bs-card-spacer-x)
}

.card-header {
    background-color: var(--bs-card-cap-bg);
    border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
    color: var(--bs-card-cap-color);
    margin-bottom: 0;
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x)
}

.card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0
}

.card-footer {
    background-color: var(--bs-card-cap-bg);
    border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
    color: var(--bs-card-cap-color);
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x)
}

.card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius)
}

.card-header-tabs {
    border-bottom: 0;
    margin-bottom: calc(var(--bs-card-cap-padding-y)*-1);
    margin-left: calc(var(--bs-card-cap-padding-x)*-.5);
    margin-right: calc(var(--bs-card-cap-padding-x)*-.5)
}

.card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg)
}

.card-header-pills {
    margin-left: calc(var(--bs-card-cap-padding-x)*-.5);
    margin-right: calc(var(--bs-card-cap-padding-x)*-.5)
}

.card-img-overlay {
    border-radius: var(--bs-card-inner-border-radius);
    bottom: 0;
    left: 0;
    padding: var(--bs-card-img-overlay-padding);
    position: absolute;
    right: 0;
    top: 0
}

.card-img,
.card-img-bottom,
.card-img-top {
    width: 100%
}

.card-img,
.card-img-top {
    border-top-left-radius: var(--bs-card-inner-border-radius);
    border-top-right-radius: var(--bs-card-inner-border-radius)
}

.card-img,
.card-img-bottom {
    border-bottom-left-radius: var(--bs-card-inner-border-radius);
    border-bottom-right-radius: var(--bs-card-inner-border-radius)
}

.card-group>.card {
    margin-bottom: var(--bs-card-group-margin)
}

@media (min-width:576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        border-left: 0;
        margin-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-header,
    .card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-footer,
    .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-header,
    .card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-footer,
    .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.accordion {
    --bs-accordion-color: var(--bs-body-color);
    --bs-accordion-bg: var(--bs-body-bg);
    --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --bs-accordion-border-color: var(--bs-border-color);
    --bs-accordion-border-width: var(--bs-border-width);
    --bs-accordion-border-radius: var(--bs-border-radius-lg);
    --bs-accordion-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: var(--bs-body-color);
    --bs-accordion-btn-bg: var(--bs-accordion-bg);
    --bs-accordion-btn-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2364748b' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23381866' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    --bs-accordion-btn-focus-border-color: #c59eff;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(255, 122, 89, .25);
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: var(--bs-primary-text-emphasis);
    --bs-accordion-active-bg: var(--bs-primary-bg-subtle)
}

.accordion-button {
    align-items: center;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    color: var(--bs-accordion-btn-color);
    display: flex;
    font-size: 1rem;
    overflow-anchor: none;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    position: relative;
    text-align: left;
    transition: var(--bs-accordion-transition);
    width: 100%
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button {
        transition: none
    }
}

.accordion-button:not(.collapsed) {
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(var(--bs-accordion-border-width)*-1) 0 var(--bs-accordion-border-color);
    color: var(--bs-accordion-active-color)
}

.accordion-button:not(.collapsed):after {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform)
}

.accordion-button:after {
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    content: "";
    flex-shrink: 0;
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    transition: var(--bs-accordion-btn-icon-transition);
    width: var(--bs-accordion-btn-icon-width)
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button:after {
        transition: none
    }
}

.accordion-button:hover {
    z-index: 2
}

.accordion-button:focus {
    border-color: var(--bs-accordion-btn-focus-border-color);
    box-shadow: var(--bs-accordion-btn-focus-box-shadow);
    outline: 0;
    z-index: 3
}

.accordion-header {
    margin-bottom: 0
}

.accordion-item {
    background-color: var(--bs-accordion-bg);
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
    color: var(--bs-accordion-color)
}

.accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius)
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: var(--bs-accordion-inner-border-radius);
    border-top-right-radius: var(--bs-accordion-inner-border-radius)
}

.accordion-item:not(:first-of-type) {
    border-top: 0
}

.accordion-item:last-of-type {
    border-bottom-left-radius: var(--bs-accordion-border-radius);
    border-bottom-right-radius: var(--bs-accordion-border-radius)
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
    border-bottom-right-radius: var(--bs-accordion-inner-border-radius)
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-left-radius: var(--bs-accordion-border-radius);
    border-bottom-right-radius: var(--bs-accordion-border-radius)
}

.accordion-body {
    padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x)
}

.accordion-flush .accordion-collapse {
    border-width: 0
}

.accordion-flush .accordion-item {
    border-left: 0;
    border-radius: 0;
    border-right: 0
}

.accordion-flush .accordion-item:first-child {
    border-top: 0
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0
}

[data-bs-theme=dark] .accordion-button:after {
    --bs-accordion-btn-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23b98bff' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23b98bff' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")
}

.breadcrumb {
    --bs-breadcrumb-padding-x: 0;
    --bs-breadcrumb-padding-y: 0;
    --bs-breadcrumb-margin-bottom: 1rem;
    --bs-breadcrumb-bg: ;
    --bs-breadcrumb-border-radius: ;
    --bs-breadcrumb-divider-color: var(--bs-secondary-color);
    --bs-breadcrumb-item-padding-x: 0.5rem;
    --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
    background-color: var(--bs-breadcrumb-bg);
    border-radius: var(--bs-breadcrumb-border-radius);
    display: flex;
    flex-wrap: wrap;
    font-size: var(--bs-breadcrumb-font-size);
    list-style: none;
    margin-bottom: var(--bs-breadcrumb-margin-bottom);
    padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x)
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: var(--bs-breadcrumb-item-padding-x)
}

.breadcrumb-item+.breadcrumb-item:before {
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/");
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x)
}

.breadcrumb-item.active {
    color: var(--bs-breadcrumb-item-active-color)
}

.pagination {
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 1rem;
    --bs-pagination-color: var(--bs-link-color);
    --bs-pagination-bg: var(--bs-body-bg);
    --bs-pagination-border-width: var(--bs-border-width);
    --bs-pagination-border-color: var(--bs-border-color);
    --bs-pagination-border-radius: var(--bs-border-radius);
    --bs-pagination-hover-color: var(--bs-link-hover-color);
    --bs-pagination-hover-bg: var(--bs-tertiary-bg);
    --bs-pagination-hover-border-color: var(--bs-border-color);
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: var(--bs-secondary-bg);
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(255, 122, 89, .25);
    --bs-pagination-active-color: #fff;
    --bs-pagination-active-bg: #ff7a59;
    --bs-pagination-active-border-color: #ff7a59;
    --bs-pagination-disabled-color: var(--bs-secondary-color);
    --bs-pagination-disabled-bg: var(--bs-secondary-bg);
    --bs-pagination-disabled-border-color: var(--bs-border-color);
    display: flex;
    list-style: none;
    padding-left: 0
}

.page-link {
    background-color: var(--bs-pagination-bg);
    border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
    color: var(--bs-pagination-color);
    display: block;
    font-size: var(--bs-pagination-font-size);
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    position: relative;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .page-link {
        transition: none
    }
}

.page-link:hover {
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color);
    color: var(--bs-pagination-hover-color);
    z-index: 2
}

.page-link:focus {
    background-color: var(--bs-pagination-focus-bg);
    box-shadow: var(--bs-pagination-focus-box-shadow);
    color: var(--bs-pagination-focus-color);
    outline: 0;
    z-index: 3
}

.active>.page-link,
.page-link.active {
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color);
    color: var(--bs-pagination-active-color);
    z-index: 3
}

.disabled>.page-link,
.page-link.disabled {
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color);
    color: var(--bs-pagination-disabled-color);
    pointer-events: none
}

.page-item:not(:first-child) .page-link {
    margin-left: calc(var(--bs-border-width)*-1)
}

.page-item .page-link {
    border-radius: var(--bs-pagination-border-radius)
}

.pagination-lg {
    --bs-pagination-padding-x: 1.5rem;
    --bs-pagination-padding-y: 0.75rem;
    --bs-pagination-font-size: 1.25rem;
    --bs-pagination-border-radius: var(--bs-border-radius-lg)
}

.pagination-sm {
    --bs-pagination-padding-x: 0.5rem;
    --bs-pagination-padding-y: 0.25rem;
    --bs-pagination-font-size: 0.875rem;
    --bs-pagination-border-radius: var(--bs-border-radius-sm)
}

.badge {
    --bs-badge-padding-x: 0.65em;
    --bs-badge-padding-y: 0.35em;
    --bs-badge-font-size: 0.75em;
    --bs-badge-font-weight: 700;
    --bs-badge-color: #fff;
    --bs-badge-border-radius: var(--bs-border-radius);
    border-radius: var(--bs-badge-border-radius);
    color: var(--bs-badge-color);
    display: inline-block;
    font-size: var(--bs-badge-font-size);
    font-weight: var(--bs-badge-font-weight);
    line-height: 1;
    padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.alert {
    --bs-alert-bg: transparent;
    --bs-alert-padding-x: 1rem;
    --bs-alert-padding-y: 1rem;
    --bs-alert-margin-bottom: 1rem;
    --bs-alert-color: inherit;
    --bs-alert-border-color: transparent;
    --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
    --bs-alert-border-radius: var(--bs-border-radius-lg);
    --bs-alert-link-color: inherit;
    background-color: var(--bs-alert-bg);
    border: var(--bs-alert-border);
    border-radius: var(--bs-alert-border-radius);
    color: var(--bs-alert-color);
    margin-bottom: var(--bs-alert-margin-bottom);
    padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
    position: relative
}

.alert-heading {
    color: inherit
}

.alert-link {
    color: var(--bs-alert-link-color);
    font-weight: 700
}

.alert-dismissible {
    padding-right: 3rem
}

.alert-dismissible .btn-close {
    padding: 1.25rem 1rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2
}

.alert-primary {
    --bs-alert-color: var(--bs-primary-text-emphasis);
    --bs-alert-bg: var(--bs-primary-bg-subtle);
    --bs-alert-border-color: var(--bs-primary-border-subtle);
    --bs-alert-link-color: var(--bs-primary-text-emphasis)
}

.alert-secondary {
    --bs-alert-color: var(--bs-secondary-text-emphasis);
    --bs-alert-bg: var(--bs-secondary-bg-subtle);
    --bs-alert-border-color: var(--bs-secondary-border-subtle);
    --bs-alert-link-color: var(--bs-secondary-text-emphasis)
}

.alert-success {
    --bs-alert-color: var(--bs-success-text-emphasis);
    --bs-alert-bg: var(--bs-success-bg-subtle);
    --bs-alert-border-color: var(--bs-success-border-subtle);
    --bs-alert-link-color: var(--bs-success-text-emphasis)
}

.alert-info {
    --bs-alert-color: var(--bs-info-text-emphasis);
    --bs-alert-bg: var(--bs-info-bg-subtle);
    --bs-alert-border-color: var(--bs-info-border-subtle);
    --bs-alert-link-color: var(--bs-info-text-emphasis)
}

.alert-warning {
    --bs-alert-color: var(--bs-warning-text-emphasis);
    --bs-alert-bg: var(--bs-warning-bg-subtle);
    --bs-alert-border-color: var(--bs-warning-border-subtle);
    --bs-alert-link-color: var(--bs-warning-text-emphasis)
}

.alert-danger {
    --bs-alert-color: var(--bs-danger-text-emphasis);
    --bs-alert-bg: var(--bs-danger-bg-subtle);
    --bs-alert-border-color: var(--bs-danger-border-subtle);
    --bs-alert-link-color: var(--bs-danger-text-emphasis)
}

.alert-light {
    --bs-alert-color: var(--bs-light-text-emphasis);
    --bs-alert-bg: var(--bs-light-bg-subtle);
    --bs-alert-border-color: var(--bs-light-border-subtle);
    --bs-alert-link-color: var(--bs-light-text-emphasis)
}

.alert-dark {
    --bs-alert-color: var(--bs-dark-text-emphasis);
    --bs-alert-bg: var(--bs-dark-bg-subtle);
    --bs-alert-border-color: var(--bs-dark-border-subtle);
    --bs-alert-link-color: var(--bs-dark-text-emphasis)
}

.alert-white {
    --bs-alert-color: var(--bs-white-text-emphasis);
    --bs-alert-bg: var(--bs-white-bg-subtle);
    --bs-alert-border-color: var(--bs-white-border-subtle);
    --bs-alert-link-color: var(--bs-white-text-emphasis)
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

.progress,
.progress-stacked {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: var(--bs-gray-200);
    --bs-progress-border-radius: var(--bs-border-radius);
    --bs-progress-box-shadow: var(--bs-box-shadow-inset);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #ff7a59;
    --bs-progress-bar-transition: width 0.6s ease;
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
    display: flex;
    font-size: var(--bs-progress-font-size);
    height: var(--bs-progress-height);
    overflow: hidden
}

.progress-bar {
    background-color: var(--bs-progress-bar-bg);
    color: var(--bs-progress-bar-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    transition: var(--bs-progress-bar-transition);
    white-space: nowrap
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent);
    background-size: var(--bs-progress-height) var(--bs-progress-height)
}

.progress-stacked>.progress {
    overflow: visible
}

.progress-stacked>.progress>.progress-bar {
    width: 100%
}

.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        animation: none
    }
}

.list-group {
    --bs-list-group-color: var(--bs-body-color);
    --bs-list-group-bg: transparent;
    --bs-list-group-border-color: var(--bs-border-color);
    --bs-list-group-border-width: var(--bs-border-width);
    --bs-list-group-border-radius: var(--bs-border-radius-lg);
    --bs-list-group-item-padding-x: 1rem;
    --bs-list-group-item-padding-y: 0.5rem;
    --bs-list-group-action-color: var(--bs-secondary-color);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
    --bs-list-group-action-active-color: var(--bs-body-color);
    --bs-list-group-action-active-bg: var(--bs-secondary-bg);
    --bs-list-group-disabled-color: var(--bs-secondary-color);
    --bs-list-group-disabled-bg: transparent;
    --bs-list-group-active-color: #fff;
    --bs-list-group-active-bg: #ff7a59;
    --bs-list-group-active-border-color: #ff7a59;
    border-radius: var(--bs-list-group-border-radius);
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-left: 0
}

.list-group-numbered {
    counter-reset: section;
    list-style-type: none
}

.list-group-numbered>.list-group-item:before {
    content: counters(section, ".") ". ";
    counter-increment: section
}

.list-group-item-action {
    color: var(--bs-list-group-action-color);
    text-align: inherit;
    width: 100%
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    background-color: var(--bs-list-group-action-hover-bg);
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    z-index: 1
}

.list-group-item-action:active {
    background-color: var(--bs-list-group-action-active-bg);
    color: var(--bs-list-group-action-active-color)
}

.list-group-item {
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
    color: var(--bs-list-group-color);
    display: block;
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    position: relative
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit
}

.list-group-item.disabled,
.list-group-item:disabled {
    background-color: var(--bs-list-group-disabled-bg);
    color: var(--bs-list-group-disabled-color);
    pointer-events: none
}

.list-group-item.active {
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color);
    color: var(--bs-list-group-active-color);
    z-index: 2
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    border-top-width: var(--bs-list-group-border-width);
    margin-top: calc(var(--bs-list-group-border-width)*-1)
}

.list-group-horizontal {
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-list-group-border-radius)
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-left-width: 0;
    border-top-width: var(--bs-list-group-border-width)
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    border-left-width: var(--bs-list-group-border-width);
    margin-left: calc(var(--bs-list-group-border-width)*-1)
}

@media (min-width:576px) {
    .list-group-horizontal-sm {
        flex-direction: row
    }

    .list-group-horizontal-sm>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item:last-child:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-right-radius: var(--bs-list-group-border-radius)
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: var(--bs-list-group-border-width)
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        border-left-width: var(--bs-list-group-border-width);
        margin-left: calc(var(--bs-list-group-border-width)*-1)
    }
}

@media (min-width:768px) {
    .list-group-horizontal-md {
        flex-direction: row
    }

    .list-group-horizontal-md>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-md>.list-group-item:last-child:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-right-radius: var(--bs-list-group-border-radius)
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: var(--bs-list-group-border-width)
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        border-left-width: var(--bs-list-group-border-width);
        margin-left: calc(var(--bs-list-group-border-width)*-1)
    }
}

@media (min-width:992px) {
    .list-group-horizontal-lg {
        flex-direction: row
    }

    .list-group-horizontal-lg>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item:last-child:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-right-radius: var(--bs-list-group-border-radius)
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: var(--bs-list-group-border-width)
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        border-left-width: var(--bs-list-group-border-width);
        margin-left: calc(var(--bs-list-group-border-width)*-1)
    }
}

@media (min-width:1200px) {
    .list-group-horizontal-xl {
        flex-direction: row
    }

    .list-group-horizontal-xl>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item:last-child:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-right-radius: var(--bs-list-group-border-radius)
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: var(--bs-list-group-border-width)
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        border-left-width: var(--bs-list-group-border-width);
        margin-left: calc(var(--bs-list-group-border-width)*-1)
    }
}

@media (min-width:1400px) {
    .list-group-horizontal-xxl {
        flex-direction: row
    }

    .list-group-horizontal-xxl>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item:last-child:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-right-radius: var(--bs-list-group-border-radius)
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: var(--bs-list-group-border-width)
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        border-left-width: var(--bs-list-group-border-width);
        margin-left: calc(var(--bs-list-group-border-width)*-1)
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width)
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    --bs-list-group-color: var(--bs-primary-text-emphasis);
    --bs-list-group-bg: var(--bs-primary-bg-subtle);
    --bs-list-group-border-color: var(--bs-primary-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
    --bs-list-group-active-color: var(--bs-primary-bg-subtle);
    --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-primary-text-emphasis)
}

.list-group-item-secondary {
    --bs-list-group-color: var(--bs-secondary-text-emphasis);
    --bs-list-group-bg: var(--bs-secondary-bg-subtle);
    --bs-list-group-border-color: var(--bs-secondary-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
    --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
    --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis)
}

.list-group-item-success {
    --bs-list-group-color: var(--bs-success-text-emphasis);
    --bs-list-group-bg: var(--bs-success-bg-subtle);
    --bs-list-group-border-color: var(--bs-success-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
    --bs-list-group-active-color: var(--bs-success-bg-subtle);
    --bs-list-group-active-bg: var(--bs-success-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-success-text-emphasis)
}

.list-group-item-info {
    --bs-list-group-color: var(--bs-info-text-emphasis);
    --bs-list-group-bg: var(--bs-info-bg-subtle);
    --bs-list-group-border-color: var(--bs-info-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
    --bs-list-group-active-color: var(--bs-info-bg-subtle);
    --bs-list-group-active-bg: var(--bs-info-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-info-text-emphasis)
}

.list-group-item-warning {
    --bs-list-group-color: var(--bs-warning-text-emphasis);
    --bs-list-group-bg: var(--bs-warning-bg-subtle);
    --bs-list-group-border-color: var(--bs-warning-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
    --bs-list-group-active-color: var(--bs-warning-bg-subtle);
    --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-warning-text-emphasis)
}

.list-group-item-danger {
    --bs-list-group-color: var(--bs-danger-text-emphasis);
    --bs-list-group-bg: var(--bs-danger-bg-subtle);
    --bs-list-group-border-color: var(--bs-danger-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
    --bs-list-group-active-color: var(--bs-danger-bg-subtle);
    --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-danger-text-emphasis)
}

.list-group-item-light {
    --bs-list-group-color: var(--bs-light-text-emphasis);
    --bs-list-group-bg: var(--bs-light-bg-subtle);
    --bs-list-group-border-color: var(--bs-light-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
    --bs-list-group-active-color: var(--bs-light-bg-subtle);
    --bs-list-group-active-bg: var(--bs-light-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-light-text-emphasis)
}

.list-group-item-dark {
    --bs-list-group-color: var(--bs-dark-text-emphasis);
    --bs-list-group-bg: var(--bs-dark-bg-subtle);
    --bs-list-group-border-color: var(--bs-dark-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
    --bs-list-group-active-color: var(--bs-dark-bg-subtle);
    --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-dark-text-emphasis)
}

.list-group-item-white {
    --bs-list-group-color: var(--bs-white-text-emphasis);
    --bs-list-group-bg: var(--bs-white-bg-subtle);
    --bs-list-group-border-color: var(--bs-white-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-white-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-white-border-subtle);
    --bs-list-group-active-color: var(--bs-white-bg-subtle);
    --bs-list-group-active-bg: var(--bs-white-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-white-text-emphasis)
}

.btn-close {
    --bs-btn-close-color: #020617;
    --bs-btn-close-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23020617' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
    --bs-btn-close-opacity: 0.5;
    --bs-btn-close-hover-opacity: 0.75;
    --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(255, 122, 89, .25);
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
    border: 0;
    border-radius: .5rem;
    box-sizing: content-box;
    height: 1em;
    opacity: var(--bs-btn-close-opacity);
    padding: .25em;
    width: 1em
}

.btn-close,
.btn-close:hover {
    color: var(--bs-btn-close-color)
}

.btn-close:hover {
    opacity: var(--bs-btn-close-hover-opacity);
    text-decoration: none
}

.btn-close:focus {
    box-shadow: var(--bs-btn-close-focus-shadow);
    opacity: var(--bs-btn-close-focus-opacity);
    outline: 0
}

.btn-close.disabled,
.btn-close:disabled {
    opacity: var(--bs-btn-close-disabled-opacity);
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.btn-close-white,
[data-bs-theme=dark] .btn-close {
    filter: var(--bs-btn-close-white-filter)
}

.toast {
    --bs-toast-zindex: 1090;
    --bs-toast-padding-x: 0.75rem;
    --bs-toast-padding-y: 0.5rem;
    --bs-toast-spacing: 2.5rem;
    --bs-toast-max-width: 350px;
    --bs-toast-font-size: 0.875rem;
    --bs-toast-color: ;
    --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
    --bs-toast-border-width: var(--bs-border-width);
    --bs-toast-border-color: var(--bs-border-color-translucent);
    --bs-toast-border-radius: var(--bs-border-radius-lg);
    --bs-toast-box-shadow: var(--bs-box-shadow);
    --bs-toast-header-color: var(--bs-secondary-color);
    --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
    --bs-toast-header-border-color: var(--bs-border-color-translucent);
    background-clip: padding-box;
    background-color: var(--bs-toast-bg);
    border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
    border-radius: var(--bs-toast-border-radius);
    box-shadow: var(--bs-toast-box-shadow);
    color: var(--bs-toast-color);
    font-size: var(--bs-toast-font-size);
    max-width: 100%;
    pointer-events: auto;
    width: var(--bs-toast-max-width)
}

.toast.showing {
    opacity: 0
}

.toast:not(.show) {
    display: none
}

.toast-container {
    --bs-toast-zindex: 1090;
    max-width: 100%;
    pointer-events: none;
    position: absolute;
    width: -moz-max-content;
    width: max-content;
    z-index: var(--bs-toast-zindex)
}

.toast-container>:not(:last-child) {
    margin-bottom: var(--bs-toast-spacing)
}

.toast-header {
    align-items: center;
    background-clip: padding-box;
    background-color: var(--bs-toast-header-bg);
    border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
    border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
    border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
    color: var(--bs-toast-header-color);
    display: flex;
    padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x)
}

.toast-header .btn-close {
    margin-left: var(--bs-toast-padding-x);
    margin-right: calc(var(--bs-toast-padding-x)*-.5)
}

.toast-body {
    word-wrap: break-word;
    padding: var(--bs-toast-padding-x)
}

.modal {
    --bs-modal-zindex: 1055;
    --bs-modal-width: 500px;
    --bs-modal-padding: 1rem;
    --bs-modal-margin: 0.5rem;
    --bs-modal-color: ;
    --bs-modal-bg: var(--bs-white);
    --bs-modal-border-color: var(--bs-border-color-translucent);
    --bs-modal-border-width: var(--bs-border-width);
    --bs-modal-border-radius: var(--bs-border-radius-lg);
    --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(2, 6, 23, .075);
    --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
    --bs-modal-header-padding-x: 1rem;
    --bs-modal-header-padding-y: 1rem;
    --bs-modal-header-padding: 1rem 1rem;
    --bs-modal-header-border-color: var(--bs-border-color);
    --bs-modal-header-border-width: var(--bs-border-width);
    --bs-modal-title-line-height: 1.5;
    --bs-modal-footer-gap: 0.5rem;
    --bs-modal-footer-bg: ;
    --bs-modal-footer-border-color: var(--bs-border-color);
    --bs-modal-footer-border-width: var(--bs-border-width);
    display: none;
    height: 100%;
    left: 0;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: var(--bs-modal-zindex)
}

.modal-dialog {
    margin: var(--bs-modal-margin);
    pointer-events: none;
    position: relative;
    width: auto
}

.modal.fade .modal-dialog {
    transform: translateY(-50px);
    transition: transform .3s ease-out
}

@media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    height: calc(100% - var(--bs-modal-margin)*2)
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    align-items: center;
    display: flex;
    min-height: calc(100% - var(--bs-modal-margin)*2)
}

.modal-content {
    background-clip: padding-box;
    background-color: var(--bs-modal-bg);
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    color: var(--bs-modal-color);
    display: flex;
    flex-direction: column;
    outline: 0;
    pointer-events: auto;
    position: relative;
    width: 100%
}

.modal-backdrop {
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #020617;
    --bs-backdrop-opacity: 0.5;
    background-color: var(--bs-backdrop-bg);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: var(--bs-backdrop-zindex)
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity)
}

.modal-header {
    align-items: center;
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius);
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding)
}

.modal-header .btn-close {
    margin: calc(var(--bs-modal-header-padding-y)*-.5) calc(var(--bs-modal-header-padding-x)*-.5) calc(var(--bs-modal-header-padding-y)*-.5) auto;
    padding: calc(var(--bs-modal-header-padding-y)*.5) calc(var(--bs-modal-header-padding-x)*.5)
}

.modal-title {
    line-height: var(--bs-modal-title-line-height);
    margin-bottom: 0
}

.modal-body {
    flex: 1 1 auto;
    padding: var(--bs-modal-padding);
    position: relative
}

.modal-footer {
    align-items: center;
    background-color: var(--bs-modal-footer-bg);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap)*.5)
}

.modal-footer>* {
    margin: calc(var(--bs-modal-footer-gap)*.5)
}

@media (min-width:576px) {
    .modal {
        --bs-modal-margin: 1.75rem;
        --bs-modal-box-shadow: 0 0.5rem 1rem rgba(2, 6, 23, .15)
    }

    .modal-dialog {
        margin-left: auto;
        margin-right: auto;
        max-width: var(--bs-modal-width)
    }

    .modal-sm {
        --bs-modal-width: 300px
    }
}

@media (min-width:992px) {

    .modal-lg,
    .modal-xl {
        --bs-modal-width: 800px
    }
}

@media (min-width:1200px) {
    .modal-xl {
        --bs-modal-width: 1140px
    }
}

.modal-fullscreen {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw
}

.modal-fullscreen .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%
}

.modal-fullscreen .modal-footer,
.modal-fullscreen .modal-header {
    border-radius: 0
}

.modal-fullscreen .modal-body {
    overflow-y: auto
}

@media (max-width:575.98px) {
    .modal-fullscreen-sm-down {
        height: 100%;
        margin: 0;
        max-width: none;
        width: 100vw
    }

    .modal-fullscreen-sm-down .modal-content {
        border: 0;
        border-radius: 0;
        height: 100%
    }

    .modal-fullscreen-sm-down .modal-footer,
    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width:767.98px) {
    .modal-fullscreen-md-down {
        height: 100%;
        margin: 0;
        max-width: none;
        width: 100vw
    }

    .modal-fullscreen-md-down .modal-content {
        border: 0;
        border-radius: 0;
        height: 100%
    }

    .modal-fullscreen-md-down .modal-footer,
    .modal-fullscreen-md-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width:991.98px) {
    .modal-fullscreen-lg-down {
        height: 100%;
        margin: 0;
        max-width: none;
        width: 100vw
    }

    .modal-fullscreen-lg-down .modal-content {
        border: 0;
        border-radius: 0;
        height: 100%
    }

    .modal-fullscreen-lg-down .modal-footer,
    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width:1199.98px) {
    .modal-fullscreen-xl-down {
        height: 100%;
        margin: 0;
        max-width: none;
        width: 100vw
    }

    .modal-fullscreen-xl-down .modal-content {
        border: 0;
        border-radius: 0;
        height: 100%
    }

    .modal-fullscreen-xl-down .modal-footer,
    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width:1399.98px) {
    .modal-fullscreen-xxl-down {
        height: 100%;
        margin: 0;
        max-width: none;
        width: 100vw
    }

    .modal-fullscreen-xxl-down .modal-content {
        border: 0;
        border-radius: 0;
        height: 100%
    }

    .modal-fullscreen-xxl-down .modal-footer,
    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto
    }
}

.tooltip {
    --bs-tooltip-zindex: 1080;
    --bs-tooltip-max-width: 200px;
    --bs-tooltip-padding-x: 0.5rem;
    --bs-tooltip-padding-y: 0.25rem;
    --bs-tooltip-margin: ;
    --bs-tooltip-font-size: 0.875rem;
    --bs-tooltip-color: var(--bs-body-bg);
    --bs-tooltip-bg: var(--bs-emphasis-color);
    --bs-tooltip-border-radius: var(--bs-border-radius);
    --bs-tooltip-opacity: 0.9;
    --bs-tooltip-arrow-width: 0.8rem;
    --bs-tooltip-arrow-height: 0.4rem;
    word-wrap: break-word;
    display: block;
    font-family: var(--bs-font-sans-serif);
    font-size: var(--bs-tooltip-font-size);
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.5;
    margin: var(--bs-tooltip-margin);
    opacity: 0;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    z-index: var(--bs-tooltip-zindex)
}

.tooltip.show {
    opacity: var(--bs-tooltip-opacity)
}

.tooltip .tooltip-arrow {
    display: block;
    height: var(--bs-tooltip-arrow-height);
    width: var(--bs-tooltip-arrow-width)
}

.tooltip .tooltip-arrow:before {
    border-color: transparent;
    border-style: solid;
    content: "";
    position: absolute
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
    bottom: calc(var(--bs-tooltip-arrow-height)*-1)
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow:before,
.bs-tooltip-top .tooltip-arrow:before {
    border-top-color: var(--bs-tooltip-bg);
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width)*.5) 0;
    top: -1px
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
    height: var(--bs-tooltip-arrow-width);
    left: calc(var(--bs-tooltip-arrow-height)*-1);
    width: var(--bs-tooltip-arrow-height)
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow:before,
.bs-tooltip-end .tooltip-arrow:before {
    border-right-color: var(--bs-tooltip-bg);
    border-width: calc(var(--bs-tooltip-arrow-width)*.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width)*.5) 0;
    right: -1px
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
    top: calc(var(--bs-tooltip-arrow-height)*-1)
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow:before,
.bs-tooltip-bottom .tooltip-arrow:before {
    border-bottom-color: var(--bs-tooltip-bg);
    border-width: 0 calc(var(--bs-tooltip-arrow-width)*.5) var(--bs-tooltip-arrow-height);
    bottom: -1px
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
    height: var(--bs-tooltip-arrow-width);
    right: calc(var(--bs-tooltip-arrow-height)*-1);
    width: var(--bs-tooltip-arrow-height)
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow:before,
.bs-tooltip-start .tooltip-arrow:before {
    border-left-color: var(--bs-tooltip-bg);
    border-width: calc(var(--bs-tooltip-arrow-width)*.5) 0 calc(var(--bs-tooltip-arrow-width)*.5) var(--bs-tooltip-arrow-height);
    left: -1px
}

.tooltip-inner {
    background-color: var(--bs-tooltip-bg);
    border-radius: var(--bs-tooltip-border-radius);
    color: var(--bs-tooltip-color);
    max-width: var(--bs-tooltip-max-width);
    padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
    text-align: center
}

.popover {
    --bs-popover-zindex: 1070;
    --bs-popover-max-width: 276px;
    --bs-popover-font-size: 0.875rem;
    --bs-popover-bg: var(--bs-body-bg);
    --bs-popover-border-width: var(--bs-border-width);
    --bs-popover-border-color: var(--bs-border-color-translucent);
    --bs-popover-border-radius: var(--bs-border-radius-lg);
    --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
    --bs-popover-box-shadow: 0 0.5rem 1rem rgba(2, 6, 23, .15);
    --bs-popover-header-padding-x: 1rem;
    --bs-popover-header-padding-y: 0.5rem;
    --bs-popover-header-font-size: 1rem;
    --bs-popover-header-color: var(--bs-gray-800);
    --bs-popover-header-bg: var(--bs-secondary-bg);
    --bs-popover-body-padding-x: 1rem;
    --bs-popover-body-padding-y: 1rem;
    --bs-popover-body-color: var(--bs-body-color);
    --bs-popover-arrow-width: 1rem;
    --bs-popover-arrow-height: 0.5rem;
    --bs-popover-arrow-border: var(--bs-popover-border-color);
    word-wrap: break-word;
    background-clip: padding-box;
    background-color: var(--bs-popover-bg);
    border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
    border-radius: var(--bs-popover-border-radius);
    display: block;
    font-family: var(--bs-font-sans-serif);
    font-size: var(--bs-popover-font-size);
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.5;
    max-width: var(--bs-popover-max-width);
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    z-index: var(--bs-popover-zindex)
}

.popover .popover-arrow {
    display: block;
    height: var(--bs-popover-arrow-height);
    width: var(--bs-popover-arrow-width)
}

.popover .popover-arrow:after,
.popover .popover-arrow:before {
    border: 0 solid transparent;
    content: "";
    display: block;
    position: absolute
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow,
.bs-popover-top>.popover-arrow {
    bottom: calc((var(--bs-popover-arrow-height))*-1 - var(--bs-popover-border-width))
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow:after,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow:before,
.bs-popover-top>.popover-arrow:after,
.bs-popover-top>.popover-arrow:before {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width)*.5) 0
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow:before,
.bs-popover-top>.popover-arrow:before {
    border-top-color: var(--bs-popover-arrow-border);
    bottom: 0
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow:after,
.bs-popover-top>.popover-arrow:after {
    border-top-color: var(--bs-popover-bg);
    bottom: var(--bs-popover-border-width)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow,
.bs-popover-end>.popover-arrow {
    height: var(--bs-popover-arrow-width);
    left: calc((var(--bs-popover-arrow-height))*-1 - var(--bs-popover-border-width));
    width: var(--bs-popover-arrow-height)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow:after,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow:before,
.bs-popover-end>.popover-arrow:after,
.bs-popover-end>.popover-arrow:before {
    border-width: calc(var(--bs-popover-arrow-width)*.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width)*.5) 0
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow:before,
.bs-popover-end>.popover-arrow:before {
    border-right-color: var(--bs-popover-arrow-border);
    left: 0
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow:after,
.bs-popover-end>.popover-arrow:after {
    border-right-color: var(--bs-popover-bg);
    left: var(--bs-popover-border-width)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow,
.bs-popover-bottom>.popover-arrow {
    top: calc((var(--bs-popover-arrow-height))*-1 - var(--bs-popover-border-width))
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow:after,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow:before,
.bs-popover-bottom>.popover-arrow:after,
.bs-popover-bottom>.popover-arrow:before {
    border-width: 0 calc(var(--bs-popover-arrow-width)*.5) var(--bs-popover-arrow-height)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow:before,
.bs-popover-bottom>.popover-arrow:before {
    border-bottom-color: var(--bs-popover-arrow-border);
    top: 0
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow:after,
.bs-popover-bottom>.popover-arrow:after {
    border-bottom-color: var(--bs-popover-bg);
    top: var(--bs-popover-border-width)
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header:before,
.bs-popover-bottom .popover-header:before {
    border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
    content: "";
    display: block;
    left: 50%;
    margin-left: calc(var(--bs-popover-arrow-width)*-.5);
    position: absolute;
    top: 0;
    width: var(--bs-popover-arrow-width)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow,
.bs-popover-start>.popover-arrow {
    height: var(--bs-popover-arrow-width);
    right: calc((var(--bs-popover-arrow-height))*-1 - var(--bs-popover-border-width));
    width: var(--bs-popover-arrow-height)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow:after,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow:before,
.bs-popover-start>.popover-arrow:after,
.bs-popover-start>.popover-arrow:before {
    border-width: calc(var(--bs-popover-arrow-width)*.5) 0 calc(var(--bs-popover-arrow-width)*.5) var(--bs-popover-arrow-height)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow:before,
.bs-popover-start>.popover-arrow:before {
    border-left-color: var(--bs-popover-arrow-border);
    right: 0
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow:after,
.bs-popover-start>.popover-arrow:after {
    border-left-color: var(--bs-popover-bg);
    right: var(--bs-popover-border-width)
}

.popover-header {
    background-color: var(--bs-popover-header-bg);
    border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
    border-top-left-radius: var(--bs-popover-inner-border-radius);
    border-top-right-radius: var(--bs-popover-inner-border-radius);
    color: var(--bs-popover-header-color);
    font-size: var(--bs-popover-header-font-size);
    margin-bottom: 0;
    padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x)
}

.popover-header:empty {
    display: none
}

.popover-body {
    color: var(--bs-popover-body-color);
    padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x)
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    overflow: hidden;
    position: relative;
    width: 100%
}

.carousel-inner:after {
    clear: both;
    content: "";
    display: block
}

.carousel-item {
    backface-visibility: hidden;
    display: none;
    float: left;
    margin-right: -100%;
    position: relative;
    transition: transform .6s ease-in-out;
    width: 100%
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block
}

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
    transform: translateX(100%)
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transform: none;
    transition-property: opacity
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
    opacity: 1;
    z-index: 1
}

.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
    opacity: 0;
    transition: opacity 0s .6s;
    z-index: 0
}

@media (prefers-reduced-motion:reduce) {

    .carousel-fade .active.carousel-item-end,
    .carousel-fade .active.carousel-item-start {
        transition: none
    }
}

.carousel-control-next,
.carousel-control-prev {
    align-items: center;
    background: none;
    border: 0;
    bottom: 0;
    color: #fff;
    display: flex;
    justify-content: center;
    opacity: .5;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 0;
    transition: opacity .15s ease;
    width: 15%;
    z-index: 1
}

@media (prefers-reduced-motion:reduce) {

    .carousel-control-next,
    .carousel-control-prev {
        transition: none
    }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    opacity: .9;
    outline: 0;
    text-decoration: none
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: inline-block;
    height: 2rem;
    width: 2rem
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")
}

.carousel-indicators {
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    margin-bottom: 1rem;
    margin-left: 15%;
    margin-right: 15%;
    padding: 0;
    position: absolute;
    right: 0;
    z-index: 2
}

.carousel-indicators [data-bs-target] {
    background-clip: padding-box;
    background-color: #fff;
    border: 0;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
    box-sizing: content-box;
    cursor: pointer;
    flex: 0 1 auto;
    height: 3px;
    margin-left: 3px;
    margin-right: 3px;
    opacity: .5;
    padding: 0;
    text-indent: -999px;
    transition: opacity .6s ease;
    width: 30px
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    bottom: 1.25rem;
    color: #fff;
    left: 15%;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    position: absolute;
    right: 15%;
    text-align: center
}

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
    filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #020617
}

.carousel-dark .carousel-caption {
    color: #020617
}

[data-bs-theme=dark] .carousel .carousel-control-next-icon,
[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon,
[data-bs-theme=dark].carousel .carousel-control-prev-icon {
    filter: invert(1) grayscale(100)
}

[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target],
[data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
    background-color: #020617
}

[data-bs-theme=dark] .carousel .carousel-caption,
[data-bs-theme=dark].carousel .carousel-caption {
    color: #020617
}

.spinner-border,
.spinner-grow {
    animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
    border-radius: 50%;
    display: inline-block;
    height: var(--bs-spinner-height);
    vertical-align: var(--bs-spinner-vertical-align);
    width: var(--bs-spinner-width)
}

@keyframes spinner-border {
    to {
        transform: rotate(1turn)
    }
}

.spinner-border {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-border-width: 0.25em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-border;
    border-right-color: currentcolor;
    border: var(--bs-spinner-border-width) solid;
    border-right: var(--bs-spinner-border-width) solid transparent
}

.spinner-border-sm {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem;
    --bs-spinner-border-width: 0.2em
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

.spinner-grow {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-grow;
    background-color: currentcolor;
    opacity: 0
}

.spinner-grow-sm {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem
}

@media (prefers-reduced-motion:reduce) {

    .spinner-border,
    .spinner-grow {
        --bs-spinner-animation-speed: 1.5s
    }
}

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
    --bs-offcanvas-zindex: 1045;
    --bs-offcanvas-width: 400px;
    --bs-offcanvas-height: 30vh;
    --bs-offcanvas-padding-x: 1rem;
    --bs-offcanvas-padding-y: 1rem;
    --bs-offcanvas-color: var(--bs-body-color);
    --bs-offcanvas-bg: var(--bs-body-bg);
    --bs-offcanvas-border-width: var(--bs-border-width);
    --bs-offcanvas-border-color: var(--bs-border-color-translucent);
    --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(2, 6, 23, .075);
    --bs-offcanvas-transition: transform 0.3s ease-in-out;
    --bs-offcanvas-title-line-height: 1.5
}

@media (max-width:575.98px) {
    .offcanvas-sm {
        background-clip: padding-box;
        background-color: var(--bs-offcanvas-bg);
        bottom: 0;
        color: var(--bs-offcanvas-color);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        outline: 0;
        position: fixed;
        transition: var(--bs-offcanvas-transition);
        visibility: hidden;
        z-index: var(--bs-offcanvas-zindex)
    }
}

@media (max-width:575.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-sm {
        transition: none
    }
}

@media (max-width:575.98px) {
    .offcanvas-sm.offcanvas-start {
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        left: 0;
        top: 0;
        transform: translateX(-100%);
        width: var(--bs-offcanvas-width)
    }

    .offcanvas-sm.offcanvas-end {
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        right: 0;
        top: 0;
        transform: translateX(100%);
        width: var(--bs-offcanvas-width)
    }

    .offcanvas-sm.offcanvas-top {
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        top: 0;
        transform: translateY(-100%)
    }

    .offcanvas-sm.offcanvas-bottom,
    .offcanvas-sm.offcanvas-top {
        height: var(--bs-offcanvas-height);
        left: 0;
        max-height: 100%;
        right: 0
    }

    .offcanvas-sm.offcanvas-bottom {
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }

    .offcanvas-sm.show:not(.hiding),
    .offcanvas-sm.showing {
        transform: none
    }

    .offcanvas-sm.hiding,
    .offcanvas-sm.show,
    .offcanvas-sm.showing {
        visibility: visible
    }
}

@media (min-width:576px) {
    .offcanvas-sm {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-sm .offcanvas-header {
        display: none
    }

    .offcanvas-sm .offcanvas-body {
        background-color: transparent !important;
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0
    }
}

@media (max-width:767.98px) {
    .offcanvas-md {
        background-clip: padding-box;
        background-color: var(--bs-offcanvas-bg);
        bottom: 0;
        color: var(--bs-offcanvas-color);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        outline: 0;
        position: fixed;
        transition: var(--bs-offcanvas-transition);
        visibility: hidden;
        z-index: var(--bs-offcanvas-zindex)
    }
}

@media (max-width:767.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-md {
        transition: none
    }
}

@media (max-width:767.98px) {
    .offcanvas-md.offcanvas-start {
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        left: 0;
        top: 0;
        transform: translateX(-100%);
        width: var(--bs-offcanvas-width)
    }

    .offcanvas-md.offcanvas-end {
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        right: 0;
        top: 0;
        transform: translateX(100%);
        width: var(--bs-offcanvas-width)
    }

    .offcanvas-md.offcanvas-top {
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        top: 0;
        transform: translateY(-100%)
    }

    .offcanvas-md.offcanvas-bottom,
    .offcanvas-md.offcanvas-top {
        height: var(--bs-offcanvas-height);
        left: 0;
        max-height: 100%;
        right: 0
    }

    .offcanvas-md.offcanvas-bottom {
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }

    .offcanvas-md.show:not(.hiding),
    .offcanvas-md.showing {
        transform: none
    }

    .offcanvas-md.hiding,
    .offcanvas-md.show,
    .offcanvas-md.showing {
        visibility: visible
    }
}

@media (min-width:768px) {
    .offcanvas-md {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-md .offcanvas-header {
        display: none
    }

    .offcanvas-md .offcanvas-body {
        background-color: transparent !important;
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0
    }
}

@media (max-width:991.98px) {
    .offcanvas-lg {
        background-clip: padding-box;
        background-color: var(--bs-offcanvas-bg);
        bottom: 0;
        color: var(--bs-offcanvas-color);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        outline: 0;
        position: fixed;
        transition: var(--bs-offcanvas-transition);
        visibility: hidden;
        z-index: var(--bs-offcanvas-zindex)
    }
}

@media (max-width:991.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-lg {
        transition: none
    }
}

@media (max-width:991.98px) {
    .offcanvas-lg.offcanvas-start {
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        left: 0;
        top: 0;
        transform: translateX(-100%);
        width: var(--bs-offcanvas-width)
    }

    .offcanvas-lg.offcanvas-end {
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        right: 0;
        top: 0;
        transform: translateX(100%);
        width: var(--bs-offcanvas-width)
    }

    .offcanvas-lg.offcanvas-top {
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        top: 0;
        transform: translateY(-100%)
    }

    .offcanvas-lg.offcanvas-bottom,
    .offcanvas-lg.offcanvas-top {
        height: var(--bs-offcanvas-height);
        left: 0;
        max-height: 100%;
        right: 0
    }

    .offcanvas-lg.offcanvas-bottom {
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }

    .offcanvas-lg.show:not(.hiding),
    .offcanvas-lg.showing {
        transform: none
    }

    .offcanvas-lg.hiding,
    .offcanvas-lg.show,
    .offcanvas-lg.showing {
        visibility: visible
    }
}

@media (min-width:992px) {
    .offcanvas-lg {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-lg .offcanvas-header {
        display: none
    }

    .offcanvas-lg .offcanvas-body {
        background-color: transparent !important;
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0
    }
}

@media (max-width:1199.98px) {
    .offcanvas-xl {
        background-clip: padding-box;
        background-color: var(--bs-offcanvas-bg);
        bottom: 0;
        color: var(--bs-offcanvas-color);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        outline: 0;
        position: fixed;
        transition: var(--bs-offcanvas-transition);
        visibility: hidden;
        z-index: var(--bs-offcanvas-zindex)
    }
}

@media (max-width:1199.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-xl {
        transition: none
    }
}

@media (max-width:1199.98px) {
    .offcanvas-xl.offcanvas-start {
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        left: 0;
        top: 0;
        transform: translateX(-100%);
        width: var(--bs-offcanvas-width)
    }

    .offcanvas-xl.offcanvas-end {
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        right: 0;
        top: 0;
        transform: translateX(100%);
        width: var(--bs-offcanvas-width)
    }

    .offcanvas-xl.offcanvas-top {
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        top: 0;
        transform: translateY(-100%)
    }

    .offcanvas-xl.offcanvas-bottom,
    .offcanvas-xl.offcanvas-top {
        height: var(--bs-offcanvas-height);
        left: 0;
        max-height: 100%;
        right: 0
    }

    .offcanvas-xl.offcanvas-bottom {
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }

    .offcanvas-xl.show:not(.hiding),
    .offcanvas-xl.showing {
        transform: none
    }

    .offcanvas-xl.hiding,
    .offcanvas-xl.show,
    .offcanvas-xl.showing {
        visibility: visible
    }
}

@media (min-width:1200px) {
    .offcanvas-xl {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-xl .offcanvas-header {
        display: none
    }

    .offcanvas-xl .offcanvas-body {
        background-color: transparent !important;
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0
    }
}

@media (max-width:1399.98px) {
    .offcanvas-xxl {
        background-clip: padding-box;
        background-color: var(--bs-offcanvas-bg);
        bottom: 0;
        color: var(--bs-offcanvas-color);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        outline: 0;
        position: fixed;
        transition: var(--bs-offcanvas-transition);
        visibility: hidden;
        z-index: var(--bs-offcanvas-zindex)
    }
}

@media (max-width:1399.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-xxl {
        transition: none
    }
}

@media (max-width:1399.98px) {
    .offcanvas-xxl.offcanvas-start {
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        left: 0;
        top: 0;
        transform: translateX(-100%);
        width: var(--bs-offcanvas-width)
    }

    .offcanvas-xxl.offcanvas-end {
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        right: 0;
        top: 0;
        transform: translateX(100%);
        width: var(--bs-offcanvas-width)
    }

    .offcanvas-xxl.offcanvas-top {
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        top: 0;
        transform: translateY(-100%)
    }

    .offcanvas-xxl.offcanvas-bottom,
    .offcanvas-xxl.offcanvas-top {
        height: var(--bs-offcanvas-height);
        left: 0;
        max-height: 100%;
        right: 0
    }

    .offcanvas-xxl.offcanvas-bottom {
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }

    .offcanvas-xxl.show:not(.hiding),
    .offcanvas-xxl.showing {
        transform: none
    }

    .offcanvas-xxl.hiding,
    .offcanvas-xxl.show,
    .offcanvas-xxl.showing {
        visibility: visible
    }
}

@media (min-width:1400px) {
    .offcanvas-xxl {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-xxl .offcanvas-header {
        display: none
    }

    .offcanvas-xxl .offcanvas-body {
        background-color: transparent !important;
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0
    }
}

.offcanvas {
    background-clip: padding-box;
    background-color: var(--bs-offcanvas-bg);
    bottom: 0;
    color: var(--bs-offcanvas-color);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    transition: var(--bs-offcanvas-transition);
    visibility: hidden;
    z-index: var(--bs-offcanvas-zindex)
}

@media (prefers-reduced-motion:reduce) {
    .offcanvas {
        transition: none
    }
}

.offcanvas.offcanvas-start {
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: var(--bs-offcanvas-width)
}

.offcanvas.offcanvas-end {
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: var(--bs-offcanvas-width)
}

.offcanvas.offcanvas-top {
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%)
}

.offcanvas.offcanvas-bottom,
.offcanvas.offcanvas-top {
    height: var(--bs-offcanvas-height);
    left: 0;
    max-height: 100%;
    right: 0
}

.offcanvas.offcanvas-bottom {
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%)
}

.offcanvas.hiding,
.offcanvas.show,
.offcanvas.showing {
    visibility: visible
}

.offcanvas-backdrop {
    background-color: #020617;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1040
}

.offcanvas-backdrop.fade {
    opacity: 0
}

.offcanvas-backdrop.show {
    opacity: .5
}

.offcanvas-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x)
}

.offcanvas-header .btn-close {
    margin-bottom: calc(var(--bs-offcanvas-padding-y)*-.5);
    margin-right: calc(var(--bs-offcanvas-padding-x)*-.5);
    margin-top: calc(var(--bs-offcanvas-padding-y)*-.5);
    padding: calc(var(--bs-offcanvas-padding-y)*.5) calc(var(--bs-offcanvas-padding-x)*.5)
}

.offcanvas-title {
    line-height: var(--bs-offcanvas-title-line-height);
    margin-bottom: 0
}

.offcanvas-body {
    flex-grow: 1;
    overflow-y: auto;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x)
}

.placeholder {
    background-color: currentcolor;
    cursor: wait;
    display: inline-block;
    min-height: 1em;
    opacity: .5;
    vertical-align: middle
}

.placeholder.btn:before {
    content: "";
    display: inline-block
}

.placeholder-xs {
    min-height: .6em
}

.placeholder-sm {
    min-height: .8em
}

.placeholder-lg {
    min-height: 1.2em
}

.placeholder-glow .placeholder {
    animation: placeholder-glow 2s ease-in-out infinite
}

@keyframes placeholder-glow {
    50% {
        opacity: .2
    }
}

.placeholder-wave {
    animation: placeholder-wave 2s linear infinite;
    -webkit-mask-image: linear-gradient(130deg, #020617 55%, rgba(0, 0, 0, .8) 75%, #020617 95%);
    mask-image: linear-gradient(130deg, #020617 55%, rgba(0, 0, 0, .8) 75%, #020617 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%
}

@keyframes placeholder-wave {
    to {
        -webkit-mask-position: -200% 0;
        mask-position: -200% 0
    }
}

.clearfix:after {
    clear: both;
    content: "";
    display: block
}

.text-bg-primary {
    background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
    color: #fff !important
}

.text-bg-secondary {
    background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
    color: #020617 !important
}

.text-bg-success {
    background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
    color: #fff !important
}

.text-bg-info {
    background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
    color: #020617 !important
}

.text-bg-warning {
    background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
    color: #020617 !important
}

.text-bg-danger {
    background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
    color: #fff !important
}

.text-bg-light {
    background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
    color: #020617 !important
}

.text-bg-dark {
    background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
    color: #fff !important
}

.text-bg-white {
    background-color: RGBA(var(--bs-white-rgb), var(--bs-bg-opacity, 1)) !important;
    color: #020617 !important
}

.link-primary {
    color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-primary:focus,
.link-primary:hover {
    color: RGBA(111, 49, 204, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(111, 49, 204, var(--bs-link-underline-opacity, 1)) !important
}

.link-secondary {
    color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-secondary:focus,
.link-secondary:hover {
    color: RGBA(232, 237, 243, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(232, 237, 243, var(--bs-link-underline-opacity, 1)) !important
}

.link-success {
    color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-success:focus,
.link-success:hover {
    color: RGBA(0, 102, 6, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(0, 102, 6, var(--bs-link-underline-opacity, 1)) !important
}

.link-info {
    color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-info:focus,
.link-info:hover {
    color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important
}

.link-warning {
    color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-warning:focus,
.link-warning:hover {
    color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important
}

.link-danger {
    color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-danger:focus,
.link-danger:hover {
    color: RGBA(175, 16, 43, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(175, 16, 43, var(--bs-link-underline-opacity, 1)) !important
}

.link-light {
    color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-light:focus,
.link-light:hover {
    color: RGBA(244, 247, 250, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(244, 247, 250, var(--bs-link-underline-opacity, 1)) !important
}

.link-dark {
    color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-dark:focus,
.link-dark:hover {
    color: RGBA(12, 18, 34, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(12, 18, 34, var(--bs-link-underline-opacity, 1)) !important
}

.link-white {
    color: RGBA(var(--bs-white-rgb), var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-white-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-white:focus,
.link-white:hover {
    color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important
}

.link-body-emphasis {
    color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-body-emphasis:focus,
.link-body-emphasis:hover {
    color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, .75)) !important;
    text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, .75)) !important
}

.focus-ring:focus {
    box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
    outline: 0
}

.icon-link {
    align-items: center;
    backface-visibility: hidden;
    display: inline-flex;
    gap: .375rem;
    text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, .5));
    text-underline-offset: .25em
}

.icon-link>.bi {
    fill: currentcolor;
    flex-shrink: 0;
    height: 1em;
    transition: transform .2s ease-in-out;
    width: 1em
}

@media (prefers-reduced-motion:reduce) {
    .icon-link>.bi {
        transition: none
    }
}

.icon-link-hover:focus-visible>.bi,
.icon-link-hover:hover>.bi {
    transform: var(--bs-icon-link-transform, translate3d(.25em, 0, 0))
}

.ratio {
    position: relative;
    width: 100%
}

.ratio:before {
    content: "";
    display: block;
    padding-top: var(--bs-aspect-ratio)
}

.ratio>* {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%
}

.ratio-4x3 {
    --bs-aspect-ratio: 75%
}

.ratio-16x9 {
    --bs-aspect-ratio: 56.25%
}

.ratio-21x9 {
    --bs-aspect-ratio: 42.8571428571%
}

.fixed-top {
    top: 0
}

.fixed-bottom,
.fixed-top {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1030
}

.fixed-bottom {
    bottom: 0
}

.sticky-top {
    top: 0
}

.sticky-bottom,
.sticky-top {
    position: sticky;
    z-index: 1020
}

.sticky-bottom {
    bottom: 0
}

@media (min-width:576px) {
    .sticky-sm-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-sm-bottom {
        bottom: 0;
        position: sticky;
        z-index: 1020
    }
}

@media (min-width:768px) {
    .sticky-md-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-md-bottom {
        bottom: 0;
        position: sticky;
        z-index: 1020
    }
}

@media (min-width:992px) {
    .sticky-lg-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-lg-bottom {
        bottom: 0;
        position: sticky;
        z-index: 1020
    }
}

@media (min-width:1200px) {
    .sticky-xl-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-xl-bottom {
        bottom: 0;
        position: sticky;
        z-index: 1020
    }
}

@media (min-width:1400px) {
    .sticky-xxl-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-xxl-bottom {
        bottom: 0;
        position: sticky;
        z-index: 1020
    }
}

.hstack {
    align-items: center;
    flex-direction: row
}

.hstack,
.vstack {
    align-self: stretch;
    display: flex
}

.vstack {
    flex: 1 1 auto;
    flex-direction: column
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    clip: rect(0, 0, 0, 0) !important;
    border: 0 !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    white-space: nowrap !important;
    width: 1px !important
}

.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption),
.visually-hidden:not(caption) {
    position: absolute !important
}

.stretched-link:after {
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.vr {
    align-self: stretch;
    background-color: currentcolor;
    display: inline-block;
    min-height: 1em;
    opacity: 1;
    width: var(--bs-border-width)
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.float-start {
    float: left !important
}

.float-end {
    float: right !important
}

.float-none {
    float: none !important
}

.object-fit-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important
}

.object-fit-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important
}

.object-fit-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important
}

.object-fit-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important
}

.object-fit-none {
    -o-object-fit: none !important;
    object-fit: none !important
}

.opacity-0 {
    opacity: 0 !important
}

.opacity-25 {
    opacity: .25 !important
}

.opacity-50 {
    opacity: .5 !important
}

.opacity-75 {
    opacity: .75 !important
}

.opacity-100 {
    opacity: 1 !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.overflow-visible {
    overflow: visible !important
}

.overflow-scroll {
    overflow: scroll !important
}

.overflow-x-auto {
    overflow-x: auto !important
}

.overflow-x-hidden {
    overflow-x: hidden !important
}

.overflow-x-visible {
    overflow-x: visible !important
}

.overflow-x-scroll {
    overflow-x: scroll !important
}

.overflow-y-auto {
    overflow-y: auto !important
}

.overflow-y-hidden {
    overflow-y: hidden !important
}

.overflow-y-visible {
    overflow-y: visible !important
}

.overflow-y-scroll {
    overflow-y: scroll !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-grid {
    display: grid !important
}

.d-inline-grid {
    display: inline-grid !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

.d-none {
    display: none !important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(2, 6, 23, .15) !important
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(2, 6, 23, .075) !important
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(2, 6, 23, .175) !important
}

.shadow-none {
    box-shadow: none !important
}

.focus-ring-primary {
    --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-secondary {
    --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-success {
    --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-info {
    --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-warning {
    --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-danger {
    --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-light {
    --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-dark {
    --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-white {
    --bs-focus-ring-color: rgba(var(--bs-white-rgb), var(--bs-focus-ring-opacity))
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: sticky !important
}

.top-0 {
    top: 0 !important
}

.top-50 {
    top: 50% !important
}

.top-100 {
    top: 100% !important
}

.bottom-0 {
    bottom: 0 !important
}

.bottom-50 {
    bottom: 50% !important
}

.bottom-100 {
    bottom: 100% !important
}

.start-0 {
    left: 0 !important
}

.start-50 {
    left: 50% !important
}

.start-100 {
    left: 100% !important
}

.end-0 {
    right: 0 !important
}

.end-50 {
    right: 50% !important
}

.end-100 {
    right: 100% !important
}

.translate-middle {
    transform: translate(-50%, -50%) !important
}

.translate-middle-x {
    transform: translateX(-50%) !important
}

.translate-middle-y {
    transform: translateY(-50%) !important
}

.border {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-0 {
    border: 0 !important
}

.border-top {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-end {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-end-0 {
    border-right: 0 !important
}

.border-bottom {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-start {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-start-0 {
    border-left: 0 !important
}

.border-primary {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important
}

.border-secondary {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important
}

.border-success {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important
}

.border-info {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important
}

.border-warning {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important
}

.border-danger {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important
}

.border-light {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important
}

.border-dark {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important
}

.border-white {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important
}

.border-black {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important
}

.border-primary-subtle {
    border-color: var(--bs-primary-border-subtle) !important
}

.border-secondary-subtle {
    border-color: var(--bs-secondary-border-subtle) !important
}

.border-success-subtle {
    border-color: var(--bs-success-border-subtle) !important
}

.border-info-subtle {
    border-color: var(--bs-info-border-subtle) !important
}

.border-warning-subtle {
    border-color: var(--bs-warning-border-subtle) !important
}

.border-danger-subtle {
    border-color: var(--bs-danger-border-subtle) !important
}

.border-light-subtle {
    border-color: var(--bs-light-border-subtle) !important
}

.border-dark-subtle {
    border-color: var(--bs-dark-border-subtle) !important
}

.border-1 {
    border-width: 1px !important
}

.border-2 {
    border-width: 2px !important
}

.border-3 {
    border-width: 3px !important
}

.border-4 {
    border-width: 4px !important
}

.border-5 {
    border-width: 5px !important
}

.border-opacity-10 {
    --bs-border-opacity: 0.1
}

.border-opacity-25 {
    --bs-border-opacity: 0.25
}

.border-opacity-50 {
    --bs-border-opacity: 0.5
}

.border-opacity-75 {
    --bs-border-opacity: 0.75
}

.border-opacity-100 {
    --bs-border-opacity: 1
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.mw-100 {
    max-width: 100% !important
}

.vw-100 {
    width: 100vw !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.mh-100 {
    max-height: 100% !important
}

.vh-100 {
    height: 100vh !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.justify-content-evenly {
    justify-content: space-evenly !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

.order-first {
    order: -1 !important
}

.order-0 {
    order: 0 !important
}

.order-1 {
    order: 1 !important
}

.order-2 {
    order: 2 !important
}

.order-3 {
    order: 3 !important
}

.order-4 {
    order: 4 !important
}

.order-5 {
    order: 5 !important
}

.order-last {
    order: 6 !important
}

.m-0 {
    margin: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.m-5 {
    margin: 2rem !important
}

.m-6 {
    margin: 2.5rem !important
}

.m-7 {
    margin: 3rem !important
}

.m-8 {
    margin: 4rem !important
}

.m-9 {
    margin: 5rem !important
}

.m-10 {
    margin: 6rem !important
}

.m-auto {
    margin: auto !important
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
}

.mx-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important
}

.mx-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important
}

.mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important
}

.mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important
}

.mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important
}

.mx-6 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important
}

.mx-7 {
    margin-left: 3rem !important;
    margin-right: 3rem !important
}

.mx-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important
}

.mx-9 {
    margin-left: 5rem !important;
    margin-right: 5rem !important
}

.mx-10 {
    margin-left: 6rem !important;
    margin-right: 6rem !important
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important
}

.my-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important
}

.my-1 {
    margin-bottom: .25rem !important;
    margin-top: .25rem !important
}

.my-2 {
    margin-bottom: .5rem !important;
    margin-top: .5rem !important
}

.my-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important
}

.my-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important
}

.my-5 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important
}

.my-6 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important
}

.my-7 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important
}

.my-8 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important
}

.my-9 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important
}

.my-10 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important
}

.my-auto {
    margin-bottom: auto !important;
    margin-top: auto !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mt-5 {
    margin-top: 2rem !important
}

.mt-6 {
    margin-top: 2.5rem !important
}

.mt-7 {
    margin-top: 3rem !important
}

.mt-8 {
    margin-top: 4rem !important
}

.mt-9 {
    margin-top: 5rem !important
}

.mt-10 {
    margin-top: 6rem !important
}

.mt-auto {
    margin-top: auto !important
}

.me-0 {
    margin-right: 0 !important
}

.me-1 {
    margin-right: .25rem !important
}

.me-2 {
    margin-right: .5rem !important
}

.me-3 {
    margin-right: 1rem !important
}

.me-4 {
    margin-right: 1.5rem !important
}

.me-5 {
    margin-right: 2rem !important
}

.me-6 {
    margin-right: 2.5rem !important
}

.me-7 {
    margin-right: 3rem !important
}

.me-8 {
    margin-right: 4rem !important
}

.me-9 {
    margin-right: 5rem !important
}

.me-10 {
    margin-right: 6rem !important
}

.me-auto {
    margin-right: auto !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

.mb-5 {
    margin-bottom: 2rem !important
}

.mb-6 {
    margin-bottom: 2.5rem !important
}

.mb-7 {
    margin-bottom: 3rem !important
}

.mb-8 {
    margin-bottom: 4rem !important
}

.mb-9 {
    margin-bottom: 5rem !important
}

.mb-10 {
    margin-bottom: 6rem !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ms-0 {
    margin-left: 0 !important
}

.ms-1 {
    margin-left: .25rem !important
}

.ms-2 {
    margin-left: .5rem !important
}

.ms-3 {
    margin-left: 1rem !important
}

.ms-4 {
    margin-left: 1.5rem !important
}

.ms-5 {
    margin-left: 2rem !important
}

.ms-6 {
    margin-left: 2.5rem !important
}

.ms-7 {
    margin-left: 3rem !important
}

.ms-8 {
    margin-left: 4rem !important
}

.ms-9 {
    margin-left: 5rem !important
}

.ms-10 {
    margin-left: 6rem !important
}

.ms-auto {
    margin-left: auto !important
}

.m-n1 {
    margin: -.25rem !important
}

.m-n2 {
    margin: -.5rem !important
}

.m-n3 {
    margin: -1rem !important
}

.m-n4 {
    margin: -1.5rem !important
}

.m-n5 {
    margin: -2rem !important
}

.m-n6 {
    margin: -2.5rem !important
}

.m-n7 {
    margin: -3rem !important
}

.m-n8 {
    margin: -4rem !important
}

.m-n9 {
    margin: -5rem !important
}

.m-n10 {
    margin: -6rem !important
}

.mx-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important
}

.mx-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important
}

.mx-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important
}

.mx-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important
}

.mx-n5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important
}

.mx-n6 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important
}

.mx-n7 {
    margin-left: -3rem !important;
    margin-right: -3rem !important
}

.mx-n8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important
}

.mx-n9 {
    margin-left: -5rem !important;
    margin-right: -5rem !important
}

.mx-n10 {
    margin-left: -6rem !important;
    margin-right: -6rem !important
}

.my-n1 {
    margin-bottom: -.25rem !important;
    margin-top: -.25rem !important
}

.my-n2 {
    margin-bottom: -.5rem !important;
    margin-top: -.5rem !important
}

.my-n3 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important
}

.my-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important
}

.my-n5 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important
}

.my-n6 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important
}

.my-n7 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important
}

.my-n8 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important
}

.my-n9 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important
}

.my-n10 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important
}

.mt-n1 {
    margin-top: -.25rem !important
}

.mt-n2 {
    margin-top: -.5rem !important
}

.mt-n3 {
    margin-top: -1rem !important
}

.mt-n4 {
    margin-top: -1.5rem !important
}

.mt-n5 {
    margin-top: -2rem !important
}

.mt-n6 {
    margin-top: -2.5rem !important
}

.mt-n7 {
    margin-top: -3rem !important
}

.mt-n8 {
    margin-top: -4rem !important
}

.mt-n9 {
    margin-top: -5rem !important
}

.mt-n10 {
    margin-top: -6rem !important
}

.me-n1 {
    margin-right: -.25rem !important
}

.me-n2 {
    margin-right: -.5rem !important
}

.me-n3 {
    margin-right: -1rem !important
}

.me-n4 {
    margin-right: -1.5rem !important
}

.me-n5 {
    margin-right: -2rem !important
}

.me-n6 {
    margin-right: -2.5rem !important
}

.me-n7 {
    margin-right: -3rem !important
}

.me-n8 {
    margin-right: -4rem !important
}

.me-n9 {
    margin-right: -5rem !important
}

.me-n10 {
    margin-right: -6rem !important
}

.mb-n1 {
    margin-bottom: -.25rem !important
}

.mb-n2 {
    margin-bottom: -.5rem !important
}

.mb-n3 {
    margin-bottom: -1rem !important
}

.mb-n4 {
    margin-bottom: -1.5rem !important
}

.mb-n5 {
    margin-bottom: -2rem !important
}

.mb-n6 {
    margin-bottom: -2.5rem !important
}

.mb-n7 {
    margin-bottom: -3rem !important
}

.mb-n8 {
    margin-bottom: -4rem !important
}

.mb-n9 {
    margin-bottom: -5rem !important
}

.mb-n10 {
    margin-bottom: -6rem !important
}

.ms-n1 {
    margin-left: -.25rem !important
}

.ms-n2 {
    margin-left: -.5rem !important
}

.ms-n3 {
    margin-left: -1rem !important
}

.ms-n4 {
    margin-left: -1.5rem !important
}

.ms-n5 {
    margin-left: -2rem !important
}

.ms-n6 {
    margin-left: -2.5rem !important
}

.ms-n7 {
    margin-left: -3rem !important
}

.ms-n8 {
    margin-left: -4rem !important
}

.ms-n9 {
    margin-left: -5rem !important
}

.ms-n10 {
    margin-left: -6rem !important
}

.p-0 {
    padding: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.p-5 {
    padding: 2rem !important
}

.p-6 {
    padding: 2.5rem !important
}

.p-7 {
    padding: 3rem !important
}

.p-8 {
    padding: 4rem !important
}

.p-9 {
    padding: 5rem !important
}

.p-10 {
    padding: 6rem !important
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
}

.px-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important
}

.px-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important
}

.px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important
}

.px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important
}

.px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important
}

.px-6 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important
}

.px-7 {
    padding-left: 3rem !important;
    padding-right: 3rem !important
}

.px-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important
}

.px-9 {
    padding-left: 5rem !important;
    padding-right: 5rem !important
}

.px-10 {
    padding-left: 6rem !important;
    padding-right: 6rem !important
}

.py-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important
}

.py-1 {
    padding-bottom: .25rem !important;
    padding-top: .25rem !important
}

.py-2 {
    padding-bottom: .5rem !important;
    padding-top: .5rem !important
}

.py-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important
}

.py-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important
}

.py-5 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important
}

.py-6 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important
}

.py-7 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important
}

.py-8 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important
}

.py-9 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important
}

.py-10 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pt-3 {
    padding-top: 1rem !important
}

.pt-4 {
    padding-top: 1.5rem !important
}

.pt-5 {
    padding-top: 2rem !important
}

.pt-6 {
    padding-top: 2.5rem !important
}

.pt-7 {
    padding-top: 3rem !important
}

.pt-8 {
    padding-top: 4rem !important
}

.pt-9 {
    padding-top: 5rem !important
}

.pt-10 {
    padding-top: 6rem !important
}

.pe-0 {
    padding-right: 0 !important
}

.pe-1 {
    padding-right: .25rem !important
}

.pe-2 {
    padding-right: .5rem !important
}

.pe-3 {
    padding-right: 1rem !important
}

.pe-4 {
    padding-right: 1.5rem !important
}

.pe-5 {
    padding-right: 2rem !important
}

.pe-6 {
    padding-right: 2.5rem !important
}

.pe-7 {
    padding-right: 3rem !important
}

.pe-8 {
    padding-right: 4rem !important
}

.pe-9 {
    padding-right: 5rem !important
}

.pe-10 {
    padding-right: 6rem !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pb-3 {
    padding-bottom: 1rem !important
}

.pb-4 {
    padding-bottom: 1.5rem !important
}

.pb-5 {
    padding-bottom: 2rem !important
}

.pb-6 {
    padding-bottom: 2.5rem !important
}

.pb-7 {
    padding-bottom: 3rem !important
}

.pb-8 {
    padding-bottom: 4rem !important
}

.pb-9 {
    padding-bottom: 5rem !important
}

.pb-10 {
    padding-bottom: 6rem !important
}

.ps-0 {
    padding-left: 0 !important
}

.ps-1 {
    padding-left: .25rem !important
}

.ps-2 {
    padding-left: .5rem !important
}

.ps-3 {
    padding-left: 1rem !important
}

.ps-4 {
    padding-left: 1.5rem !important
}

.ps-5 {
    padding-left: 2rem !important
}

.ps-6 {
    padding-left: 2.5rem !important
}

.ps-7 {
    padding-left: 3rem !important
}

.ps-8 {
    padding-left: 4rem !important
}

.ps-9 {
    padding-left: 5rem !important
}

.ps-10 {
    padding-left: 6rem !important
}

.gap-0 {
    gap: 0 !important
}

.gap-1 {
    gap: .25rem !important
}

.gap-2 {
    gap: .5rem !important
}

.gap-3 {
    gap: 1rem !important
}

.gap-4 {
    gap: 1.5rem !important
}

.gap-5 {
    gap: 2rem !important
}

.gap-6 {
    gap: 2.5rem !important
}

.gap-7 {
    gap: 3rem !important
}

.gap-8 {
    gap: 4rem !important
}

.gap-9 {
    gap: 5rem !important
}

.gap-10 {
    gap: 6rem !important
}

.row-gap-0 {
    row-gap: 0 !important
}

.row-gap-1 {
    row-gap: .25rem !important
}

.row-gap-2 {
    row-gap: .5rem !important
}

.row-gap-3 {
    row-gap: 1rem !important
}

.row-gap-4 {
    row-gap: 1.5rem !important
}

.row-gap-5 {
    row-gap: 2rem !important
}

.row-gap-6 {
    row-gap: 2.5rem !important
}

.row-gap-7 {
    row-gap: 3rem !important
}

.row-gap-8 {
    row-gap: 4rem !important
}

.row-gap-9 {
    row-gap: 5rem !important
}

.row-gap-10 {
    row-gap: 6rem !important
}

.column-gap-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important
}

.column-gap-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important
}

.column-gap-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important
}

.column-gap-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important
}

.column-gap-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important
}

.column-gap-5 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important
}

.column-gap-6 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important
}

.column-gap-7 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important
}

.column-gap-8 {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important
}

.column-gap-9 {
    -moz-column-gap: 5rem !important;
    column-gap: 5rem !important
}

.column-gap-10 {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important
}

.font-monospace {
    font-family: var(--bs-font-monospace) !important
}

.fs-1 {
    font-size: calc(1.375rem + 1.5vw) !important
}

.fs-2 {
    font-size: calc(1.325rem + .9vw) !important
}

.fs-3 {
    font-size: calc(1.275rem + .3vw) !important
}

.fs-4 {
    font-size: 1.25rem !important
}

.fs-5 {
    font-size: 1rem !important
}

.fs-6 {
    font-size: .875rem !important
}

.fst-italic {
    font-style: italic !important
}

.fst-normal {
    font-style: normal !important
}

.fw-lighter {
    font-weight: lighter !important
}

.fw-light {
    font-weight: 300 !important
}

.fw-normal {
    font-weight: 400 !important
}

.fw-medium {
    font-weight: 500 !important
}

.fw-semibold {
    font-weight: 600 !important
}

.fw-bold {
    font-weight: 700 !important
}

.fw-bolder {
    font-weight: bolder !important
}

.lh-1 {
    line-height: 1 !important
}

.lh-sm {
    line-height: 1.25 !important
}

.lh-base {
    line-height: 1.5 !important
}

.lh-lg {
    line-height: 2 !important
}

.text-start {
    text-align: left !important
}

.text-end {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.text-decoration-none {
    text-decoration: none !important
}

.text-decoration-underline {
    text-decoration: underline !important
}

.text-decoration-line-through {
    text-decoration: line-through !important
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
}

.text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important
}

.text-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important
}

.text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important
}

.text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important
}

.text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important
}

.text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important
}

.text-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important
}

.text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important
}

.text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important
}

.text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important
}

.text-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important
}

.text-muted {
    --bs-text-opacity: 1;
    color: var(--bs-secondary-color) !important
}

.text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(2, 6, 23, .5) !important
}

.text-white-50 {
    --bs-text-opacity: 1;
    color: hsla(0, 0%, 100%, .5) !important
}

.text-body-secondary {
    --bs-text-opacity: 1;
    color: var(--bs-secondary-color) !important
}

.text-body-tertiary {
    --bs-text-opacity: 1;
    color: var(--bs-tertiary-color) !important
}

.text-body-emphasis {
    --bs-text-opacity: 1;
    color: var(--bs-emphasis-color) !important
}

.text-reset {
    --bs-text-opacity: 1;
    color: inherit !important
}

.text-opacity-25 {
    --bs-text-opacity: 0.25
}

.text-opacity-50 {
    --bs-text-opacity: 0.5
}

.text-opacity-75 {
    --bs-text-opacity: 0.75
}

.text-opacity-100 {
    --bs-text-opacity: 1
}

.text-primary-emphasis {
    color: var(--bs-primary-text-emphasis) !important
}

.text-secondary-emphasis {
    color: var(--bs-secondary-text-emphasis) !important
}

.text-success-emphasis {
    color: var(--bs-success-text-emphasis) !important
}

.text-info-emphasis {
    color: var(--bs-info-text-emphasis) !important
}

.text-warning-emphasis {
    color: var(--bs-warning-text-emphasis) !important
}

.text-danger-emphasis {
    color: var(--bs-danger-text-emphasis) !important
}

.text-light-emphasis {
    color: var(--bs-light-text-emphasis) !important
}

.text-dark-emphasis {
    color: var(--bs-dark-text-emphasis) !important
}

.link-opacity-10,
.link-opacity-10-hover:hover {
    --bs-link-opacity: 0.1
}

.link-opacity-25,
.link-opacity-25-hover:hover {
    --bs-link-opacity: 0.25
}

.link-opacity-50,
.link-opacity-50-hover:hover {
    --bs-link-opacity: 0.5
}

.link-opacity-75,
.link-opacity-75-hover:hover {
    --bs-link-opacity: 0.75
}

.link-opacity-100,
.link-opacity-100-hover:hover {
    --bs-link-opacity: 1
}

.link-offset-1,
.link-offset-1-hover:hover {
    text-underline-offset: .125em !important
}

.link-offset-2,
.link-offset-2-hover:hover {
    text-underline-offset: .25em !important
}

.link-offset-3,
.link-offset-3-hover:hover {
    text-underline-offset: .375em !important
}

.link-underline-primary {
    --bs-link-underline-opacity: 1;
    text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-secondary {
    --bs-link-underline-opacity: 1;
    text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-success {
    --bs-link-underline-opacity: 1;
    text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-info {
    --bs-link-underline-opacity: 1;
    text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-warning {
    --bs-link-underline-opacity: 1;
    text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-danger {
    --bs-link-underline-opacity: 1;
    text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-light {
    --bs-link-underline-opacity: 1;
    text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-dark {
    --bs-link-underline-opacity: 1;
    text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-white {
    --bs-link-underline-opacity: 1;
    text-decoration-color: rgba(var(--bs-white-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline {
    --bs-link-underline-opacity: 1;
    text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-underline-opacity-0,
.link-underline-opacity-0-hover:hover {
    --bs-link-underline-opacity: 0
}

.link-underline-opacity-10,
.link-underline-opacity-10-hover:hover {
    --bs-link-underline-opacity: 0.1
}

.link-underline-opacity-25,
.link-underline-opacity-25-hover:hover {
    --bs-link-underline-opacity: 0.25
}

.link-underline-opacity-50,
.link-underline-opacity-50-hover:hover {
    --bs-link-underline-opacity: 0.5
}

.link-underline-opacity-75,
.link-underline-opacity-75-hover:hover {
    --bs-link-underline-opacity: 0.75
}

.link-underline-opacity-100,
.link-underline-opacity-100-hover:hover {
    --bs-link-underline-opacity: 1
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity))  !important
}

.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important
}

.bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important
}

.bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important
}

.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important
}

.bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important
}

.bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important
}

.bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important
}

.bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important
}

.bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important
}

.bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important
}

.bg-body-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important
}

.bg-body-tertiary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important
}

.bg-primary-dark {
    --bs-bg-opacity: 1;
    background-color: #532599 !important
}

.bg-info-dark {
    --bs-bg-opacity: 1;
    background-color: #032830 !important
}

.bg-warning-dark {
    --bs-bg-opacity: 1;
    background-color: #332701 !important
}

.bg-success-dark {
    --bs-bg-opacity: 1;
    background-color: #001a02 !important
}

.bg-secondary-dark {
    --bs-bg-opacity: 1;
    background-color: #2d2e30 !important
}

.bg-danger-dark {
    --bs-bg-opacity: 1;
    background-color: #2c040b !important
}

.bg-gray-100 {
    --bs-bg-opacity: 1;
    background-color: #f1f5f9 !important
}

.bg-gray-200 {
    --bs-bg-opacity: 1;
    background-color: #e2e8f0 !important
}

.bg-gray-300 {
    --bs-bg-opacity: 1;
    background-color: #cbd5e1 !important
}

.bg-gray-400 {
    --bs-bg-opacity: 1;
    background-color: #94a3b8 !important
}

.bg-gray-500 {
    --bs-bg-opacity: 1;
    background-color: #64748b !important
}

.bg-gray-600 {
    --bs-bg-opacity: 1;
    background-color: #475569 !important
}

.bg-gray-700 {
    --bs-bg-opacity: 1;
    background-color: #334155 !important
}

.bg-gray-800 {
    --bs-bg-opacity: 1;
    background-color: #1e293b !important
}

.bg-gray-900 {
    --bs-bg-opacity: 1;
    background-color: #0f172a !important
}

.bg-gray-950 {
    --bs-bg-opacity: 1;
    background-color: #020617 !important
}

.bg-opacity-10 {
    --bs-bg-opacity: 0.1
}

.bg-opacity-25 {
    --bs-bg-opacity: 0.25
}

.bg-opacity-50 {
    --bs-bg-opacity: 0.5
}

.bg-opacity-75 {
    --bs-bg-opacity: 0.75
}

.bg-opacity-100 {
    --bs-bg-opacity: 1
}

.bg-primary-subtle {
    background-color: var(--bs-primary-bg-subtle) !important
}

.bg-secondary-subtle {
    background-color: var(--bs-secondary-bg-subtle) !important
}

.bg-success-subtle {
    background-color: var(--bs-success-bg-subtle) !important
}

.bg-info-subtle {
    background-color: var(--bs-info-bg-subtle) !important
}

.bg-warning-subtle {
    background-color: var(--bs-warning-bg-subtle) !important
}

.bg-danger-subtle {
    background-color: var(--bs-danger-bg-subtle) !important
}

.bg-light-subtle {
    background-color: var(--bs-light-bg-subtle) !important
}

.bg-dark-subtle {
    background-color: var(--bs-dark-bg-subtle) !important
}

.bg-gradient {
    background-image: var(--bs-gradient) !important
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    user-select: auto !important
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important
}

.pe-none {
    pointer-events: none !important
}

.pe-auto {
    pointer-events: auto !important
}

.rounded {
    border-radius: var(--bs-border-radius) !important
}

.rounded-0 {
    border-radius: 0 !important
}

.rounded-1 {
    border-radius: var(--bs-border-radius-sm) !important
}

.rounded-2 {
    border-radius: var(--bs-border-radius) !important
}

.rounded-3 {
    border-radius: var(--bs-border-radius-lg) !important
}

.rounded-4 {
    border-radius: var(--bs-border-radius-xl) !important
}

.rounded-5 {
    border-radius: var(--bs-border-radius-xxl) !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: var(--bs-border-radius-pill) !important
}

.rounded-top {
    border-top-left-radius: var(--bs-border-radius) !important;
    border-top-right-radius: var(--bs-border-radius) !important
}

.rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important
}

.rounded-top-1 {
    border-top-left-radius: var(--bs-border-radius-sm) !important;
    border-top-right-radius: var(--bs-border-radius-sm) !important
}

.rounded-top-2 {
    border-top-left-radius: var(--bs-border-radius) !important;
    border-top-right-radius: var(--bs-border-radius) !important
}

.rounded-top-3 {
    border-top-left-radius: var(--bs-border-radius-lg) !important;
    border-top-right-radius: var(--bs-border-radius-lg) !important
}

.rounded-top-4 {
    border-top-left-radius: var(--bs-border-radius-xl) !important;
    border-top-right-radius: var(--bs-border-radius-xl) !important
}

.rounded-top-5 {
    border-top-left-radius: var(--bs-border-radius-xxl) !important;
    border-top-right-radius: var(--bs-border-radius-xxl) !important
}

.rounded-top-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important
}

.rounded-top-pill {
    border-top-left-radius: var(--bs-border-radius-pill) !important;
    border-top-right-radius: var(--bs-border-radius-pill) !important
}

.rounded-end {
    border-bottom-right-radius: var(--bs-border-radius) !important;
    border-top-right-radius: var(--bs-border-radius) !important
}

.rounded-end-0 {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important
}

.rounded-end-1 {
    border-bottom-right-radius: var(--bs-border-radius-sm) !important;
    border-top-right-radius: var(--bs-border-radius-sm) !important
}

.rounded-end-2 {
    border-bottom-right-radius: var(--bs-border-radius) !important;
    border-top-right-radius: var(--bs-border-radius) !important
}

.rounded-end-3 {
    border-bottom-right-radius: var(--bs-border-radius-lg) !important;
    border-top-right-radius: var(--bs-border-radius-lg) !important
}

.rounded-end-4 {
    border-bottom-right-radius: var(--bs-border-radius-xl) !important;
    border-top-right-radius: var(--bs-border-radius-xl) !important
}

.rounded-end-5 {
    border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
    border-top-right-radius: var(--bs-border-radius-xxl) !important
}

.rounded-end-circle {
    border-bottom-right-radius: 50% !important;
    border-top-right-radius: 50% !important
}

.rounded-end-pill {
    border-bottom-right-radius: var(--bs-border-radius-pill) !important;
    border-top-right-radius: var(--bs-border-radius-pill) !important
}

.rounded-bottom {
    border-bottom-left-radius: var(--bs-border-radius) !important;
    border-bottom-right-radius: var(--bs-border-radius) !important
}

.rounded-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}

.rounded-bottom-1 {
    border-bottom-left-radius: var(--bs-border-radius-sm) !important;
    border-bottom-right-radius: var(--bs-border-radius-sm) !important
}

.rounded-bottom-2 {
    border-bottom-left-radius: var(--bs-border-radius) !important;
    border-bottom-right-radius: var(--bs-border-radius) !important
}

.rounded-bottom-3 {
    border-bottom-left-radius: var(--bs-border-radius-lg) !important;
    border-bottom-right-radius: var(--bs-border-radius-lg) !important
}

.rounded-bottom-4 {
    border-bottom-left-radius: var(--bs-border-radius-xl) !important;
    border-bottom-right-radius: var(--bs-border-radius-xl) !important
}

.rounded-bottom-5 {
    border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
    border-bottom-right-radius: var(--bs-border-radius-xxl) !important
}

.rounded-bottom-circle {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important
}

.rounded-bottom-pill {
    border-bottom-left-radius: var(--bs-border-radius-pill) !important;
    border-bottom-right-radius: var(--bs-border-radius-pill) !important
}

.rounded-start {
    border-bottom-left-radius: var(--bs-border-radius) !important;
    border-top-left-radius: var(--bs-border-radius) !important
}

.rounded-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important
}

.rounded-start-1 {
    border-bottom-left-radius: var(--bs-border-radius-sm) !important;
    border-top-left-radius: var(--bs-border-radius-sm) !important
}

.rounded-start-2 {
    border-bottom-left-radius: var(--bs-border-radius) !important;
    border-top-left-radius: var(--bs-border-radius) !important
}

.rounded-start-3 {
    border-bottom-left-radius: var(--bs-border-radius-lg) !important;
    border-top-left-radius: var(--bs-border-radius-lg) !important
}

.rounded-start-4 {
    border-bottom-left-radius: var(--bs-border-radius-xl) !important;
    border-top-left-radius: var(--bs-border-radius-xl) !important
}

.rounded-start-5 {
    border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
    border-top-left-radius: var(--bs-border-radius-xxl) !important
}

.rounded-start-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important
}

.rounded-start-pill {
    border-bottom-left-radius: var(--bs-border-radius-pill) !important;
    border-top-left-radius: var(--bs-border-radius-pill) !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

.z-n1 {
    z-index: -1 !important
}

.z-0 {
    z-index: 0 !important
}

.z-1 {
    z-index: 1 !important
}

.z-2 {
    z-index: 2 !important
}

.z-3 {
    z-index: 3 !important
}

.ls-xs {
    letter-spacing: -.08em !important
}

.ls-sm {
    letter-spacing: -.04em !important
}

.ls-base {
    letter-spacing: 0 !important
}

.ls-md {
    letter-spacing: .1em !important
}

.ls-lg {
    letter-spacing: .15em !important
}

.ls-xl {
    letter-spacing: .2em !important
}

.ls-xxl {
    letter-spacing: .25em !important
}

@media (min-width:576px) {
    .float-sm-start {
        float: left !important
    }

    .float-sm-end {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }

    .object-fit-sm-contain {
        -o-object-fit: contain !important;
        object-fit: contain !important
    }

    .object-fit-sm-cover {
        -o-object-fit: cover !important;
        object-fit: cover !important
    }

    .object-fit-sm-fill {
        -o-object-fit: fill !important;
        object-fit: fill !important
    }

    .object-fit-sm-scale {
        -o-object-fit: scale-down !important;
        object-fit: scale-down !important
    }

    .object-fit-sm-none {
        -o-object-fit: none !important;
        object-fit: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-grid {
        display: grid !important
    }

    .d-sm-inline-grid {
        display: inline-grid !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }

    .d-sm-none {
        display: none !important
    }

    .position-sm-static {
        position: static !important
    }

    .position-sm-relative {
        position: relative !important
    }

    .position-sm-absolute {
        position: absolute !important
    }

    .position-sm-fixed {
        position: fixed !important
    }

    .position-sm-sticky {
        position: sticky !important
    }

    .top-sm-0 {
        top: 0 !important
    }

    .top-sm-50 {
        top: 50% !important
    }

    .top-sm-100 {
        top: 100% !important
    }

    .bottom-sm-0 {
        bottom: 0 !important
    }

    .bottom-sm-50 {
        bottom: 50% !important
    }

    .bottom-sm-100 {
        bottom: 100% !important
    }

    .start-sm-0 {
        left: 0 !important
    }

    .start-sm-50 {
        left: 50% !important
    }

    .start-sm-100 {
        left: 100% !important
    }

    .translate-middle-sm {
        transform: translate(-50%, -50%) !important
    }

    .translate-middle-sm-x {
        transform: translateX(-50%) !important
    }

    .translate-middle-sm-y {
        transform: translateY(-50%) !important
    }

    .border-sm {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-sm-0 {
        border: 0 !important
    }

    .border-top-sm {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-top-sm-0 {
        border-top: 0 !important
    }

    .border-end-sm {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-end-sm-0 {
        border-right: 0 !important
    }

    .border-bottom-sm {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-bottom-sm-0 {
        border-bottom: 0 !important
    }

    .border-start-sm {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-start-sm-0 {
        border-left: 0 !important
    }

    .border-sm-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important
    }

    .border-sm-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important
    }

    .w-sm-25 {
        width: 25% !important
    }

    .w-sm-50 {
        width: 50% !important
    }

    .w-sm-75 {
        width: 75% !important
    }

    .w-sm-100 {
        width: 100% !important
    }

    .w-sm-auto {
        width: auto !important
    }

    .mw-sm-100 {
        max-width: 100% !important
    }

    .vw-sm-100 {
        width: 100vw !important
    }

    .min-vw-sm-100 {
        min-width: 100vw !important
    }

    .h-sm-25 {
        height: 25% !important
    }

    .h-sm-50 {
        height: 50% !important
    }

    .h-sm-75 {
        height: 75% !important
    }

    .h-sm-100 {
        height: 100% !important
    }

    .h-sm-auto {
        height: auto !important
    }

    .mh-sm-100 {
        max-height: 100% !important
    }

    .min-vh-sm-100 {
        min-height: 100vh !important
    }

    .flex-sm-fill {
        flex: 1 1 auto !important
    }

    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .justify-content-sm-evenly {
        justify-content: space-evenly !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }

    .order-sm-first {
        order: -1 !important
    }

    .order-sm-0 {
        order: 0 !important
    }

    .order-sm-1 {
        order: 1 !important
    }

    .order-sm-2 {
        order: 2 !important
    }

    .order-sm-3 {
        order: 3 !important
    }

    .order-sm-4 {
        order: 4 !important
    }

    .order-sm-5 {
        order: 5 !important
    }

    .order-sm-last {
        order: 6 !important
    }

    .m-sm-0 {
        margin: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .m-sm-3 {
        margin: 1rem !important
    }

    .m-sm-4 {
        margin: 1.5rem !important
    }

    .m-sm-5 {
        margin: 2rem !important
    }

    .m-sm-6 {
        margin: 2.5rem !important
    }

    .m-sm-7 {
        margin: 3rem !important
    }

    .m-sm-8 {
        margin: 4rem !important
    }

    .m-sm-9 {
        margin: 5rem !important
    }

    .m-sm-10 {
        margin: 6rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mx-sm-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .mx-sm-1 {
        margin-left: .25rem !important;
        margin-right: .25rem !important
    }

    .mx-sm-2 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }

    .mx-sm-3 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }

    .mx-sm-4 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }

    .mx-sm-5 {
        margin-left: 2rem !important;
        margin-right: 2rem !important
    }

    .mx-sm-6 {
        margin-left: 2.5rem !important;
        margin-right: 2.5rem !important
    }

    .mx-sm-7 {
        margin-left: 3rem !important;
        margin-right: 3rem !important
    }

    .mx-sm-8 {
        margin-left: 4rem !important;
        margin-right: 4rem !important
    }

    .mx-sm-9 {
        margin-left: 5rem !important;
        margin-right: 5rem !important
    }

    .mx-sm-10 {
        margin-left: 6rem !important;
        margin-right: 6rem !important
    }

    .mx-sm-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .my-sm-0 {
        margin-bottom: 0 !important;
        margin-top: 0 !important
    }

    .my-sm-1 {
        margin-bottom: .25rem !important;
        margin-top: .25rem !important
    }

    .my-sm-2 {
        margin-bottom: .5rem !important;
        margin-top: .5rem !important
    }

    .my-sm-3 {
        margin-bottom: 1rem !important;
        margin-top: 1rem !important
    }

    .my-sm-4 {
        margin-bottom: 1.5rem !important;
        margin-top: 1.5rem !important
    }

    .my-sm-5 {
        margin-bottom: 2rem !important;
        margin-top: 2rem !important
    }

    .my-sm-6 {
        margin-bottom: 2.5rem !important;
        margin-top: 2.5rem !important
    }

    .my-sm-7 {
        margin-bottom: 3rem !important;
        margin-top: 3rem !important
    }

    .my-sm-8 {
        margin-bottom: 4rem !important;
        margin-top: 4rem !important
    }

    .my-sm-9 {
        margin-bottom: 5rem !important;
        margin-top: 5rem !important
    }

    .my-sm-10 {
        margin-bottom: 6rem !important;
        margin-top: 6rem !important
    }

    .my-sm-auto {
        margin-bottom: auto !important;
        margin-top: auto !important
    }

    .mt-sm-0 {
        margin-top: 0 !important
    }

    .mt-sm-1 {
        margin-top: .25rem !important
    }

    .mt-sm-2 {
        margin-top: .5rem !important
    }

    .mt-sm-3 {
        margin-top: 1rem !important
    }

    .mt-sm-4 {
        margin-top: 1.5rem !important
    }

    .mt-sm-5 {
        margin-top: 2rem !important
    }

    .mt-sm-6 {
        margin-top: 2.5rem !important
    }

    .mt-sm-7 {
        margin-top: 3rem !important
    }

    .mt-sm-8 {
        margin-top: 4rem !important
    }

    .mt-sm-9 {
        margin-top: 5rem !important
    }

    .mt-sm-10 {
        margin-top: 6rem !important
    }

    .mt-sm-auto {
        margin-top: auto !important
    }

    .me-sm-0 {
        margin-right: 0 !important
    }

    .me-sm-1 {
        margin-right: .25rem !important
    }

    .me-sm-2 {
        margin-right: .5rem !important
    }

    .me-sm-3 {
        margin-right: 1rem !important
    }

    .me-sm-4 {
        margin-right: 1.5rem !important
    }

    .me-sm-5 {
        margin-right: 2rem !important
    }

    .me-sm-6 {
        margin-right: 2.5rem !important
    }

    .me-sm-7 {
        margin-right: 3rem !important
    }

    .me-sm-8 {
        margin-right: 4rem !important
    }

    .me-sm-9 {
        margin-right: 5rem !important
    }

    .me-sm-10 {
        margin-right: 6rem !important
    }

    .me-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-0 {
        margin-bottom: 0 !important
    }

    .mb-sm-1 {
        margin-bottom: .25rem !important
    }

    .mb-sm-2 {
        margin-bottom: .5rem !important
    }

    .mb-sm-3 {
        margin-bottom: 1rem !important
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-sm-5 {
        margin-bottom: 2rem !important
    }

    .mb-sm-6 {
        margin-bottom: 2.5rem !important
    }

    .mb-sm-7 {
        margin-bottom: 3rem !important
    }

    .mb-sm-8 {
        margin-bottom: 4rem !important
    }

    .mb-sm-9 {
        margin-bottom: 5rem !important
    }

    .mb-sm-10 {
        margin-bottom: 6rem !important
    }

    .mb-sm-auto {
        margin-bottom: auto !important
    }

    .ms-sm-0 {
        margin-left: 0 !important
    }

    .ms-sm-1 {
        margin-left: .25rem !important
    }

    .ms-sm-2 {
        margin-left: .5rem !important
    }

    .ms-sm-3 {
        margin-left: 1rem !important
    }

    .ms-sm-4 {
        margin-left: 1.5rem !important
    }

    .ms-sm-5 {
        margin-left: 2rem !important
    }

    .ms-sm-6 {
        margin-left: 2.5rem !important
    }

    .ms-sm-7 {
        margin-left: 3rem !important
    }

    .ms-sm-8 {
        margin-left: 4rem !important
    }

    .ms-sm-9 {
        margin-left: 5rem !important
    }

    .ms-sm-10 {
        margin-left: 6rem !important
    }

    .ms-sm-auto {
        margin-left: auto !important
    }

    .m-sm-n1 {
        margin: -.25rem !important
    }

    .m-sm-n2 {
        margin: -.5rem !important
    }

    .m-sm-n3 {
        margin: -1rem !important
    }

    .m-sm-n4 {
        margin: -1.5rem !important
    }

    .m-sm-n5 {
        margin: -2rem !important
    }

    .m-sm-n6 {
        margin: -2.5rem !important
    }

    .m-sm-n7 {
        margin: -3rem !important
    }

    .m-sm-n8 {
        margin: -4rem !important
    }

    .m-sm-n9 {
        margin: -5rem !important
    }

    .m-sm-n10 {
        margin: -6rem !important
    }

    .mx-sm-n1 {
        margin-left: -.25rem !important;
        margin-right: -.25rem !important
    }

    .mx-sm-n2 {
        margin-left: -.5rem !important;
        margin-right: -.5rem !important
    }

    .mx-sm-n3 {
        margin-left: -1rem !important;
        margin-right: -1rem !important
    }

    .mx-sm-n4 {
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important
    }

    .mx-sm-n5 {
        margin-left: -2rem !important;
        margin-right: -2rem !important
    }

    .mx-sm-n6 {
        margin-left: -2.5rem !important;
        margin-right: -2.5rem !important
    }

    .mx-sm-n7 {
        margin-left: -3rem !important;
        margin-right: -3rem !important
    }

    .mx-sm-n8 {
        margin-left: -4rem !important;
        margin-right: -4rem !important
    }

    .mx-sm-n9 {
        margin-left: -5rem !important;
        margin-right: -5rem !important
    }

    .mx-sm-n10 {
        margin-left: -6rem !important;
        margin-right: -6rem !important
    }

    .my-sm-n1 {
        margin-bottom: -.25rem !important;
        margin-top: -.25rem !important
    }

    .my-sm-n2 {
        margin-bottom: -.5rem !important;
        margin-top: -.5rem !important
    }

    .my-sm-n3 {
        margin-bottom: -1rem !important;
        margin-top: -1rem !important
    }

    .my-sm-n4 {
        margin-bottom: -1.5rem !important;
        margin-top: -1.5rem !important
    }

    .my-sm-n5 {
        margin-bottom: -2rem !important;
        margin-top: -2rem !important
    }

    .my-sm-n6 {
        margin-bottom: -2.5rem !important;
        margin-top: -2.5rem !important
    }

    .my-sm-n7 {
        margin-bottom: -3rem !important;
        margin-top: -3rem !important
    }

    .my-sm-n8 {
        margin-bottom: -4rem !important;
        margin-top: -4rem !important
    }

    .my-sm-n9 {
        margin-bottom: -5rem !important;
        margin-top: -5rem !important
    }

    .my-sm-n10 {
        margin-bottom: -6rem !important;
        margin-top: -6rem !important
    }

    .mt-sm-n1 {
        margin-top: -.25rem !important
    }

    .mt-sm-n2 {
        margin-top: -.5rem !important
    }

    .mt-sm-n3 {
        margin-top: -1rem !important
    }

    .mt-sm-n4 {
        margin-top: -1.5rem !important
    }

    .mt-sm-n5 {
        margin-top: -2rem !important
    }

    .mt-sm-n6 {
        margin-top: -2.5rem !important
    }

    .mt-sm-n7 {
        margin-top: -3rem !important
    }

    .mt-sm-n8 {
        margin-top: -4rem !important
    }

    .mt-sm-n9 {
        margin-top: -5rem !important
    }

    .mt-sm-n10 {
        margin-top: -6rem !important
    }

    .me-sm-n1 {
        margin-right: -.25rem !important
    }

    .me-sm-n2 {
        margin-right: -.5rem !important
    }

    .me-sm-n3 {
        margin-right: -1rem !important
    }

    .me-sm-n4 {
        margin-right: -1.5rem !important
    }

    .me-sm-n5 {
        margin-right: -2rem !important
    }

    .me-sm-n6 {
        margin-right: -2.5rem !important
    }

    .me-sm-n7 {
        margin-right: -3rem !important
    }

    .me-sm-n8 {
        margin-right: -4rem !important
    }

    .me-sm-n9 {
        margin-right: -5rem !important
    }

    .me-sm-n10 {
        margin-right: -6rem !important
    }

    .mb-sm-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-sm-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-sm-n3 {
        margin-bottom: -1rem !important
    }

    .mb-sm-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-sm-n5 {
        margin-bottom: -2rem !important
    }

    .mb-sm-n6 {
        margin-bottom: -2.5rem !important
    }

    .mb-sm-n7 {
        margin-bottom: -3rem !important
    }

    .mb-sm-n8 {
        margin-bottom: -4rem !important
    }

    .mb-sm-n9 {
        margin-bottom: -5rem !important
    }

    .mb-sm-n10 {
        margin-bottom: -6rem !important
    }

    .ms-sm-n1 {
        margin-left: -.25rem !important
    }

    .ms-sm-n2 {
        margin-left: -.5rem !important
    }

    .ms-sm-n3 {
        margin-left: -1rem !important
    }

    .ms-sm-n4 {
        margin-left: -1.5rem !important
    }

    .ms-sm-n5 {
        margin-left: -2rem !important
    }

    .ms-sm-n6 {
        margin-left: -2.5rem !important
    }

    .ms-sm-n7 {
        margin-left: -3rem !important
    }

    .ms-sm-n8 {
        margin-left: -4rem !important
    }

    .ms-sm-n9 {
        margin-left: -5rem !important
    }

    .ms-sm-n10 {
        margin-left: -6rem !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem !important
    }

    .p-sm-5 {
        padding: 2rem !important
    }

    .p-sm-6 {
        padding: 2.5rem !important
    }

    .p-sm-7 {
        padding: 3rem !important
    }

    .p-sm-8 {
        padding: 4rem !important
    }

    .p-sm-9 {
        padding: 5rem !important
    }

    .p-sm-10 {
        padding: 6rem !important
    }

    .px-sm-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .px-sm-1 {
        padding-left: .25rem !important;
        padding-right: .25rem !important
    }

    .px-sm-2 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }

    .px-sm-3 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }

    .px-sm-4 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    .px-sm-5 {
        padding-left: 2rem !important;
        padding-right: 2rem !important
    }

    .px-sm-6 {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important
    }

    .px-sm-7 {
        padding-left: 3rem !important;
        padding-right: 3rem !important
    }

    .px-sm-8 {
        padding-left: 4rem !important;
        padding-right: 4rem !important
    }

    .px-sm-9 {
        padding-left: 5rem !important;
        padding-right: 5rem !important
    }

    .px-sm-10 {
        padding-left: 6rem !important;
        padding-right: 6rem !important
    }

    .py-sm-0 {
        padding-bottom: 0 !important;
        padding-top: 0 !important
    }

    .py-sm-1 {
        padding-bottom: .25rem !important;
        padding-top: .25rem !important
    }

    .py-sm-2 {
        padding-bottom: .5rem !important;
        padding-top: .5rem !important
    }

    .py-sm-3 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important
    }

    .py-sm-4 {
        padding-bottom: 1.5rem !important;
        padding-top: 1.5rem !important
    }

    .py-sm-5 {
        padding-bottom: 2rem !important;
        padding-top: 2rem !important
    }

    .py-sm-6 {
        padding-bottom: 2.5rem !important;
        padding-top: 2.5rem !important
    }

    .py-sm-7 {
        padding-bottom: 3rem !important;
        padding-top: 3rem !important
    }

    .py-sm-8 {
        padding-bottom: 4rem !important;
        padding-top: 4rem !important
    }

    .py-sm-9 {
        padding-bottom: 5rem !important;
        padding-top: 5rem !important
    }

    .py-sm-10 {
        padding-bottom: 6rem !important;
        padding-top: 6rem !important
    }

    .pt-sm-0 {
        padding-top: 0 !important
    }

    .pt-sm-1 {
        padding-top: .25rem !important
    }

    .pt-sm-2 {
        padding-top: .5rem !important
    }

    .pt-sm-3 {
        padding-top: 1rem !important
    }

    .pt-sm-4 {
        padding-top: 1.5rem !important
    }

    .pt-sm-5 {
        padding-top: 2rem !important
    }

    .pt-sm-6 {
        padding-top: 2.5rem !important
    }

    .pt-sm-7 {
        padding-top: 3rem !important
    }

    .pt-sm-8 {
        padding-top: 4rem !important
    }

    .pt-sm-9 {
        padding-top: 5rem !important
    }

    .pt-sm-10 {
        padding-top: 6rem !important
    }

    .pe-sm-0 {
        padding-right: 0 !important
    }

    .pe-sm-1 {
        padding-right: .25rem !important
    }

    .pe-sm-2 {
        padding-right: .5rem !important
    }

    .pe-sm-3 {
        padding-right: 1rem !important
    }

    .pe-sm-4 {
        padding-right: 1.5rem !important
    }

    .pe-sm-5 {
        padding-right: 2rem !important
    }

    .pe-sm-6 {
        padding-right: 2.5rem !important
    }

    .pe-sm-7 {
        padding-right: 3rem !important
    }

    .pe-sm-8 {
        padding-right: 4rem !important
    }

    .pe-sm-9 {
        padding-right: 5rem !important
    }

    .pe-sm-10 {
        padding-right: 6rem !important
    }

    .pb-sm-0 {
        padding-bottom: 0 !important
    }

    .pb-sm-1 {
        padding-bottom: .25rem !important
    }

    .pb-sm-2 {
        padding-bottom: .5rem !important
    }

    .pb-sm-3 {
        padding-bottom: 1rem !important
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-sm-5 {
        padding-bottom: 2rem !important
    }

    .pb-sm-6 {
        padding-bottom: 2.5rem !important
    }

    .pb-sm-7 {
        padding-bottom: 3rem !important
    }

    .pb-sm-8 {
        padding-bottom: 4rem !important
    }

    .pb-sm-9 {
        padding-bottom: 5rem !important
    }

    .pb-sm-10 {
        padding-bottom: 6rem !important
    }

    .ps-sm-0 {
        padding-left: 0 !important
    }

    .ps-sm-1 {
        padding-left: .25rem !important
    }

    .ps-sm-2 {
        padding-left: .5rem !important
    }

    .ps-sm-3 {
        padding-left: 1rem !important
    }

    .ps-sm-4 {
        padding-left: 1.5rem !important
    }

    .ps-sm-5 {
        padding-left: 2rem !important
    }

    .ps-sm-6 {
        padding-left: 2.5rem !important
    }

    .ps-sm-7 {
        padding-left: 3rem !important
    }

    .ps-sm-8 {
        padding-left: 4rem !important
    }

    .ps-sm-9 {
        padding-left: 5rem !important
    }

    .ps-sm-10 {
        padding-left: 6rem !important
    }

    .gap-sm-0 {
        gap: 0 !important
    }

    .gap-sm-1 {
        gap: .25rem !important
    }

    .gap-sm-2 {
        gap: .5rem !important
    }

    .gap-sm-3 {
        gap: 1rem !important
    }

    .gap-sm-4 {
        gap: 1.5rem !important
    }

    .gap-sm-5 {
        gap: 2rem !important
    }

    .gap-sm-6 {
        gap: 2.5rem !important
    }

    .gap-sm-7 {
        gap: 3rem !important
    }

    .gap-sm-8 {
        gap: 4rem !important
    }

    .gap-sm-9 {
        gap: 5rem !important
    }

    .gap-sm-10 {
        gap: 6rem !important
    }

    .row-gap-sm-0 {
        row-gap: 0 !important
    }

    .row-gap-sm-1 {
        row-gap: .25rem !important
    }

    .row-gap-sm-2 {
        row-gap: .5rem !important
    }

    .row-gap-sm-3 {
        row-gap: 1rem !important
    }

    .row-gap-sm-4 {
        row-gap: 1.5rem !important
    }

    .row-gap-sm-5 {
        row-gap: 2rem !important
    }

    .row-gap-sm-6 {
        row-gap: 2.5rem !important
    }

    .row-gap-sm-7 {
        row-gap: 3rem !important
    }

    .row-gap-sm-8 {
        row-gap: 4rem !important
    }

    .row-gap-sm-9 {
        row-gap: 5rem !important
    }

    .row-gap-sm-10 {
        row-gap: 6rem !important
    }

    .column-gap-sm-0 {
        -moz-column-gap: 0 !important;
        column-gap: 0 !important
    }

    .column-gap-sm-1 {
        -moz-column-gap: .25rem !important;
        column-gap: .25rem !important
    }

    .column-gap-sm-2 {
        -moz-column-gap: .5rem !important;
        column-gap: .5rem !important
    }

    .column-gap-sm-3 {
        -moz-column-gap: 1rem !important;
        column-gap: 1rem !important
    }

    .column-gap-sm-4 {
        -moz-column-gap: 1.5rem !important;
        column-gap: 1.5rem !important
    }

    .column-gap-sm-5 {
        -moz-column-gap: 2rem !important;
        column-gap: 2rem !important
    }

    .column-gap-sm-6 {
        -moz-column-gap: 2.5rem !important;
        column-gap: 2.5rem !important
    }

    .column-gap-sm-7 {
        -moz-column-gap: 3rem !important;
        column-gap: 3rem !important
    }

    .column-gap-sm-8 {
        -moz-column-gap: 4rem !important;
        column-gap: 4rem !important
    }

    .column-gap-sm-9 {
        -moz-column-gap: 5rem !important;
        column-gap: 5rem !important
    }

    .column-gap-sm-10 {
        -moz-column-gap: 6rem !important;
        column-gap: 6rem !important
    }

    .text-sm-start {
        text-align: left !important
    }

    .text-sm-end {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }

    .rounded-top-sm {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-sm-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-top-sm-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-top-sm-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-sm-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-top-sm-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-top-sm-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-top-sm-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-top-sm-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-end-sm {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-sm-0 {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-end-sm-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-end-sm-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-sm-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-end-sm-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-end-sm-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-end-sm-circle {
        border-bottom-right-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-end-sm-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-bottom-sm {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-sm-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-sm-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-bottom-sm-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-sm-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-bottom-sm-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-bottom-sm-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-bottom-sm-circle {
        border-bottom-left-radius: 50% !important;
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-sm-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-start-sm {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-sm-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important
    }

    .rounded-start-sm-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-start-sm-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-sm-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-start-sm-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-start-sm-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-start-sm-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important
    }

    .rounded-start-sm-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important
    }
}

@media (min-width:768px) {
    .float-md-start {
        float: left !important
    }

    .float-md-end {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }

    .object-fit-md-contain {
        -o-object-fit: contain !important;
        object-fit: contain !important
    }

    .object-fit-md-cover {
        -o-object-fit: cover !important;
        object-fit: cover !important
    }

    .object-fit-md-fill {
        -o-object-fit: fill !important;
        object-fit: fill !important
    }

    .object-fit-md-scale {
        -o-object-fit: scale-down !important;
        object-fit: scale-down !important
    }

    .object-fit-md-none {
        -o-object-fit: none !important;
        object-fit: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-grid {
        display: grid !important
    }

    .d-md-inline-grid {
        display: inline-grid !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }

    .d-md-none {
        display: none !important
    }

    .position-md-static {
        position: static !important
    }

    .position-md-relative {
        position: relative !important
    }

    .position-md-absolute {
        position: absolute !important
    }

    .position-md-fixed {
        position: fixed !important
    }

    .position-md-sticky {
        position: sticky !important
    }

    .top-md-0 {
        top: 0 !important
    }

    .top-md-50 {
        top: 50% !important
    }

    .top-md-100 {
        top: 100% !important
    }

    .bottom-md-0 {
        bottom: 0 !important
    }

    .bottom-md-50 {
        bottom: 50% !important
    }

    .bottom-md-100 {
        bottom: 100% !important
    }

    .start-md-0 {
        left: 0 !important
    }

    .start-md-50 {
        left: 50% !important
    }

    .start-md-100 {
        left: 100% !important
    }

    .translate-middle-md {
        transform: translate(-50%, -50%) !important
    }

    .translate-middle-md-x {
        transform: translateX(-50%) !important
    }

    .translate-middle-md-y {
        transform: translateY(-50%) !important
    }

    .border-md {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-md-0 {
        border: 0 !important
    }

    .border-top-md {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-top-md-0 {
        border-top: 0 !important
    }

    .border-end-md {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-end-md-0 {
        border-right: 0 !important
    }

    .border-bottom-md {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-bottom-md-0 {
        border-bottom: 0 !important
    }

    .border-start-md {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-start-md-0 {
        border-left: 0 !important
    }

    .border-md-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important
    }

    .border-md-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important
    }

    .w-md-25 {
        width: 25% !important
    }

    .w-md-50 {
        width: 50% !important
    }

    .w-md-75 {
        width: 75% !important
    }

    .w-md-100 {
        width: 100% !important
    }

    .w-md-auto {
        width: auto !important
    }

    .mw-md-100 {
        max-width: 100% !important
    }

    .vw-md-100 {
        width: 100vw !important
    }

    .min-vw-md-100 {
        min-width: 100vw !important
    }

    .h-md-25 {
        height: 25% !important
    }

    .h-md-50 {
        height: 50% !important
    }

    .h-md-75 {
        height: 75% !important
    }

    .h-md-100 {
        height: 100% !important
    }

    .h-md-auto {
        height: auto !important
    }

    .mh-md-100 {
        max-height: 100% !important
    }

    .min-vh-md-100 {
        min-height: 100vh !important
    }

    .flex-md-fill {
        flex: 1 1 auto !important
    }

    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .justify-content-md-evenly {
        justify-content: space-evenly !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }

    .order-md-first {
        order: -1 !important
    }

    .order-md-0 {
        order: 0 !important
    }

    .order-md-1 {
        order: 1 !important
    }

    .order-md-2 {
        order: 2 !important
    }

    .order-md-3 {
        order: 3 !important
    }

    .order-md-4 {
        order: 4 !important
    }

    .order-md-5 {
        order: 5 !important
    }

    .order-md-last {
        order: 6 !important
    }

    .m-md-0 {
        margin: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .m-md-3 {
        margin: 1rem !important
    }

    .m-md-4 {
        margin: 1.5rem !important
    }

    .m-md-5 {
        margin: 2rem !important
    }

    .m-md-6 {
        margin: 2.5rem !important
    }

    .m-md-7 {
        margin: 3rem !important
    }

    .m-md-8 {
        margin: 4rem !important
    }

    .m-md-9 {
        margin: 5rem !important
    }

    .m-md-10 {
        margin: 6rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mx-md-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .mx-md-1 {
        margin-left: .25rem !important;
        margin-right: .25rem !important
    }

    .mx-md-2 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }

    .mx-md-3 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }

    .mx-md-4 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }

    .mx-md-5 {
        margin-left: 2rem !important;
        margin-right: 2rem !important
    }

    .mx-md-6 {
        margin-left: 2.5rem !important;
        margin-right: 2.5rem !important
    }

    .mx-md-7 {
        margin-left: 3rem !important;
        margin-right: 3rem !important
    }

    .mx-md-8 {
        margin-left: 4rem !important;
        margin-right: 4rem !important
    }

    .mx-md-9 {
        margin-left: 5rem !important;
        margin-right: 5rem !important
    }

    .mx-md-10 {
        margin-left: 6rem !important;
        margin-right: 6rem !important
    }

    .mx-md-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .my-md-0 {
        margin-bottom: 0 !important;
        margin-top: 0 !important
    }

    .my-md-1 {
        margin-bottom: .25rem !important;
        margin-top: .25rem !important
    }

    .my-md-2 {
        margin-bottom: .5rem !important;
        margin-top: .5rem !important
    }

    .my-md-3 {
        margin-bottom: 1rem !important;
        margin-top: 1rem !important
    }

    .my-md-4 {
        margin-bottom: 1.5rem !important;
        margin-top: 1.5rem !important
    }

    .my-md-5 {
        margin-bottom: 2rem !important;
        margin-top: 2rem !important
    }

    .my-md-6 {
        margin-bottom: 2.5rem !important;
        margin-top: 2.5rem !important
    }

    .my-md-7 {
        margin-bottom: 3rem !important;
        margin-top: 3rem !important
    }

    .my-md-8 {
        margin-bottom: 4rem !important;
        margin-top: 4rem !important
    }

    .my-md-9 {
        margin-bottom: 5rem !important;
        margin-top: 5rem !important
    }

    .my-md-10 {
        margin-bottom: 6rem !important;
        margin-top: 6rem !important
    }

    .my-md-auto {
        margin-bottom: auto !important;
        margin-top: auto !important
    }

    .mt-md-0 {
        margin-top: 0 !important
    }

    .mt-md-1 {
        margin-top: .25rem !important
    }

    .mt-md-2 {
        margin-top: .5rem !important
    }

    .mt-md-3 {
        margin-top: 1rem !important
    }

    .mt-md-4 {
        margin-top: 1.5rem !important
    }

    .mt-md-5 {
        margin-top: 2rem !important
    }

    .mt-md-6 {
        margin-top: 2.5rem !important
    }

    .mt-md-7 {
        margin-top: 3rem !important
    }

    .mt-md-8 {
        margin-top: 4rem !important
    }

    .mt-md-9 {
        margin-top: 5rem !important
    }

    .mt-md-10 {
        margin-top: 6rem !important
    }

    .mt-md-auto {
        margin-top: auto !important
    }

    .me-md-0 {
        margin-right: 0 !important
    }

    .me-md-1 {
        margin-right: .25rem !important
    }

    .me-md-2 {
        margin-right: .5rem !important
    }

    .me-md-3 {
        margin-right: 1rem !important
    }

    .me-md-4 {
        margin-right: 1.5rem !important
    }

    .me-md-5 {
        margin-right: 2rem !important
    }

    .me-md-6 {
        margin-right: 2.5rem !important
    }

    .me-md-7 {
        margin-right: 3rem !important
    }

    .me-md-8 {
        margin-right: 4rem !important
    }

    .me-md-9 {
        margin-right: 5rem !important
    }

    .me-md-10 {
        margin-right: 6rem !important
    }

    .me-md-auto {
        margin-right: auto !important
    }

    .mb-md-0 {
        margin-bottom: 0 !important
    }

    .mb-md-1 {
        margin-bottom: .25rem !important
    }

    .mb-md-2 {
        margin-bottom: .5rem !important
    }

    .mb-md-3 {
        margin-bottom: 1rem !important
    }

    .mb-md-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-md-5 {
        margin-bottom: 2rem !important
    }

    .mb-md-6 {
        margin-bottom: 2.5rem !important
    }

    .mb-md-7 {
        margin-bottom: 3rem !important
    }

    .mb-md-8 {
        margin-bottom: 4rem !important
    }

    .mb-md-9 {
        margin-bottom: 5rem !important
    }

    .mb-md-10 {
        margin-bottom: 6rem !important
    }

    .mb-md-auto {
        margin-bottom: auto !important
    }

    .ms-md-0 {
        margin-left: 0 !important
    }

    .ms-md-1 {
        margin-left: .25rem !important
    }

    .ms-md-2 {
        margin-left: .5rem !important
    }

    .ms-md-3 {
        margin-left: 1rem !important
    }

    .ms-md-4 {
        margin-left: 1.5rem !important
    }

    .ms-md-5 {
        margin-left: 2rem !important
    }

    .ms-md-6 {
        margin-left: 2.5rem !important
    }

    .ms-md-7 {
        margin-left: 3rem !important
    }

    .ms-md-8 {
        margin-left: 4rem !important
    }

    .ms-md-9 {
        margin-left: 5rem !important
    }

    .ms-md-10 {
        margin-left: 6rem !important
    }

    .ms-md-auto {
        margin-left: auto !important
    }

    .m-md-n1 {
        margin: -.25rem !important
    }

    .m-md-n2 {
        margin: -.5rem !important
    }

    .m-md-n3 {
        margin: -1rem !important
    }

    .m-md-n4 {
        margin: -1.5rem !important
    }

    .m-md-n5 {
        margin: -2rem !important
    }

    .m-md-n6 {
        margin: -2.5rem !important
    }

    .m-md-n7 {
        margin: -3rem !important
    }

    .m-md-n8 {
        margin: -4rem !important
    }

    .m-md-n9 {
        margin: -5rem !important
    }

    .m-md-n10 {
        margin: -6rem !important
    }

    .mx-md-n1 {
        margin-left: -.25rem !important;
        margin-right: -.25rem !important
    }

    .mx-md-n2 {
        margin-left: -.5rem !important;
        margin-right: -.5rem !important
    }

    .mx-md-n3 {
        margin-left: -1rem !important;
        margin-right: -1rem !important
    }

    .mx-md-n4 {
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important
    }

    .mx-md-n5 {
        margin-left: -2rem !important;
        margin-right: -2rem !important
    }

    .mx-md-n6 {
        margin-left: -2.5rem !important;
        margin-right: -2.5rem !important
    }

    .mx-md-n7 {
        margin-left: -3rem !important;
        margin-right: -3rem !important
    }

    .mx-md-n8 {
        margin-left: -4rem !important;
        margin-right: -4rem !important
    }

    .mx-md-n9 {
        margin-left: -5rem !important;
        margin-right: -5rem !important
    }

    .mx-md-n10 {
        margin-left: -6rem !important;
        margin-right: -6rem !important
    }

    .my-md-n1 {
        margin-bottom: -.25rem !important;
        margin-top: -.25rem !important
    }

    .my-md-n2 {
        margin-bottom: -.5rem !important;
        margin-top: -.5rem !important
    }

    .my-md-n3 {
        margin-bottom: -1rem !important;
        margin-top: -1rem !important
    }

    .my-md-n4 {
        margin-bottom: -1.5rem !important;
        margin-top: -1.5rem !important
    }

    .my-md-n5 {
        margin-bottom: -2rem !important;
        margin-top: -2rem !important
    }

    .my-md-n6 {
        margin-bottom: -2.5rem !important;
        margin-top: -2.5rem !important
    }

    .my-md-n7 {
        margin-bottom: -3rem !important;
        margin-top: -3rem !important
    }

    .my-md-n8 {
        margin-bottom: -4rem !important;
        margin-top: -4rem !important
    }

    .my-md-n9 {
        margin-bottom: -5rem !important;
        margin-top: -5rem !important
    }

    .my-md-n10 {
        margin-bottom: -6rem !important;
        margin-top: -6rem !important
    }

    .mt-md-n1 {
        margin-top: -.25rem !important
    }

    .mt-md-n2 {
        margin-top: -.5rem !important
    }

    .mt-md-n3 {
        margin-top: -1rem !important
    }

    .mt-md-n4 {
        margin-top: -1.5rem !important
    }

    .mt-md-n5 {
        margin-top: -2rem !important
    }

    .mt-md-n6 {
        margin-top: -2.5rem !important
    }

    .mt-md-n7 {
        margin-top: -3rem !important
    }

    .mt-md-n8 {
        margin-top: -4rem !important
    }

    .mt-md-n9 {
        margin-top: -5rem !important
    }

    .mt-md-n10 {
        margin-top: -6rem !important
    }

    .me-md-n1 {
        margin-right: -.25rem !important
    }

    .me-md-n2 {
        margin-right: -.5rem !important
    }

    .me-md-n3 {
        margin-right: -1rem !important
    }

    .me-md-n4 {
        margin-right: -1.5rem !important
    }

    .me-md-n5 {
        margin-right: -2rem !important
    }

    .me-md-n6 {
        margin-right: -2.5rem !important
    }

    .me-md-n7 {
        margin-right: -3rem !important
    }

    .me-md-n8 {
        margin-right: -4rem !important
    }

    .me-md-n9 {
        margin-right: -5rem !important
    }

    .me-md-n10 {
        margin-right: -6rem !important
    }

    .mb-md-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-md-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-md-n3 {
        margin-bottom: -1rem !important
    }

    .mb-md-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-md-n5 {
        margin-bottom: -2rem !important
    }

    .mb-md-n6 {
        margin-bottom: -2.5rem !important
    }

    .mb-md-n7 {
        margin-bottom: -3rem !important
    }

    .mb-md-n8 {
        margin-bottom: -4rem !important
    }

    .mb-md-n9 {
        margin-bottom: -5rem !important
    }

    .mb-md-n10 {
        margin-bottom: -6rem !important
    }

    .ms-md-n1 {
        margin-left: -.25rem !important
    }

    .ms-md-n2 {
        margin-left: -.5rem !important
    }

    .ms-md-n3 {
        margin-left: -1rem !important
    }

    .ms-md-n4 {
        margin-left: -1.5rem !important
    }

    .ms-md-n5 {
        margin-left: -2rem !important
    }

    .ms-md-n6 {
        margin-left: -2.5rem !important
    }

    .ms-md-n7 {
        margin-left: -3rem !important
    }

    .ms-md-n8 {
        margin-left: -4rem !important
    }

    .ms-md-n9 {
        margin-left: -5rem !important
    }

    .ms-md-n10 {
        margin-left: -6rem !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem !important
    }

    .p-md-5 {
        padding: 2rem !important
    }

    .p-md-6 {
        padding: 2.5rem !important
    }

    .p-md-7 {
        padding: 3rem !important
    }

    .p-md-8 {
        padding: 4rem !important
    }

    .p-md-9 {
        padding: 5rem !important
    }

    .p-md-10 {
        padding: 6rem !important
    }

    .px-md-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .px-md-1 {
        padding-left: .25rem !important;
        padding-right: .25rem !important
    }

    .px-md-2 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }

    .px-md-3 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }

    .px-md-4 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    .px-md-5 {
        padding-left: 2rem !important;
        padding-right: 2rem !important
    }

    .px-md-6 {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important
    }

    .px-md-7 {
        padding-left: 3rem !important;
        padding-right: 3rem !important
    }

    .px-md-8 {
        padding-left: 4rem !important;
        padding-right: 4rem !important
    }

    .px-md-9 {
        padding-left: 5rem !important;
        padding-right: 5rem !important
    }

    .px-md-10 {
        padding-left: 6rem !important;
        padding-right: 6rem !important
    }

    .py-md-0 {
        padding-bottom: 0 !important;
        padding-top: 0 !important
    }

    .py-md-1 {
        padding-bottom: .25rem !important;
        padding-top: .25rem !important
    }

    .py-md-2 {
        padding-bottom: .5rem !important;
        padding-top: .5rem !important
    }

    .py-md-3 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important
    }

    .py-md-4 {
        padding-bottom: 1.5rem !important;
        padding-top: 1.5rem !important
    }

    .py-md-5 {
        padding-bottom: 2rem !important;
        padding-top: 2rem !important
    }

    .py-md-6 {
        padding-bottom: 2.5rem !important;
        padding-top: 2.5rem !important
    }

    .py-md-7 {
        padding-bottom: 3rem !important;
        padding-top: 3rem !important
    }

    .py-md-8 {
        padding-bottom: 4rem !important;
        padding-top: 4rem !important
    }

    .py-md-9 {
        padding-bottom: 5rem !important;
        padding-top: 5rem !important
    }

    .py-md-10 {
        padding-bottom: 6rem !important;
        padding-top: 6rem !important
    }

    .pt-md-0 {
        padding-top: 0 !important
    }

    .pt-md-1 {
        padding-top: .25rem !important
    }

    .pt-md-2 {
        padding-top: .5rem !important
    }

    .pt-md-3 {
        padding-top: 1rem !important
    }

    .pt-md-4 {
        padding-top: 1.5rem !important
    }

    .pt-md-5 {
        padding-top: 2rem !important
    }

    .pt-md-6 {
        padding-top: 2.5rem !important
    }

    .pt-md-7 {
        padding-top: 3rem !important
    }

    .pt-md-8 {
        padding-top: 4rem !important
    }

    .pt-md-9 {
        padding-top: 5rem !important
    }

    .pt-md-10 {
        padding-top: 6rem !important
    }

    .pe-md-0 {
        padding-right: 0 !important
    }

    .pe-md-1 {
        padding-right: .25rem !important
    }

    .pe-md-2 {
        padding-right: .5rem !important
    }

    .pe-md-3 {
        padding-right: 1rem !important
    }

    .pe-md-4 {
        padding-right: 1.5rem !important
    }

    .pe-md-5 {
        padding-right: 2rem !important
    }

    .pe-md-6 {
        padding-right: 2.5rem !important
    }

    .pe-md-7 {
        padding-right: 3rem !important
    }

    .pe-md-8 {
        padding-right: 4rem !important
    }

    .pe-md-9 {
        padding-right: 5rem !important
    }

    .pe-md-10 {
        padding-right: 6rem !important
    }

    .pb-md-0 {
        padding-bottom: 0 !important
    }

    .pb-md-1 {
        padding-bottom: .25rem !important
    }

    .pb-md-2 {
        padding-bottom: .5rem !important
    }

    .pb-md-3 {
        padding-bottom: 1rem !important
    }

    .pb-md-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-md-5 {
        padding-bottom: 2rem !important
    }

    .pb-md-6 {
        padding-bottom: 2.5rem !important
    }

    .pb-md-7 {
        padding-bottom: 3rem !important
    }

    .pb-md-8 {
        padding-bottom: 4rem !important
    }

    .pb-md-9 {
        padding-bottom: 5rem !important
    }

    .pb-md-10 {
        padding-bottom: 6rem !important
    }

    .ps-md-0 {
        padding-left: 0 !important
    }

    .ps-md-1 {
        padding-left: .25rem !important
    }

    .ps-md-2 {
        padding-left: .5rem !important
    }

    .ps-md-3 {
        padding-left: 1rem !important
    }

    .ps-md-4 {
        padding-left: 1.5rem !important
    }

    .ps-md-5 {
        padding-left: 2rem !important
    }

    .ps-md-6 {
        padding-left: 2.5rem !important
    }

    .ps-md-7 {
        padding-left: 3rem !important
    }

    .ps-md-8 {
        padding-left: 4rem !important
    }

    .ps-md-9 {
        padding-left: 5rem !important
    }

    .ps-md-10 {
        padding-left: 6rem !important
    }

    .gap-md-0 {
        gap: 0 !important
    }

    .gap-md-1 {
        gap: .25rem !important
    }

    .gap-md-2 {
        gap: .5rem !important
    }

    .gap-md-3 {
        gap: 1rem !important
    }

    .gap-md-4 {
        gap: 1.5rem !important
    }

    .gap-md-5 {
        gap: 2rem !important
    }

    .gap-md-6 {
        gap: 2.5rem !important
    }

    .gap-md-7 {
        gap: 3rem !important
    }

    .gap-md-8 {
        gap: 4rem !important
    }

    .gap-md-9 {
        gap: 5rem !important
    }

    .gap-md-10 {
        gap: 6rem !important
    }

    .row-gap-md-0 {
        row-gap: 0 !important
    }

    .row-gap-md-1 {
        row-gap: .25rem !important
    }

    .row-gap-md-2 {
        row-gap: .5rem !important
    }

    .row-gap-md-3 {
        row-gap: 1rem !important
    }

    .row-gap-md-4 {
        row-gap: 1.5rem !important
    }

    .row-gap-md-5 {
        row-gap: 2rem !important
    }

    .row-gap-md-6 {
        row-gap: 2.5rem !important
    }

    .row-gap-md-7 {
        row-gap: 3rem !important
    }

    .row-gap-md-8 {
        row-gap: 4rem !important
    }

    .row-gap-md-9 {
        row-gap: 5rem !important
    }

    .row-gap-md-10 {
        row-gap: 6rem !important
    }

    .column-gap-md-0 {
        -moz-column-gap: 0 !important;
        column-gap: 0 !important
    }

    .column-gap-md-1 {
        -moz-column-gap: .25rem !important;
        column-gap: .25rem !important
    }

    .column-gap-md-2 {
        -moz-column-gap: .5rem !important;
        column-gap: .5rem !important
    }

    .column-gap-md-3 {
        -moz-column-gap: 1rem !important;
        column-gap: 1rem !important
    }

    .column-gap-md-4 {
        -moz-column-gap: 1.5rem !important;
        column-gap: 1.5rem !important
    }

    .column-gap-md-5 {
        -moz-column-gap: 2rem !important;
        column-gap: 2rem !important
    }

    .column-gap-md-6 {
        -moz-column-gap: 2.5rem !important;
        column-gap: 2.5rem !important
    }

    .column-gap-md-7 {
        -moz-column-gap: 3rem !important;
        column-gap: 3rem !important
    }

    .column-gap-md-8 {
        -moz-column-gap: 4rem !important;
        column-gap: 4rem !important
    }

    .column-gap-md-9 {
        -moz-column-gap: 5rem !important;
        column-gap: 5rem !important
    }

    .column-gap-md-10 {
        -moz-column-gap: 6rem !important;
        column-gap: 6rem !important
    }

    .text-md-start {
        text-align: left !important
    }

    .text-md-end {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }

    .rounded-top-md {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-md-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-top-md-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-top-md-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-md-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-top-md-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-top-md-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-top-md-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-top-md-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-end-md {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-md-0 {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-end-md-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-end-md-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-md-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-end-md-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-end-md-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-end-md-circle {
        border-bottom-right-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-end-md-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-bottom-md {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-md-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-md-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-bottom-md-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-md-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-bottom-md-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-bottom-md-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-bottom-md-circle {
        border-bottom-left-radius: 50% !important;
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-md-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-start-md {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-md-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important
    }

    .rounded-start-md-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-start-md-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-md-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-start-md-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-start-md-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-start-md-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important
    }

    .rounded-start-md-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important
    }
}

@media (min-width:992px) {
    .float-lg-start {
        float: left !important
    }

    .float-lg-end {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }

    .object-fit-lg-contain {
        -o-object-fit: contain !important;
        object-fit: contain !important
    }

    .object-fit-lg-cover {
        -o-object-fit: cover !important;
        object-fit: cover !important
    }

    .object-fit-lg-fill {
        -o-object-fit: fill !important;
        object-fit: fill !important
    }

    .object-fit-lg-scale {
        -o-object-fit: scale-down !important;
        object-fit: scale-down !important
    }

    .object-fit-lg-none {
        -o-object-fit: none !important;
        object-fit: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-grid {
        display: grid !important
    }

    .d-lg-inline-grid {
        display: inline-grid !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }

    .d-lg-none {
        display: none !important
    }

    .position-lg-static {
        position: static !important
    }

    .position-lg-relative {
        position: relative !important
    }

    .position-lg-absolute {
        position: absolute !important
    }

    .position-lg-fixed {
        position: fixed !important
    }

    .position-lg-sticky {
        position: sticky !important
    }

    .top-lg-0 {
        top: 0 !important
    }

    .top-lg-50 {
        top: 50% !important
    }

    .top-lg-100 {
        top: 100% !important
    }

    .bottom-lg-0 {
        bottom: 0 !important
    }

    .bottom-lg-50 {
        bottom: 50% !important
    }

    .bottom-lg-100 {
        bottom: 100% !important
    }

    .start-lg-0 {
        left: 0 !important
    }

    .start-lg-50 {
        left: 50% !important
    }

    .start-lg-100 {
        left: 100% !important
    }

    .translate-middle-lg {
        transform: translate(-50%, -50%) !important
    }

    .translate-middle-lg-x {
        transform: translateX(-50%) !important
    }

    .translate-middle-lg-y {
        transform: translateY(-50%) !important
    }

    .border-lg {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-lg-0 {
        border: 0 !important
    }

    .border-top-lg {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-top-lg-0 {
        border-top: 0 !important
    }

    .border-end-lg {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-end-lg-0 {
        border-right: 0 !important
    }

    .border-bottom-lg {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-bottom-lg-0 {
        border-bottom: 0 !important
    }

    .border-start-lg {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-start-lg-0 {
        border-left: 0 !important
    }

    .border-lg-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important
    }

    .border-lg-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important
    }

    .w-lg-25 {
        width: 25% !important
    }

    .w-lg-50 {
        width: 50% !important
    }

    .w-lg-75 {
        width: 75% !important
    }

    .w-lg-100 {
        width: 100% !important
    }

    .w-lg-auto {
        width: auto !important
    }

    .mw-lg-100 {
        max-width: 100% !important
    }

    .vw-lg-100 {
        width: 100vw !important
    }

    .min-vw-lg-100 {
        min-width: 100vw !important
    }

    .h-lg-25 {
        height: 25% !important
    }

    .h-lg-50 {
        height: 50% !important
    }

    .h-lg-75 {
        height: 75% !important
    }

    .h-lg-100 {
        height: 100% !important
    }

    .h-lg-auto {
        height: auto !important
    }

    .mh-lg-100 {
        max-height: 100% !important
    }

    .min-vh-lg-100 {
        min-height: 100vh !important
    }

    .flex-lg-fill {
        flex: 1 1 auto !important
    }

    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .justify-content-lg-evenly {
        justify-content: space-evenly !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }

    .order-lg-first {
        order: -1 !important
    }

    .order-lg-0 {
        order: 0 !important
    }

    .order-lg-1 {
        order: 1 !important
    }

    .order-lg-2 {
        order: 2 !important
    }

    .order-lg-3 {
        order: 3 !important
    }

    .order-lg-4 {
        order: 4 !important
    }

    .order-lg-5 {
        order: 5 !important
    }

    .order-lg-last {
        order: 6 !important
    }

    .m-lg-0 {
        margin: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .m-lg-3 {
        margin: 1rem !important
    }

    .m-lg-4 {
        margin: 1.5rem !important
    }

    .m-lg-5 {
        margin: 2rem !important
    }

    .m-lg-6 {
        margin: 2.5rem !important
    }

    .m-lg-7 {
        margin: 3rem !important
    }

    .m-lg-8 {
        margin: 4rem !important
    }

    .m-lg-9 {
        margin: 5rem !important
    }

    .m-lg-10 {
        margin: 6rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mx-lg-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .mx-lg-1 {
        margin-left: .25rem !important;
        margin-right: .25rem !important
    }

    .mx-lg-2 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }

    .mx-lg-3 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }

    .mx-lg-4 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }

    .mx-lg-5 {
        margin-left: 2rem !important;
        margin-right: 2rem !important
    }

    .mx-lg-6 {
        margin-left: 2.5rem !important;
        margin-right: 2.5rem !important
    }

    .mx-lg-7 {
        margin-left: 3rem !important;
        margin-right: 3rem !important
    }

    .mx-lg-8 {
        margin-left: 4rem !important;
        margin-right: 4rem !important
    }

    .mx-lg-9 {
        margin-left: 5rem !important;
        margin-right: 5rem !important
    }

    .mx-lg-10 {
        margin-left: 6rem !important;
        margin-right: 6rem !important
    }

    .mx-lg-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .my-lg-0 {
        margin-bottom: 0 !important;
        margin-top: 0 !important
    }

    .my-lg-1 {
        margin-bottom: .25rem !important;
        margin-top: .25rem !important
    }

    .my-lg-2 {
        margin-bottom: .5rem !important;
        margin-top: .5rem !important
    }

    .my-lg-3 {
        margin-bottom: 1rem !important;
        margin-top: 1rem !important
    }

    .my-lg-4 {
        margin-bottom: 1.5rem !important;
        margin-top: 1.5rem !important
    }

    .my-lg-5 {
        margin-bottom: 2rem !important;
        margin-top: 2rem !important
    }

    .my-lg-6 {
        margin-bottom: 2.5rem !important;
        margin-top: 2.5rem !important
    }

    .my-lg-7 {
        margin-bottom: 3rem !important;
        margin-top: 3rem !important
    }

    .my-lg-8 {
        margin-bottom: 4rem !important;
        margin-top: 4rem !important
    }

    .my-lg-9 {
        margin-bottom: 5rem !important;
        margin-top: 5rem !important
    }

    .my-lg-10 {
        margin-bottom: 6rem !important;
        margin-top: 6rem !important
    }

    .my-lg-auto {
        margin-bottom: auto !important;
        margin-top: auto !important
    }

    .mt-lg-0 {
        margin-top: 0 !important
    }

    .mt-lg-1 {
        margin-top: .25rem !important
    }

    .mt-lg-2 {
        margin-top: .5rem !important
    }

    .mt-lg-3 {
        margin-top: 1rem !important
    }

    .mt-lg-4 {
        margin-top: 1.5rem !important
    }

    .mt-lg-5 {
        margin-top: 2rem !important
    }

    .mt-lg-6 {
        margin-top: 2.5rem !important
    }

    .mt-lg-7 {
        margin-top: 3rem !important
    }

    .mt-lg-8 {
        margin-top: 4rem !important
    }

    .mt-lg-9 {
        margin-top: 5rem !important
    }

    .mt-lg-10 {
        margin-top: 6rem !important
    }

    .mt-lg-auto {
        margin-top: auto !important
    }

    .me-lg-0 {
        margin-right: 0 !important
    }

    .me-lg-1 {
        margin-right: .25rem !important
    }

    .me-lg-2 {
        margin-right: .5rem !important
    }

    .me-lg-3 {
        margin-right: 1rem !important
    }

    .me-lg-4 {
        margin-right: 1.5rem !important
    }

    .me-lg-5 {
        margin-right: 2rem !important
    }

    .me-lg-6 {
        margin-right: 2.5rem !important
    }

    .me-lg-7 {
        margin-right: 3rem !important
    }

    .me-lg-8 {
        margin-right: 4rem !important
    }

    .me-lg-9 {
        margin-right: 5rem !important
    }

    .me-lg-10 {
        margin-right: 6rem !important
    }

    .me-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-0 {
        margin-bottom: 0 !important
    }

    .mb-lg-1 {
        margin-bottom: .25rem !important
    }

    .mb-lg-2 {
        margin-bottom: .5rem !important
    }

    .mb-lg-3 {
        margin-bottom: 1rem !important
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-lg-5 {
        margin-bottom: 2rem !important
    }

    .mb-lg-6 {
        margin-bottom: 2.5rem !important
    }

    .mb-lg-7 {
        margin-bottom: 3rem !important
    }

    .mb-lg-8 {
        margin-bottom: 4rem !important
    }

    .mb-lg-9 {
        margin-bottom: 5rem !important
    }

    .mb-lg-10 {
        margin-bottom: 6rem !important
    }

    .mb-lg-auto {
        margin-bottom: auto !important
    }

    .ms-lg-0 {
        margin-left: 0 !important
    }

    .ms-lg-1 {
        margin-left: .25rem !important
    }

    .ms-lg-2 {
        margin-left: .5rem !important
    }

    .ms-lg-3 {
        margin-left: 1rem !important
    }

    .ms-lg-4 {
        margin-left: 1.5rem !important
    }

    .ms-lg-5 {
        margin-left: 2rem !important
    }

    .ms-lg-6 {
        margin-left: 2.5rem !important
    }

    .ms-lg-7 {
        margin-left: 3rem !important
    }

    .ms-lg-8 {
        margin-left: 4rem !important
    }

    .ms-lg-9 {
        margin-left: 5rem !important
    }

    .ms-lg-10 {
        margin-left: 6rem !important
    }

    .ms-lg-auto {
        margin-left: auto !important
    }

    .m-lg-n1 {
        margin: -.25rem !important
    }

    .m-lg-n2 {
        margin: -.5rem !important
    }

    .m-lg-n3 {
        margin: -1rem !important
    }

    .m-lg-n4 {
        margin: -1.5rem !important
    }

    .m-lg-n5 {
        margin: -2rem !important
    }

    .m-lg-n6 {
        margin: -2.5rem !important
    }

    .m-lg-n7 {
        margin: -3rem !important
    }

    .m-lg-n8 {
        margin: -4rem !important
    }

    .m-lg-n9 {
        margin: -5rem !important
    }

    .m-lg-n10 {
        margin: -6rem !important
    }

    .mx-lg-n1 {
        margin-left: -.25rem !important;
        margin-right: -.25rem !important
    }

    .mx-lg-n2 {
        margin-left: -.5rem !important;
        margin-right: -.5rem !important
    }

    .mx-lg-n3 {
        margin-left: -1rem !important;
        margin-right: -1rem !important
    }

    .mx-lg-n4 {
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important
    }

    .mx-lg-n5 {
        margin-left: -2rem !important;
        margin-right: -2rem !important
    }

    .mx-lg-n6 {
        margin-left: -2.5rem !important;
        margin-right: -2.5rem !important
    }

    .mx-lg-n7 {
        margin-left: -3rem !important;
        margin-right: -3rem !important
    }

    .mx-lg-n8 {
        margin-left: -4rem !important;
        margin-right: -4rem !important
    }

    .mx-lg-n9 {
        margin-left: -5rem !important;
        margin-right: -5rem !important
    }

    .mx-lg-n10 {
        margin-left: -6rem !important;
        margin-right: -6rem !important
    }

    .my-lg-n1 {
        margin-bottom: -.25rem !important;
        margin-top: -.25rem !important
    }

    .my-lg-n2 {
        margin-bottom: -.5rem !important;
        margin-top: -.5rem !important
    }

    .my-lg-n3 {
        margin-bottom: -1rem !important;
        margin-top: -1rem !important
    }

    .my-lg-n4 {
        margin-bottom: -1.5rem !important;
        margin-top: -1.5rem !important
    }

    .my-lg-n5 {
        margin-bottom: -2rem !important;
        margin-top: -2rem !important
    }

    .my-lg-n6 {
        margin-bottom: -2.5rem !important;
        margin-top: -2.5rem !important
    }

    .my-lg-n7 {
        margin-bottom: -3rem !important;
        margin-top: -3rem !important
    }

    .my-lg-n8 {
        margin-bottom: -4rem !important;
        margin-top: -4rem !important
    }

    .my-lg-n9 {
        margin-bottom: -5rem !important;
        margin-top: -5rem !important
    }

    .my-lg-n10 {
        margin-bottom: -6rem !important;
        margin-top: -6rem !important
    }

    .mt-lg-n1 {
        margin-top: -.25rem !important
    }

    .mt-lg-n2 {
        margin-top: -.5rem !important
    }

    .mt-lg-n3 {
        margin-top: -1rem !important
    }

    .mt-lg-n4 {
        margin-top: -1.5rem !important
    }

    .mt-lg-n5 {
        margin-top: -2rem !important
    }

    .mt-lg-n6 {
        margin-top: -2.5rem !important
    }

    .mt-lg-n7 {
        margin-top: -3rem !important
    }

    .mt-lg-n8 {
        margin-top: -4rem !important
    }

    .mt-lg-n9 {
        margin-top: -5rem !important
    }

    .mt-lg-n10 {
        margin-top: -6rem !important
    }

    .me-lg-n1 {
        margin-right: -.25rem !important
    }

    .me-lg-n2 {
        margin-right: -.5rem !important
    }

    .me-lg-n3 {
        margin-right: -1rem !important
    }

    .me-lg-n4 {
        margin-right: -1.5rem !important
    }

    .me-lg-n5 {
        margin-right: -2rem !important
    }

    .me-lg-n6 {
        margin-right: -2.5rem !important
    }

    .me-lg-n7 {
        margin-right: -3rem !important
    }

    .me-lg-n8 {
        margin-right: -4rem !important
    }

    .me-lg-n9 {
        margin-right: -5rem !important
    }

    .me-lg-n10 {
        margin-right: -6rem !important
    }

    .mb-lg-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-lg-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-lg-n3 {
        margin-bottom: -1rem !important
    }

    .mb-lg-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-lg-n5 {
        margin-bottom: -2rem !important
    }

    .mb-lg-n6 {
        margin-bottom: -2.5rem !important
    }

    .mb-lg-n7 {
        margin-bottom: -3rem !important
    }

    .mb-lg-n8 {
        margin-bottom: -4rem !important
    }

    .mb-lg-n9 {
        margin-bottom: -5rem !important
    }

    .mb-lg-n10 {
        margin-bottom: -6rem !important
    }

    .ms-lg-n1 {
        margin-left: -.25rem !important
    }

    .ms-lg-n2 {
        margin-left: -.5rem !important
    }

    .ms-lg-n3 {
        margin-left: -1rem !important
    }

    .ms-lg-n4 {
        margin-left: -1.5rem !important
    }

    .ms-lg-n5 {
        margin-left: -2rem !important
    }

    .ms-lg-n6 {
        margin-left: -2.5rem !important
    }

    .ms-lg-n7 {
        margin-left: -3rem !important
    }

    .ms-lg-n8 {
        margin-left: -4rem !important
    }

    .ms-lg-n9 {
        margin-left: -5rem !important
    }

    .ms-lg-n10 {
        margin-left: -6rem !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem !important
    }

    .p-lg-5 {
        padding: 2rem !important
    }

    .p-lg-6 {
        padding: 2.5rem !important
    }

    .p-lg-7 {
        padding: 3rem !important
    }

    .p-lg-8 {
        padding: 4rem !important
    }

    .p-lg-9 {
        padding: 5rem !important
    }

    .p-lg-10 {
        padding: 6rem !important
    }

    .px-lg-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .px-lg-1 {
        padding-left: .25rem !important;
        padding-right: .25rem !important
    }

    .px-lg-2 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }

    .px-lg-3 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }

    .px-lg-4 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    .px-lg-5 {
        padding-left: 2rem !important;
        padding-right: 2rem !important
    }

    .px-lg-6 {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important
    }

    .px-lg-7 {
        padding-left: 3rem !important;
        padding-right: 3rem !important
    }

    .px-lg-8 {
        padding-left: 4rem !important;
        padding-right: 4rem !important
    }

    .px-lg-9 {
        padding-left: 5rem !important;
        padding-right: 5rem !important
    }

    .px-lg-10 {
        padding-left: 6rem !important;
        padding-right: 6rem !important
    }

    .py-lg-0 {
        padding-bottom: 0 !important;
        padding-top: 0 !important
    }

    .py-lg-1 {
        padding-bottom: .25rem !important;
        padding-top: .25rem !important
    }

    .py-lg-2 {
        padding-bottom: .5rem !important;
        padding-top: .5rem !important
    }

    .py-lg-3 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important
    }

    .py-lg-4 {
        padding-bottom: 1.5rem !important;
        padding-top: 1.5rem !important
    }

    .py-lg-5 {
        padding-bottom: 2rem !important;
        padding-top: 2rem !important
    }

    .py-lg-6 {
        padding-bottom: 2.5rem !important;
        padding-top: 2.5rem !important
    }

    .py-lg-7 {
        padding-bottom: 3rem !important;
        padding-top: 3rem !important
    }

    .py-lg-8 {
        padding-bottom: 4rem !important;
        padding-top: 4rem !important
    }

    .py-lg-9 {
        padding-bottom: 5rem !important;
        padding-top: 5rem !important
    }

    .py-lg-10 {
        padding-bottom: 6rem !important;
        padding-top: 6rem !important
    }

    .pt-lg-0 {
        padding-top: 0 !important
    }

    .pt-lg-1 {
        padding-top: .25rem !important
    }

    .pt-lg-2 {
        padding-top: .5rem !important
    }

    .pt-lg-3 {
        padding-top: 1rem !important
    }

    .pt-lg-4 {
        padding-top: 1.5rem !important
    }

    .pt-lg-5 {
        padding-top: 2rem !important
    }

    .pt-lg-6 {
        padding-top: 2.5rem !important
    }

    .pt-lg-7 {
        padding-top: 3rem !important
    }

    .pt-lg-8 {
        padding-top: 4rem !important
    }

    .pt-lg-9 {
        padding-top: 5rem !important
    }

    .pt-lg-10 {
        padding-top: 6rem !important
    }

    .pe-lg-0 {
        padding-right: 0 !important
    }

    .pe-lg-1 {
        padding-right: .25rem !important
    }

    .pe-lg-2 {
        padding-right: .5rem !important
    }

    .pe-lg-3 {
        padding-right: 1rem !important
    }

    .pe-lg-4 {
        padding-right: 1.5rem !important
    }

    .pe-lg-5 {
        padding-right: 2rem !important
    }

    .pe-lg-6 {
        padding-right: 2.5rem !important
    }

    .pe-lg-7 {
        padding-right: 3rem !important
    }

    .pe-lg-8 {
        padding-right: 4rem !important
    }

    .pe-lg-9 {
        padding-right: 5rem !important
    }

    .pe-lg-10 {
        padding-right: 6rem !important
    }

    .pb-lg-0 {
        padding-bottom: 0 !important
    }

    .pb-lg-1 {
        padding-bottom: .25rem !important
    }

    .pb-lg-2 {
        padding-bottom: .5rem !important
    }

    .pb-lg-3 {
        padding-bottom: 1rem !important
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-lg-5 {
        padding-bottom: 2rem !important
    }

    .pb-lg-6 {
        padding-bottom: 2.5rem !important
    }

    .pb-lg-7 {
        padding-bottom: 3rem !important
    }

    .pb-lg-8 {
        padding-bottom: 4rem !important
    }

    .pb-lg-9 {
        padding-bottom: 5rem !important
    }

    .pb-lg-10 {
        padding-bottom: 6rem !important
    }

    .ps-lg-0 {
        padding-left: 0 !important
    }

    .ps-lg-1 {
        padding-left: .25rem !important
    }

    .ps-lg-2 {
        padding-left: .5rem !important
    }

    .ps-lg-3 {
        padding-left: 1rem !important
    }

    .ps-lg-4 {
        padding-left: 1.5rem !important
    }

    .ps-lg-5 {
        padding-left: 2rem !important
    }

    .ps-lg-6 {
        padding-left: 2.5rem !important
    }

    .ps-lg-7 {
        padding-left: 3rem !important
    }

    .ps-lg-8 {
        padding-left: 4rem !important
    }

    .ps-lg-9 {
        padding-left: 5rem !important
    }

    .ps-lg-10 {
        padding-left: 6rem !important
    }

    .gap-lg-0 {
        gap: 0 !important
    }

    .gap-lg-1 {
        gap: .25rem !important
    }

    .gap-lg-2 {
        gap: .5rem !important
    }

    .gap-lg-3 {
        gap: 1rem !important
    }

    .gap-lg-4 {
        gap: 1.5rem !important
    }

    .gap-lg-5 {
        gap: 2rem !important
    }

    .gap-lg-6 {
        gap: 2.5rem !important
    }

    .gap-lg-7 {
        gap: 3rem !important
    }

    .gap-lg-8 {
        gap: 4rem !important
    }

    .gap-lg-9 {
        gap: 5rem !important
    }

    .gap-lg-10 {
        gap: 6rem !important
    }

    .row-gap-lg-0 {
        row-gap: 0 !important
    }

    .row-gap-lg-1 {
        row-gap: .25rem !important
    }

    .row-gap-lg-2 {
        row-gap: .5rem !important
    }

    .row-gap-lg-3 {
        row-gap: 1rem !important
    }

    .row-gap-lg-4 {
        row-gap: 1.5rem !important
    }

    .row-gap-lg-5 {
        row-gap: 2rem !important
    }

    .row-gap-lg-6 {
        row-gap: 2.5rem !important
    }

    .row-gap-lg-7 {
        row-gap: 3rem !important
    }

    .row-gap-lg-8 {
        row-gap: 4rem !important
    }

    .row-gap-lg-9 {
        row-gap: 5rem !important
    }

    .row-gap-lg-10 {
        row-gap: 6rem !important
    }

    .column-gap-lg-0 {
        -moz-column-gap: 0 !important;
        column-gap: 0 !important
    }

    .column-gap-lg-1 {
        -moz-column-gap: .25rem !important;
        column-gap: .25rem !important
    }

    .column-gap-lg-2 {
        -moz-column-gap: .5rem !important;
        column-gap: .5rem !important
    }

    .column-gap-lg-3 {
        -moz-column-gap: 1rem !important;
        column-gap: 1rem !important
    }

    .column-gap-lg-4 {
        -moz-column-gap: 1.5rem !important;
        column-gap: 1.5rem !important
    }

    .column-gap-lg-5 {
        -moz-column-gap: 2rem !important;
        column-gap: 2rem !important
    }

    .column-gap-lg-6 {
        -moz-column-gap: 2.5rem !important;
        column-gap: 2.5rem !important
    }

    .column-gap-lg-7 {
        -moz-column-gap: 3rem !important;
        column-gap: 3rem !important
    }

    .column-gap-lg-8 {
        -moz-column-gap: 4rem !important;
        column-gap: 4rem !important
    }

    .column-gap-lg-9 {
        -moz-column-gap: 5rem !important;
        column-gap: 5rem !important
    }

    .column-gap-lg-10 {
        -moz-column-gap: 6rem !important;
        column-gap: 6rem !important
    }

    .text-lg-start {
        text-align: left !important
    }

    .text-lg-end {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }

    .rounded-top-lg {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-lg-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-top-lg-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-top-lg-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-lg-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-top-lg-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-top-lg-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-top-lg-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-top-lg-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-end-lg {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-lg-0 {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-end-lg-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-end-lg-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-lg-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-end-lg-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-end-lg-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-end-lg-circle {
        border-bottom-right-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-end-lg-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-bottom-lg {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-lg-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-lg-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-bottom-lg-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-lg-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-bottom-lg-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-bottom-lg-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-bottom-lg-circle {
        border-bottom-left-radius: 50% !important;
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-lg-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-start-lg {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-lg-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important
    }

    .rounded-start-lg-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-start-lg-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-lg-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-start-lg-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-start-lg-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-start-lg-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important
    }

    .rounded-start-lg-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important
    }
}

@media (min-width:1200px) {
    .float-xl-start {
        float: left !important
    }

    .float-xl-end {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }

    .object-fit-xl-contain {
        -o-object-fit: contain !important;
        object-fit: contain !important
    }

    .object-fit-xl-cover {
        -o-object-fit: cover !important;
        object-fit: cover !important
    }

    .object-fit-xl-fill {
        -o-object-fit: fill !important;
        object-fit: fill !important
    }

    .object-fit-xl-scale {
        -o-object-fit: scale-down !important;
        object-fit: scale-down !important
    }

    .object-fit-xl-none {
        -o-object-fit: none !important;
        object-fit: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-grid {
        display: grid !important
    }

    .d-xl-inline-grid {
        display: inline-grid !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: flex !important
    }

    .d-xl-inline-flex {
        display: inline-flex !important
    }

    .d-xl-none {
        display: none !important
    }

    .position-xl-static {
        position: static !important
    }

    .position-xl-relative {
        position: relative !important
    }

    .position-xl-absolute {
        position: absolute !important
    }

    .position-xl-fixed {
        position: fixed !important
    }

    .position-xl-sticky {
        position: sticky !important
    }

    .top-xl-0 {
        top: 0 !important
    }

    .top-xl-50 {
        top: 50% !important
    }

    .top-xl-100 {
        top: 100% !important
    }

    .bottom-xl-0 {
        bottom: 0 !important
    }

    .bottom-xl-50 {
        bottom: 50% !important
    }

    .bottom-xl-100 {
        bottom: 100% !important
    }

    .start-xl-0 {
        left: 0 !important
    }

    .start-xl-50 {
        left: 50% !important
    }

    .start-xl-100 {
        left: 100% !important
    }

    .translate-middle-xl {
        transform: translate(-50%, -50%) !important
    }

    .translate-middle-xl-x {
        transform: translateX(-50%) !important
    }

    .translate-middle-xl-y {
        transform: translateY(-50%) !important
    }

    .border-xl {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-xl-0 {
        border: 0 !important
    }

    .border-top-xl {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-top-xl-0 {
        border-top: 0 !important
    }

    .border-end-xl {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-end-xl-0 {
        border-right: 0 !important
    }

    .border-bottom-xl {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-bottom-xl-0 {
        border-bottom: 0 !important
    }

    .border-start-xl {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-start-xl-0 {
        border-left: 0 !important
    }

    .border-xl-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important
    }

    .border-xl-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important
    }

    .w-xl-25 {
        width: 25% !important
    }

    .w-xl-50 {
        width: 50% !important
    }

    .w-xl-75 {
        width: 75% !important
    }

    .w-xl-100 {
        width: 100% !important
    }

    .w-xl-auto {
        width: auto !important
    }

    .mw-xl-100 {
        max-width: 100% !important
    }

    .vw-xl-100 {
        width: 100vw !important
    }

    .min-vw-xl-100 {
        min-width: 100vw !important
    }

    .h-xl-25 {
        height: 25% !important
    }

    .h-xl-50 {
        height: 50% !important
    }

    .h-xl-75 {
        height: 75% !important
    }

    .h-xl-100 {
        height: 100% !important
    }

    .h-xl-auto {
        height: auto !important
    }

    .mh-xl-100 {
        max-height: 100% !important
    }

    .min-vh-xl-100 {
        min-height: 100vh !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .justify-content-xl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }

    .order-xl-first {
        order: -1 !important
    }

    .order-xl-0 {
        order: 0 !important
    }

    .order-xl-1 {
        order: 1 !important
    }

    .order-xl-2 {
        order: 2 !important
    }

    .order-xl-3 {
        order: 3 !important
    }

    .order-xl-4 {
        order: 4 !important
    }

    .order-xl-5 {
        order: 5 !important
    }

    .order-xl-last {
        order: 6 !important
    }

    .m-xl-0 {
        margin: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .m-xl-3 {
        margin: 1rem !important
    }

    .m-xl-4 {
        margin: 1.5rem !important
    }

    .m-xl-5 {
        margin: 2rem !important
    }

    .m-xl-6 {
        margin: 2.5rem !important
    }

    .m-xl-7 {
        margin: 3rem !important
    }

    .m-xl-8 {
        margin: 4rem !important
    }

    .m-xl-9 {
        margin: 5rem !important
    }

    .m-xl-10 {
        margin: 6rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mx-xl-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .mx-xl-1 {
        margin-left: .25rem !important;
        margin-right: .25rem !important
    }

    .mx-xl-2 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }

    .mx-xl-3 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }

    .mx-xl-4 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }

    .mx-xl-5 {
        margin-left: 2rem !important;
        margin-right: 2rem !important
    }

    .mx-xl-6 {
        margin-left: 2.5rem !important;
        margin-right: 2.5rem !important
    }

    .mx-xl-7 {
        margin-left: 3rem !important;
        margin-right: 3rem !important
    }

    .mx-xl-8 {
        margin-left: 4rem !important;
        margin-right: 4rem !important
    }

    .mx-xl-9 {
        margin-left: 5rem !important;
        margin-right: 5rem !important
    }

    .mx-xl-10 {
        margin-left: 6rem !important;
        margin-right: 6rem !important
    }

    .mx-xl-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .my-xl-0 {
        margin-bottom: 0 !important;
        margin-top: 0 !important
    }

    .my-xl-1 {
        margin-bottom: .25rem !important;
        margin-top: .25rem !important
    }

    .my-xl-2 {
        margin-bottom: .5rem !important;
        margin-top: .5rem !important
    }

    .my-xl-3 {
        margin-bottom: 1rem !important;
        margin-top: 1rem !important
    }

    .my-xl-4 {
        margin-bottom: 1.5rem !important;
        margin-top: 1.5rem !important
    }

    .my-xl-5 {
        margin-bottom: 2rem !important;
        margin-top: 2rem !important
    }

    .my-xl-6 {
        margin-bottom: 2.5rem !important;
        margin-top: 2.5rem !important
    }

    .my-xl-7 {
        margin-bottom: 3rem !important;
        margin-top: 3rem !important
    }

    .my-xl-8 {
        margin-bottom: 4rem !important;
        margin-top: 4rem !important
    }

    .my-xl-9 {
        margin-bottom: 5rem !important;
        margin-top: 5rem !important
    }

    .my-xl-10 {
        margin-bottom: 6rem !important;
        margin-top: 6rem !important
    }

    .my-xl-auto {
        margin-bottom: auto !important;
        margin-top: auto !important
    }

    .mt-xl-0 {
        margin-top: 0 !important
    }

    .mt-xl-1 {
        margin-top: .25rem !important
    }

    .mt-xl-2 {
        margin-top: .5rem !important
    }

    .mt-xl-3 {
        margin-top: 1rem !important
    }

    .mt-xl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xl-5 {
        margin-top: 2rem !important
    }

    .mt-xl-6 {
        margin-top: 2.5rem !important
    }

    .mt-xl-7 {
        margin-top: 3rem !important
    }

    .mt-xl-8 {
        margin-top: 4rem !important
    }

    .mt-xl-9 {
        margin-top: 5rem !important
    }

    .mt-xl-10 {
        margin-top: 6rem !important
    }

    .mt-xl-auto {
        margin-top: auto !important
    }

    .me-xl-0 {
        margin-right: 0 !important
    }

    .me-xl-1 {
        margin-right: .25rem !important
    }

    .me-xl-2 {
        margin-right: .5rem !important
    }

    .me-xl-3 {
        margin-right: 1rem !important
    }

    .me-xl-4 {
        margin-right: 1.5rem !important
    }

    .me-xl-5 {
        margin-right: 2rem !important
    }

    .me-xl-6 {
        margin-right: 2.5rem !important
    }

    .me-xl-7 {
        margin-right: 3rem !important
    }

    .me-xl-8 {
        margin-right: 4rem !important
    }

    .me-xl-9 {
        margin-right: 5rem !important
    }

    .me-xl-10 {
        margin-right: 6rem !important
    }

    .me-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-0 {
        margin-bottom: 0 !important
    }

    .mb-xl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xl-5 {
        margin-bottom: 2rem !important
    }

    .mb-xl-6 {
        margin-bottom: 2.5rem !important
    }

    .mb-xl-7 {
        margin-bottom: 3rem !important
    }

    .mb-xl-8 {
        margin-bottom: 4rem !important
    }

    .mb-xl-9 {
        margin-bottom: 5rem !important
    }

    .mb-xl-10 {
        margin-bottom: 6rem !important
    }

    .mb-xl-auto {
        margin-bottom: auto !important
    }

    .ms-xl-0 {
        margin-left: 0 !important
    }

    .ms-xl-1 {
        margin-left: .25rem !important
    }

    .ms-xl-2 {
        margin-left: .5rem !important
    }

    .ms-xl-3 {
        margin-left: 1rem !important
    }

    .ms-xl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xl-5 {
        margin-left: 2rem !important
    }

    .ms-xl-6 {
        margin-left: 2.5rem !important
    }

    .ms-xl-7 {
        margin-left: 3rem !important
    }

    .ms-xl-8 {
        margin-left: 4rem !important
    }

    .ms-xl-9 {
        margin-left: 5rem !important
    }

    .ms-xl-10 {
        margin-left: 6rem !important
    }

    .ms-xl-auto {
        margin-left: auto !important
    }

    .m-xl-n1 {
        margin: -.25rem !important
    }

    .m-xl-n2 {
        margin: -.5rem !important
    }

    .m-xl-n3 {
        margin: -1rem !important
    }

    .m-xl-n4 {
        margin: -1.5rem !important
    }

    .m-xl-n5 {
        margin: -2rem !important
    }

    .m-xl-n6 {
        margin: -2.5rem !important
    }

    .m-xl-n7 {
        margin: -3rem !important
    }

    .m-xl-n8 {
        margin: -4rem !important
    }

    .m-xl-n9 {
        margin: -5rem !important
    }

    .m-xl-n10 {
        margin: -6rem !important
    }

    .mx-xl-n1 {
        margin-left: -.25rem !important;
        margin-right: -.25rem !important
    }

    .mx-xl-n2 {
        margin-left: -.5rem !important;
        margin-right: -.5rem !important
    }

    .mx-xl-n3 {
        margin-left: -1rem !important;
        margin-right: -1rem !important
    }

    .mx-xl-n4 {
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important
    }

    .mx-xl-n5 {
        margin-left: -2rem !important;
        margin-right: -2rem !important
    }

    .mx-xl-n6 {
        margin-left: -2.5rem !important;
        margin-right: -2.5rem !important
    }

    .mx-xl-n7 {
        margin-left: -3rem !important;
        margin-right: -3rem !important
    }

    .mx-xl-n8 {
        margin-left: -4rem !important;
        margin-right: -4rem !important
    }

    .mx-xl-n9 {
        margin-left: -5rem !important;
        margin-right: -5rem !important
    }

    .mx-xl-n10 {
        margin-left: -6rem !important;
        margin-right: -6rem !important
    }

    .my-xl-n1 {
        margin-bottom: -.25rem !important;
        margin-top: -.25rem !important
    }

    .my-xl-n2 {
        margin-bottom: -.5rem !important;
        margin-top: -.5rem !important
    }

    .my-xl-n3 {
        margin-bottom: -1rem !important;
        margin-top: -1rem !important
    }

    .my-xl-n4 {
        margin-bottom: -1.5rem !important;
        margin-top: -1.5rem !important
    }

    .my-xl-n5 {
        margin-bottom: -2rem !important;
        margin-top: -2rem !important
    }

    .my-xl-n6 {
        margin-bottom: -2.5rem !important;
        margin-top: -2.5rem !important
    }

    .my-xl-n7 {
        margin-bottom: -3rem !important;
        margin-top: -3rem !important
    }

    .my-xl-n8 {
        margin-bottom: -4rem !important;
        margin-top: -4rem !important
    }

    .my-xl-n9 {
        margin-bottom: -5rem !important;
        margin-top: -5rem !important
    }

    .my-xl-n10 {
        margin-bottom: -6rem !important;
        margin-top: -6rem !important
    }

    .mt-xl-n1 {
        margin-top: -.25rem !important
    }

    .mt-xl-n2 {
        margin-top: -.5rem !important
    }

    .mt-xl-n3 {
        margin-top: -1rem !important
    }

    .mt-xl-n4 {
        margin-top: -1.5rem !important
    }

    .mt-xl-n5 {
        margin-top: -2rem !important
    }

    .mt-xl-n6 {
        margin-top: -2.5rem !important
    }

    .mt-xl-n7 {
        margin-top: -3rem !important
    }

    .mt-xl-n8 {
        margin-top: -4rem !important
    }

    .mt-xl-n9 {
        margin-top: -5rem !important
    }

    .mt-xl-n10 {
        margin-top: -6rem !important
    }

    .me-xl-n1 {
        margin-right: -.25rem !important
    }

    .me-xl-n2 {
        margin-right: -.5rem !important
    }

    .me-xl-n3 {
        margin-right: -1rem !important
    }

    .me-xl-n4 {
        margin-right: -1.5rem !important
    }

    .me-xl-n5 {
        margin-right: -2rem !important
    }

    .me-xl-n6 {
        margin-right: -2.5rem !important
    }

    .me-xl-n7 {
        margin-right: -3rem !important
    }

    .me-xl-n8 {
        margin-right: -4rem !important
    }

    .me-xl-n9 {
        margin-right: -5rem !important
    }

    .me-xl-n10 {
        margin-right: -6rem !important
    }

    .mb-xl-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-xl-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-xl-n3 {
        margin-bottom: -1rem !important
    }

    .mb-xl-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-xl-n5 {
        margin-bottom: -2rem !important
    }

    .mb-xl-n6 {
        margin-bottom: -2.5rem !important
    }

    .mb-xl-n7 {
        margin-bottom: -3rem !important
    }

    .mb-xl-n8 {
        margin-bottom: -4rem !important
    }

    .mb-xl-n9 {
        margin-bottom: -5rem !important
    }

    .mb-xl-n10 {
        margin-bottom: -6rem !important
    }

    .ms-xl-n1 {
        margin-left: -.25rem !important
    }

    .ms-xl-n2 {
        margin-left: -.5rem !important
    }

    .ms-xl-n3 {
        margin-left: -1rem !important
    }

    .ms-xl-n4 {
        margin-left: -1.5rem !important
    }

    .ms-xl-n5 {
        margin-left: -2rem !important
    }

    .ms-xl-n6 {
        margin-left: -2.5rem !important
    }

    .ms-xl-n7 {
        margin-left: -3rem !important
    }

    .ms-xl-n8 {
        margin-left: -4rem !important
    }

    .ms-xl-n9 {
        margin-left: -5rem !important
    }

    .ms-xl-n10 {
        margin-left: -6rem !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .p-xl-3 {
        padding: 1rem !important
    }

    .p-xl-4 {
        padding: 1.5rem !important
    }

    .p-xl-5 {
        padding: 2rem !important
    }

    .p-xl-6 {
        padding: 2.5rem !important
    }

    .p-xl-7 {
        padding: 3rem !important
    }

    .p-xl-8 {
        padding: 4rem !important
    }

    .p-xl-9 {
        padding: 5rem !important
    }

    .p-xl-10 {
        padding: 6rem !important
    }

    .px-xl-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .px-xl-1 {
        padding-left: .25rem !important;
        padding-right: .25rem !important
    }

    .px-xl-2 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }

    .px-xl-3 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }

    .px-xl-4 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    .px-xl-5 {
        padding-left: 2rem !important;
        padding-right: 2rem !important
    }

    .px-xl-6 {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important
    }

    .px-xl-7 {
        padding-left: 3rem !important;
        padding-right: 3rem !important
    }

    .px-xl-8 {
        padding-left: 4rem !important;
        padding-right: 4rem !important
    }

    .px-xl-9 {
        padding-left: 5rem !important;
        padding-right: 5rem !important
    }

    .px-xl-10 {
        padding-left: 6rem !important;
        padding-right: 6rem !important
    }

    .py-xl-0 {
        padding-bottom: 0 !important;
        padding-top: 0 !important
    }

    .py-xl-1 {
        padding-bottom: .25rem !important;
        padding-top: .25rem !important
    }

    .py-xl-2 {
        padding-bottom: .5rem !important;
        padding-top: .5rem !important
    }

    .py-xl-3 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important
    }

    .py-xl-4 {
        padding-bottom: 1.5rem !important;
        padding-top: 1.5rem !important
    }

    .py-xl-5 {
        padding-bottom: 2rem !important;
        padding-top: 2rem !important
    }

    .py-xl-6 {
        padding-bottom: 2.5rem !important;
        padding-top: 2.5rem !important
    }

    .py-xl-7 {
        padding-bottom: 3rem !important;
        padding-top: 3rem !important
    }

    .py-xl-8 {
        padding-bottom: 4rem !important;
        padding-top: 4rem !important
    }

    .py-xl-9 {
        padding-bottom: 5rem !important;
        padding-top: 5rem !important
    }

    .py-xl-10 {
        padding-bottom: 6rem !important;
        padding-top: 6rem !important
    }

    .pt-xl-0 {
        padding-top: 0 !important
    }

    .pt-xl-1 {
        padding-top: .25rem !important
    }

    .pt-xl-2 {
        padding-top: .5rem !important
    }

    .pt-xl-3 {
        padding-top: 1rem !important
    }

    .pt-xl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xl-5 {
        padding-top: 2rem !important
    }

    .pt-xl-6 {
        padding-top: 2.5rem !important
    }

    .pt-xl-7 {
        padding-top: 3rem !important
    }

    .pt-xl-8 {
        padding-top: 4rem !important
    }

    .pt-xl-9 {
        padding-top: 5rem !important
    }

    .pt-xl-10 {
        padding-top: 6rem !important
    }

    .pe-xl-0 {
        padding-right: 0 !important
    }

    .pe-xl-1 {
        padding-right: .25rem !important
    }

    .pe-xl-2 {
        padding-right: .5rem !important
    }

    .pe-xl-3 {
        padding-right: 1rem !important
    }

    .pe-xl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xl-5 {
        padding-right: 2rem !important
    }

    .pe-xl-6 {
        padding-right: 2.5rem !important
    }

    .pe-xl-7 {
        padding-right: 3rem !important
    }

    .pe-xl-8 {
        padding-right: 4rem !important
    }

    .pe-xl-9 {
        padding-right: 5rem !important
    }

    .pe-xl-10 {
        padding-right: 6rem !important
    }

    .pb-xl-0 {
        padding-bottom: 0 !important
    }

    .pb-xl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xl-5 {
        padding-bottom: 2rem !important
    }

    .pb-xl-6 {
        padding-bottom: 2.5rem !important
    }

    .pb-xl-7 {
        padding-bottom: 3rem !important
    }

    .pb-xl-8 {
        padding-bottom: 4rem !important
    }

    .pb-xl-9 {
        padding-bottom: 5rem !important
    }

    .pb-xl-10 {
        padding-bottom: 6rem !important
    }

    .ps-xl-0 {
        padding-left: 0 !important
    }

    .ps-xl-1 {
        padding-left: .25rem !important
    }

    .ps-xl-2 {
        padding-left: .5rem !important
    }

    .ps-xl-3 {
        padding-left: 1rem !important
    }

    .ps-xl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xl-5 {
        padding-left: 2rem !important
    }

    .ps-xl-6 {
        padding-left: 2.5rem !important
    }

    .ps-xl-7 {
        padding-left: 3rem !important
    }

    .ps-xl-8 {
        padding-left: 4rem !important
    }

    .ps-xl-9 {
        padding-left: 5rem !important
    }

    .ps-xl-10 {
        padding-left: 6rem !important
    }

    .gap-xl-0 {
        gap: 0 !important
    }

    .gap-xl-1 {
        gap: .25rem !important
    }

    .gap-xl-2 {
        gap: .5rem !important
    }

    .gap-xl-3 {
        gap: 1rem !important
    }

    .gap-xl-4 {
        gap: 1.5rem !important
    }

    .gap-xl-5 {
        gap: 2rem !important
    }

    .gap-xl-6 {
        gap: 2.5rem !important
    }

    .gap-xl-7 {
        gap: 3rem !important
    }

    .gap-xl-8 {
        gap: 4rem !important
    }

    .gap-xl-9 {
        gap: 5rem !important
    }

    .gap-xl-10 {
        gap: 6rem !important
    }

    .row-gap-xl-0 {
        row-gap: 0 !important
    }

    .row-gap-xl-1 {
        row-gap: .25rem !important
    }

    .row-gap-xl-2 {
        row-gap: .5rem !important
    }

    .row-gap-xl-3 {
        row-gap: 1rem !important
    }

    .row-gap-xl-4 {
        row-gap: 1.5rem !important
    }

    .row-gap-xl-5 {
        row-gap: 2rem !important
    }

    .row-gap-xl-6 {
        row-gap: 2.5rem !important
    }

    .row-gap-xl-7 {
        row-gap: 3rem !important
    }

    .row-gap-xl-8 {
        row-gap: 4rem !important
    }

    .row-gap-xl-9 {
        row-gap: 5rem !important
    }

    .row-gap-xl-10 {
        row-gap: 6rem !important
    }

    .column-gap-xl-0 {
        -moz-column-gap: 0 !important;
        column-gap: 0 !important
    }

    .column-gap-xl-1 {
        -moz-column-gap: .25rem !important;
        column-gap: .25rem !important
    }

    .column-gap-xl-2 {
        -moz-column-gap: .5rem !important;
        column-gap: .5rem !important
    }

    .column-gap-xl-3 {
        -moz-column-gap: 1rem !important;
        column-gap: 1rem !important
    }

    .column-gap-xl-4 {
        -moz-column-gap: 1.5rem !important;
        column-gap: 1.5rem !important
    }

    .column-gap-xl-5 {
        -moz-column-gap: 2rem !important;
        column-gap: 2rem !important
    }

    .column-gap-xl-6 {
        -moz-column-gap: 2.5rem !important;
        column-gap: 2.5rem !important
    }

    .column-gap-xl-7 {
        -moz-column-gap: 3rem !important;
        column-gap: 3rem !important
    }

    .column-gap-xl-8 {
        -moz-column-gap: 4rem !important;
        column-gap: 4rem !important
    }

    .column-gap-xl-9 {
        -moz-column-gap: 5rem !important;
        column-gap: 5rem !important
    }

    .column-gap-xl-10 {
        -moz-column-gap: 6rem !important;
        column-gap: 6rem !important
    }

    .text-xl-start {
        text-align: left !important
    }

    .text-xl-end {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }

    .rounded-top-xl {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-xl-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-top-xl-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-top-xl-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-xl-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-top-xl-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-top-xl-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-top-xl-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-top-xl-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-end-xl {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-xl-0 {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-end-xl-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-end-xl-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-xl-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-end-xl-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-end-xl-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-end-xl-circle {
        border-bottom-right-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-end-xl-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-bottom-xl {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-xl-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-xl-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-bottom-xl-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-xl-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-bottom-xl-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-bottom-xl-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-bottom-xl-circle {
        border-bottom-left-radius: 50% !important;
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-xl-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-start-xl {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-xl-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important
    }

    .rounded-start-xl-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-start-xl-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-xl-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-start-xl-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-start-xl-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-start-xl-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important
    }

    .rounded-start-xl-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important
    }
}

@media (min-width:1400px) {
    .float-xxl-start {
        float: left !important
    }

    .float-xxl-end {
        float: right !important
    }

    .float-xxl-none {
        float: none !important
    }

    .object-fit-xxl-contain {
        -o-object-fit: contain !important;
        object-fit: contain !important
    }

    .object-fit-xxl-cover {
        -o-object-fit: cover !important;
        object-fit: cover !important
    }

    .object-fit-xxl-fill {
        -o-object-fit: fill !important;
        object-fit: fill !important
    }

    .object-fit-xxl-scale {
        -o-object-fit: scale-down !important;
        object-fit: scale-down !important
    }

    .object-fit-xxl-none {
        -o-object-fit: none !important;
        object-fit: none !important
    }

    .d-xxl-inline {
        display: inline !important
    }

    .d-xxl-inline-block {
        display: inline-block !important
    }

    .d-xxl-block {
        display: block !important
    }

    .d-xxl-grid {
        display: grid !important
    }

    .d-xxl-inline-grid {
        display: inline-grid !important
    }

    .d-xxl-table {
        display: table !important
    }

    .d-xxl-table-row {
        display: table-row !important
    }

    .d-xxl-table-cell {
        display: table-cell !important
    }

    .d-xxl-flex {
        display: flex !important
    }

    .d-xxl-inline-flex {
        display: inline-flex !important
    }

    .d-xxl-none {
        display: none !important
    }

    .position-xxl-static {
        position: static !important
    }

    .position-xxl-relative {
        position: relative !important
    }

    .position-xxl-absolute {
        position: absolute !important
    }

    .position-xxl-fixed {
        position: fixed !important
    }

    .position-xxl-sticky {
        position: sticky !important
    }

    .top-xxl-0 {
        top: 0 !important
    }

    .top-xxl-50 {
        top: 50% !important
    }

    .top-xxl-100 {
        top: 100% !important
    }

    .bottom-xxl-0 {
        bottom: 0 !important
    }

    .bottom-xxl-50 {
        bottom: 50% !important
    }

    .bottom-xxl-100 {
        bottom: 100% !important
    }

    .start-xxl-0 {
        left: 0 !important
    }

    .start-xxl-50 {
        left: 50% !important
    }

    .start-xxl-100 {
        left: 100% !important
    }

    .translate-middle-xxl {
        transform: translate(-50%, -50%) !important
    }

    .translate-middle-xxl-x {
        transform: translateX(-50%) !important
    }

    .translate-middle-xxl-y {
        transform: translateY(-50%) !important
    }

    .border-xxl {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-xxl-0 {
        border: 0 !important
    }

    .border-top-xxl {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-top-xxl-0 {
        border-top: 0 !important
    }

    .border-end-xxl {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-end-xxl-0 {
        border-right: 0 !important
    }

    .border-bottom-xxl {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-bottom-xxl-0 {
        border-bottom: 0 !important
    }

    .border-start-xxl {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .border-start-xxl-0 {
        border-left: 0 !important
    }

    .border-xxl-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important
    }

    .border-xxl-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important
    }

    .w-xxl-25 {
        width: 25% !important
    }

    .w-xxl-50 {
        width: 50% !important
    }

    .w-xxl-75 {
        width: 75% !important
    }

    .w-xxl-100 {
        width: 100% !important
    }

    .w-xxl-auto {
        width: auto !important
    }

    .mw-xxl-100 {
        max-width: 100% !important
    }

    .vw-xxl-100 {
        width: 100vw !important
    }

    .min-vw-xxl-100 {
        min-width: 100vw !important
    }

    .h-xxl-25 {
        height: 25% !important
    }

    .h-xxl-50 {
        height: 50% !important
    }

    .h-xxl-75 {
        height: 75% !important
    }

    .h-xxl-100 {
        height: 100% !important
    }

    .h-xxl-auto {
        height: auto !important
    }

    .mh-xxl-100 {
        max-height: 100% !important
    }

    .min-vh-xxl-100 {
        min-height: 100vh !important
    }

    .flex-xxl-fill {
        flex: 1 1 auto !important
    }

    .flex-xxl-row {
        flex-direction: row !important
    }

    .flex-xxl-column {
        flex-direction: column !important
    }

    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xxl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xxl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xxl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xxl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xxl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-xxl-start {
        justify-content: flex-start !important
    }

    .justify-content-xxl-end {
        justify-content: flex-end !important
    }

    .justify-content-xxl-center {
        justify-content: center !important
    }

    .justify-content-xxl-between {
        justify-content: space-between !important
    }

    .justify-content-xxl-around {
        justify-content: space-around !important
    }

    .justify-content-xxl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xxl-start {
        align-items: flex-start !important
    }

    .align-items-xxl-end {
        align-items: flex-end !important
    }

    .align-items-xxl-center {
        align-items: center !important
    }

    .align-items-xxl-baseline {
        align-items: baseline !important
    }

    .align-items-xxl-stretch {
        align-items: stretch !important
    }

    .align-content-xxl-start {
        align-content: flex-start !important
    }

    .align-content-xxl-end {
        align-content: flex-end !important
    }

    .align-content-xxl-center {
        align-content: center !important
    }

    .align-content-xxl-between {
        align-content: space-between !important
    }

    .align-content-xxl-around {
        align-content: space-around !important
    }

    .align-content-xxl-stretch {
        align-content: stretch !important
    }

    .align-self-xxl-auto {
        align-self: auto !important
    }

    .align-self-xxl-start {
        align-self: flex-start !important
    }

    .align-self-xxl-end {
        align-self: flex-end !important
    }

    .align-self-xxl-center {
        align-self: center !important
    }

    .align-self-xxl-baseline {
        align-self: baseline !important
    }

    .align-self-xxl-stretch {
        align-self: stretch !important
    }

    .order-xxl-first {
        order: -1 !important
    }

    .order-xxl-0 {
        order: 0 !important
    }

    .order-xxl-1 {
        order: 1 !important
    }

    .order-xxl-2 {
        order: 2 !important
    }

    .order-xxl-3 {
        order: 3 !important
    }

    .order-xxl-4 {
        order: 4 !important
    }

    .order-xxl-5 {
        order: 5 !important
    }

    .order-xxl-last {
        order: 6 !important
    }

    .m-xxl-0 {
        margin: 0 !important
    }

    .m-xxl-1 {
        margin: .25rem !important
    }

    .m-xxl-2 {
        margin: .5rem !important
    }

    .m-xxl-3 {
        margin: 1rem !important
    }

    .m-xxl-4 {
        margin: 1.5rem !important
    }

    .m-xxl-5 {
        margin: 2rem !important
    }

    .m-xxl-6 {
        margin: 2.5rem !important
    }

    .m-xxl-7 {
        margin: 3rem !important
    }

    .m-xxl-8 {
        margin: 4rem !important
    }

    .m-xxl-9 {
        margin: 5rem !important
    }

    .m-xxl-10 {
        margin: 6rem !important
    }

    .m-xxl-auto {
        margin: auto !important
    }

    .mx-xxl-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .mx-xxl-1 {
        margin-left: .25rem !important;
        margin-right: .25rem !important
    }

    .mx-xxl-2 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }

    .mx-xxl-3 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }

    .mx-xxl-4 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }

    .mx-xxl-5 {
        margin-left: 2rem !important;
        margin-right: 2rem !important
    }

    .mx-xxl-6 {
        margin-left: 2.5rem !important;
        margin-right: 2.5rem !important
    }

    .mx-xxl-7 {
        margin-left: 3rem !important;
        margin-right: 3rem !important
    }

    .mx-xxl-8 {
        margin-left: 4rem !important;
        margin-right: 4rem !important
    }

    .mx-xxl-9 {
        margin-left: 5rem !important;
        margin-right: 5rem !important
    }

    .mx-xxl-10 {
        margin-left: 6rem !important;
        margin-right: 6rem !important
    }

    .mx-xxl-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .my-xxl-0 {
        margin-bottom: 0 !important;
        margin-top: 0 !important
    }

    .my-xxl-1 {
        margin-bottom: .25rem !important;
        margin-top: .25rem !important
    }

    .my-xxl-2 {
        margin-bottom: .5rem !important;
        margin-top: .5rem !important
    }

    .my-xxl-3 {
        margin-bottom: 1rem !important;
        margin-top: 1rem !important
    }

    .my-xxl-4 {
        margin-bottom: 1.5rem !important;
        margin-top: 1.5rem !important
    }

    .my-xxl-5 {
        margin-bottom: 2rem !important;
        margin-top: 2rem !important
    }

    .my-xxl-6 {
        margin-bottom: 2.5rem !important;
        margin-top: 2.5rem !important
    }

    .my-xxl-7 {
        margin-bottom: 3rem !important;
        margin-top: 3rem !important
    }

    .my-xxl-8 {
        margin-bottom: 4rem !important;
        margin-top: 4rem !important
    }

    .my-xxl-9 {
        margin-bottom: 5rem !important;
        margin-top: 5rem !important
    }

    .my-xxl-10 {
        margin-bottom: 6rem !important;
        margin-top: 6rem !important
    }

    .my-xxl-auto {
        margin-bottom: auto !important;
        margin-top: auto !important
    }

    .mt-xxl-0 {
        margin-top: 0 !important
    }

    .mt-xxl-1 {
        margin-top: .25rem !important
    }

    .mt-xxl-2 {
        margin-top: .5rem !important
    }

    .mt-xxl-3 {
        margin-top: 1rem !important
    }

    .mt-xxl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xxl-5 {
        margin-top: 2rem !important
    }

    .mt-xxl-6 {
        margin-top: 2.5rem !important
    }

    .mt-xxl-7 {
        margin-top: 3rem !important
    }

    .mt-xxl-8 {
        margin-top: 4rem !important
    }

    .mt-xxl-9 {
        margin-top: 5rem !important
    }

    .mt-xxl-10 {
        margin-top: 6rem !important
    }

    .mt-xxl-auto {
        margin-top: auto !important
    }

    .me-xxl-0 {
        margin-right: 0 !important
    }

    .me-xxl-1 {
        margin-right: .25rem !important
    }

    .me-xxl-2 {
        margin-right: .5rem !important
    }

    .me-xxl-3 {
        margin-right: 1rem !important
    }

    .me-xxl-4 {
        margin-right: 1.5rem !important
    }

    .me-xxl-5 {
        margin-right: 2rem !important
    }

    .me-xxl-6 {
        margin-right: 2.5rem !important
    }

    .me-xxl-7 {
        margin-right: 3rem !important
    }

    .me-xxl-8 {
        margin-right: 4rem !important
    }

    .me-xxl-9 {
        margin-right: 5rem !important
    }

    .me-xxl-10 {
        margin-right: 6rem !important
    }

    .me-xxl-auto {
        margin-right: auto !important
    }

    .mb-xxl-0 {
        margin-bottom: 0 !important
    }

    .mb-xxl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xxl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xxl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xxl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xxl-5 {
        margin-bottom: 2rem !important
    }

    .mb-xxl-6 {
        margin-bottom: 2.5rem !important
    }

    .mb-xxl-7 {
        margin-bottom: 3rem !important
    }

    .mb-xxl-8 {
        margin-bottom: 4rem !important
    }

    .mb-xxl-9 {
        margin-bottom: 5rem !important
    }

    .mb-xxl-10 {
        margin-bottom: 6rem !important
    }

    .mb-xxl-auto {
        margin-bottom: auto !important
    }

    .ms-xxl-0 {
        margin-left: 0 !important
    }

    .ms-xxl-1 {
        margin-left: .25rem !important
    }

    .ms-xxl-2 {
        margin-left: .5rem !important
    }

    .ms-xxl-3 {
        margin-left: 1rem !important
    }

    .ms-xxl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xxl-5 {
        margin-left: 2rem !important
    }

    .ms-xxl-6 {
        margin-left: 2.5rem !important
    }

    .ms-xxl-7 {
        margin-left: 3rem !important
    }

    .ms-xxl-8 {
        margin-left: 4rem !important
    }

    .ms-xxl-9 {
        margin-left: 5rem !important
    }

    .ms-xxl-10 {
        margin-left: 6rem !important
    }

    .ms-xxl-auto {
        margin-left: auto !important
    }

    .m-xxl-n1 {
        margin: -.25rem !important
    }

    .m-xxl-n2 {
        margin: -.5rem !important
    }

    .m-xxl-n3 {
        margin: -1rem !important
    }

    .m-xxl-n4 {
        margin: -1.5rem !important
    }

    .m-xxl-n5 {
        margin: -2rem !important
    }

    .m-xxl-n6 {
        margin: -2.5rem !important
    }

    .m-xxl-n7 {
        margin: -3rem !important
    }

    .m-xxl-n8 {
        margin: -4rem !important
    }

    .m-xxl-n9 {
        margin: -5rem !important
    }

    .m-xxl-n10 {
        margin: -6rem !important
    }

    .mx-xxl-n1 {
        margin-left: -.25rem !important;
        margin-right: -.25rem !important
    }

    .mx-xxl-n2 {
        margin-left: -.5rem !important;
        margin-right: -.5rem !important
    }

    .mx-xxl-n3 {
        margin-left: -1rem !important;
        margin-right: -1rem !important
    }

    .mx-xxl-n4 {
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important
    }

    .mx-xxl-n5 {
        margin-left: -2rem !important;
        margin-right: -2rem !important
    }

    .mx-xxl-n6 {
        margin-left: -2.5rem !important;
        margin-right: -2.5rem !important
    }

    .mx-xxl-n7 {
        margin-left: -3rem !important;
        margin-right: -3rem !important
    }

    .mx-xxl-n8 {
        margin-left: -4rem !important;
        margin-right: -4rem !important
    }

    .mx-xxl-n9 {
        margin-left: -5rem !important;
        margin-right: -5rem !important
    }

    .mx-xxl-n10 {
        margin-left: -6rem !important;
        margin-right: -6rem !important
    }

    .my-xxl-n1 {
        margin-bottom: -.25rem !important;
        margin-top: -.25rem !important
    }

    .my-xxl-n2 {
        margin-bottom: -.5rem !important;
        margin-top: -.5rem !important
    }

    .my-xxl-n3 {
        margin-bottom: -1rem !important;
        margin-top: -1rem !important
    }

    .my-xxl-n4 {
        margin-bottom: -1.5rem !important;
        margin-top: -1.5rem !important
    }

    .my-xxl-n5 {
        margin-bottom: -2rem !important;
        margin-top: -2rem !important
    }

    .my-xxl-n6 {
        margin-bottom: -2.5rem !important;
        margin-top: -2.5rem !important
    }

    .my-xxl-n7 {
        margin-bottom: -3rem !important;
        margin-top: -3rem !important
    }

    .my-xxl-n8 {
        margin-bottom: -4rem !important;
        margin-top: -4rem !important
    }

    .my-xxl-n9 {
        margin-bottom: -5rem !important;
        margin-top: -5rem !important
    }

    .my-xxl-n10 {
        margin-bottom: -6rem !important;
        margin-top: -6rem !important
    }

    .mt-xxl-n1 {
        margin-top: -.25rem !important
    }

    .mt-xxl-n2 {
        margin-top: -.5rem !important
    }

    .mt-xxl-n3 {
        margin-top: -1rem !important
    }

    .mt-xxl-n4 {
        margin-top: -1.5rem !important
    }

    .mt-xxl-n5 {
        margin-top: -2rem !important
    }

    .mt-xxl-n6 {
        margin-top: -2.5rem !important
    }

    .mt-xxl-n7 {
        margin-top: -3rem !important
    }

    .mt-xxl-n8 {
        margin-top: -4rem !important
    }

    .mt-xxl-n9 {
        margin-top: -5rem !important
    }

    .mt-xxl-n10 {
        margin-top: -6rem !important
    }

    .me-xxl-n1 {
        margin-right: -.25rem !important
    }

    .me-xxl-n2 {
        margin-right: -.5rem !important
    }

    .me-xxl-n3 {
        margin-right: -1rem !important
    }

    .me-xxl-n4 {
        margin-right: -1.5rem !important
    }

    .me-xxl-n5 {
        margin-right: -2rem !important
    }

    .me-xxl-n6 {
        margin-right: -2.5rem !important
    }

    .me-xxl-n7 {
        margin-right: -3rem !important
    }

    .me-xxl-n8 {
        margin-right: -4rem !important
    }

    .me-xxl-n9 {
        margin-right: -5rem !important
    }

    .me-xxl-n10 {
        margin-right: -6rem !important
    }

    .mb-xxl-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-xxl-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-xxl-n3 {
        margin-bottom: -1rem !important
    }

    .mb-xxl-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-xxl-n5 {
        margin-bottom: -2rem !important
    }

    .mb-xxl-n6 {
        margin-bottom: -2.5rem !important
    }

    .mb-xxl-n7 {
        margin-bottom: -3rem !important
    }

    .mb-xxl-n8 {
        margin-bottom: -4rem !important
    }

    .mb-xxl-n9 {
        margin-bottom: -5rem !important
    }

    .mb-xxl-n10 {
        margin-bottom: -6rem !important
    }

    .ms-xxl-n1 {
        margin-left: -.25rem !important
    }

    .ms-xxl-n2 {
        margin-left: -.5rem !important
    }

    .ms-xxl-n3 {
        margin-left: -1rem !important
    }

    .ms-xxl-n4 {
        margin-left: -1.5rem !important
    }

    .ms-xxl-n5 {
        margin-left: -2rem !important
    }

    .ms-xxl-n6 {
        margin-left: -2.5rem !important
    }

    .ms-xxl-n7 {
        margin-left: -3rem !important
    }

    .ms-xxl-n8 {
        margin-left: -4rem !important
    }

    .ms-xxl-n9 {
        margin-left: -5rem !important
    }

    .ms-xxl-n10 {
        margin-left: -6rem !important
    }

    .p-xxl-0 {
        padding: 0 !important
    }

    .p-xxl-1 {
        padding: .25rem !important
    }

    .p-xxl-2 {
        padding: .5rem !important
    }

    .p-xxl-3 {
        padding: 1rem !important
    }

    .p-xxl-4 {
        padding: 1.5rem !important
    }

    .p-xxl-5 {
        padding: 2rem !important
    }

    .p-xxl-6 {
        padding: 2.5rem !important
    }

    .p-xxl-7 {
        padding: 3rem !important
    }

    .p-xxl-8 {
        padding: 4rem !important
    }

    .p-xxl-9 {
        padding: 5rem !important
    }

    .p-xxl-10 {
        padding: 6rem !important
    }

    .px-xxl-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .px-xxl-1 {
        padding-left: .25rem !important;
        padding-right: .25rem !important
    }

    .px-xxl-2 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }

    .px-xxl-3 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }

    .px-xxl-4 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    .px-xxl-5 {
        padding-left: 2rem !important;
        padding-right: 2rem !important
    }

    .px-xxl-6 {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important
    }

    .px-xxl-7 {
        padding-left: 3rem !important;
        padding-right: 3rem !important
    }

    .px-xxl-8 {
        padding-left: 4rem !important;
        padding-right: 4rem !important
    }

    .px-xxl-9 {
        padding-left: 5rem !important;
        padding-right: 5rem !important
    }

    .px-xxl-10 {
        padding-left: 6rem !important;
        padding-right: 6rem !important
    }

    .py-xxl-0 {
        padding-bottom: 0 !important;
        padding-top: 0 !important
    }

    .py-xxl-1 {
        padding-bottom: .25rem !important;
        padding-top: .25rem !important
    }

    .py-xxl-2 {
        padding-bottom: .5rem !important;
        padding-top: .5rem !important
    }

    .py-xxl-3 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important
    }

    .py-xxl-4 {
        padding-bottom: 1.5rem !important;
        padding-top: 1.5rem !important
    }

    .py-xxl-5 {
        padding-bottom: 2rem !important;
        padding-top: 2rem !important
    }

    .py-xxl-6 {
        padding-bottom: 2.5rem !important;
        padding-top: 2.5rem !important
    }

    .py-xxl-7 {
        padding-bottom: 3rem !important;
        padding-top: 3rem !important
    }

    .py-xxl-8 {
        padding-bottom: 4rem !important;
        padding-top: 4rem !important
    }

    .py-xxl-9 {
        padding-bottom: 5rem !important;
        padding-top: 5rem !important
    }

    .py-xxl-10 {
        padding-bottom: 6rem !important;
        padding-top: 6rem !important
    }

    .pt-xxl-0 {
        padding-top: 0 !important
    }

    .pt-xxl-1 {
        padding-top: .25rem !important
    }

    .pt-xxl-2 {
        padding-top: .5rem !important
    }

    .pt-xxl-3 {
        padding-top: 1rem !important
    }

    .pt-xxl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xxl-5 {
        padding-top: 2rem !important
    }

    .pt-xxl-6 {
        padding-top: 2.5rem !important
    }

    .pt-xxl-7 {
        padding-top: 3rem !important
    }

    .pt-xxl-8 {
        padding-top: 4rem !important
    }

    .pt-xxl-9 {
        padding-top: 5rem !important
    }

    .pt-xxl-10 {
        padding-top: 6rem !important
    }

    .pe-xxl-0 {
        padding-right: 0 !important
    }

    .pe-xxl-1 {
        padding-right: .25rem !important
    }

    .pe-xxl-2 {
        padding-right: .5rem !important
    }

    .pe-xxl-3 {
        padding-right: 1rem !important
    }

    .pe-xxl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xxl-5 {
        padding-right: 2rem !important
    }

    .pe-xxl-6 {
        padding-right: 2.5rem !important
    }

    .pe-xxl-7 {
        padding-right: 3rem !important
    }

    .pe-xxl-8 {
        padding-right: 4rem !important
    }

    .pe-xxl-9 {
        padding-right: 5rem !important
    }

    .pe-xxl-10 {
        padding-right: 6rem !important
    }

    .pb-xxl-0 {
        padding-bottom: 0 !important
    }

    .pb-xxl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xxl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xxl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xxl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xxl-5 {
        padding-bottom: 2rem !important
    }

    .pb-xxl-6 {
        padding-bottom: 2.5rem !important
    }

    .pb-xxl-7 {
        padding-bottom: 3rem !important
    }

    .pb-xxl-8 {
        padding-bottom: 4rem !important
    }

    .pb-xxl-9 {
        padding-bottom: 5rem !important
    }

    .pb-xxl-10 {
        padding-bottom: 6rem !important
    }

    .ps-xxl-0 {
        padding-left: 0 !important
    }

    .ps-xxl-1 {
        padding-left: .25rem !important
    }

    .ps-xxl-2 {
        padding-left: .5rem !important
    }

    .ps-xxl-3 {
        padding-left: 1rem !important
    }

    .ps-xxl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xxl-5 {
        padding-left: 2rem !important
    }

    .ps-xxl-6 {
        padding-left: 2.5rem !important
    }

    .ps-xxl-7 {
        padding-left: 3rem !important
    }

    .ps-xxl-8 {
        padding-left: 4rem !important
    }

    .ps-xxl-9 {
        padding-left: 5rem !important
    }

    .ps-xxl-10 {
        padding-left: 6rem !important
    }

    .gap-xxl-0 {
        gap: 0 !important
    }

    .gap-xxl-1 {
        gap: .25rem !important
    }

    .gap-xxl-2 {
        gap: .5rem !important
    }

    .gap-xxl-3 {
        gap: 1rem !important
    }

    .gap-xxl-4 {
        gap: 1.5rem !important
    }

    .gap-xxl-5 {
        gap: 2rem !important
    }

    .gap-xxl-6 {
        gap: 2.5rem !important
    }

    .gap-xxl-7 {
        gap: 3rem !important
    }

    .gap-xxl-8 {
        gap: 4rem !important
    }

    .gap-xxl-9 {
        gap: 5rem !important
    }

    .gap-xxl-10 {
        gap: 6rem !important
    }

    .row-gap-xxl-0 {
        row-gap: 0 !important
    }

    .row-gap-xxl-1 {
        row-gap: .25rem !important
    }

    .row-gap-xxl-2 {
        row-gap: .5rem !important
    }

    .row-gap-xxl-3 {
        row-gap: 1rem !important
    }

    .row-gap-xxl-4 {
        row-gap: 1.5rem !important
    }

    .row-gap-xxl-5 {
        row-gap: 2rem !important
    }

    .row-gap-xxl-6 {
        row-gap: 2.5rem !important
    }

    .row-gap-xxl-7 {
        row-gap: 3rem !important
    }

    .row-gap-xxl-8 {
        row-gap: 4rem !important
    }

    .row-gap-xxl-9 {
        row-gap: 5rem !important
    }

    .row-gap-xxl-10 {
        row-gap: 6rem !important
    }

    .column-gap-xxl-0 {
        -moz-column-gap: 0 !important;
        column-gap: 0 !important
    }

    .column-gap-xxl-1 {
        -moz-column-gap: .25rem !important;
        column-gap: .25rem !important
    }

    .column-gap-xxl-2 {
        -moz-column-gap: .5rem !important;
        column-gap: .5rem !important
    }

    .column-gap-xxl-3 {
        -moz-column-gap: 1rem !important;
        column-gap: 1rem !important
    }

    .column-gap-xxl-4 {
        -moz-column-gap: 1.5rem !important;
        column-gap: 1.5rem !important
    }

    .column-gap-xxl-5 {
        -moz-column-gap: 2rem !important;
        column-gap: 2rem !important
    }

    .column-gap-xxl-6 {
        -moz-column-gap: 2.5rem !important;
        column-gap: 2.5rem !important
    }

    .column-gap-xxl-7 {
        -moz-column-gap: 3rem !important;
        column-gap: 3rem !important
    }

    .column-gap-xxl-8 {
        -moz-column-gap: 4rem !important;
        column-gap: 4rem !important
    }

    .column-gap-xxl-9 {
        -moz-column-gap: 5rem !important;
        column-gap: 5rem !important
    }

    .column-gap-xxl-10 {
        -moz-column-gap: 6rem !important;
        column-gap: 6rem !important
    }

    .text-xxl-start {
        text-align: left !important
    }

    .text-xxl-end {
        text-align: right !important
    }

    .text-xxl-center {
        text-align: center !important
    }

    .rounded-top-xxl {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-xxl-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-top-xxl-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-top-xxl-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-top-xxl-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-top-xxl-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-top-xxl-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-top-xxl-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-top-xxl-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-end-xxl {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-xxl-0 {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-end-xxl-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-end-xxl-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important
    }

    .rounded-end-xxl-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-end-xxl-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-end-xxl-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-end-xxl-circle {
        border-bottom-right-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-end-xxl-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-bottom-xxl {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-xxl-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-xxl-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-bottom-xxl-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important
    }

    .rounded-bottom-xxl-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-bottom-xxl-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-bottom-xxl-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-bottom-xxl-circle {
        border-bottom-left-radius: 50% !important;
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-xxl-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important
    }

    .rounded-start-xxl {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-xxl-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important
    }

    .rounded-start-xxl-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important
    }

    .rounded-start-xxl-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important
    }

    .rounded-start-xxl-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important
    }

    .rounded-start-xxl-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important
    }

    .rounded-start-xxl-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important
    }

    .rounded-start-xxl-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important
    }

    .rounded-start-xxl-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important
    }
}

@media (min-width:1200px) {
    .fs-1 {
        font-size: 2.5rem !important
    }

    .fs-2 {
        font-size: 2rem !important
    }

    .fs-3 {
        font-size: 1.5rem !important
    }
}

@media print {
    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-grid {
        display: grid !important
    }

    .d-print-inline-grid {
        display: inline-grid !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: flex !important
    }

    .d-print-inline-flex {
        display: inline-flex !important
    }

    .d-print-none {
        display: none !important
    }
}

.docs-nav-sidebar {
    background-color: var(--bs-body-bg);
    border-right: 1px solid var(--bs-gray-200);
    font-weight: 500;
    height: 100%;
    position: fixed;
    top: 0;
    width: 15.625rem;
    z-index: 22
}

.docs-nav-sidebar .docs-nav {
    height: 100% !important;
    overflow-y: auto;
    padding: 70px 0
}

@media (max-width:768px) {
    .docs-nav-sidebar .docs-nav {
        padding: 14px 0
    }
}

.docs-nav-sidebar .navbar {
    padding: 0
}

.docs-nav-sidebar .navbar-nav {
    min-width: 100%
}

@media (max-width:375px) {
    .docs-nav-sidebar .navbar-nav {
        padding-top: 25px
    }
}

.docs-nav-sidebar .navbar-nav .navbar-header {
    font-size: .75rem;
    margin-bottom: 1rem;
    margin-top: 1.5625rem;
    padding: 0 1.25rem
}

.docs-nav-sidebar .navbar-nav .navbar-header .heading {
    color: var(--bs-gray-800);
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: capitalize
}

.docs-nav-sidebar .navbar-nav .navbar-border {
    border-bottom: 1px solid var(--bs-gray-200);
    margin: 1.25rem 0
}

.docs-nav-sidebar .navbar-nav .nav-item .nav-link {
    border-bottom: none;
    border-radius: var(--bs-border-radius);
    color: var(--bs-gray-500) !important;
    font-size: .875rem;
    line-height: 1.25rem;
    margin: .05rem 1.25rem;
    padding: .275rem .675rem
}

.docs-nav-sidebar .navbar-nav .nav-item .nav-link:hover {
    background-color: transparent;
    color: var(--bs-primary) !important
}

.docs-nav-sidebar .navbar-nav .nav-item .nav-link.active {
    background-color: transparent;
    color: #ff7a59 !important
}

.docs-main-wrapper {
    min-height: 100%;
    position: relative
}

.docs-wrapper {
    left: 0;
    margin-left: 15.625rem;
    position: relative
}

.docs-content {
    padding: 8rem 0
}

@media (max-width:768px) {
    .docs-content {
        padding: 4.5rem 0
    }
}

.scrollspy-example {
    height: 12.5rem;
    margin-top: .5rem;
    overflow: auto;
    position: relative
}

.scrollspy-example-2 {
    height: 21.875rem;
    overflow: auto;
    position: relative
}

.color-swatch {
    margin-bottom: 1.875rem
}

.color-swatch-header {
    border-radius: .5rem;
    height: 4.5rem
}

.color-swatch-body {
    line-height: 1;
    margin-top: .875rem
}

.color-swatch-body-label {
    margin-bottom: .25rem
}

.color-swatch-body-value {
    margin-bottom: 0
}

@media only screen and (max-width:480px) {
    .docs-nav-sidebar {
        position: relative;
        right: 0;
        width: 100%
    }

    .docs-nav-sidebar .top-search-bar {
        border-bottom: 1px solid var(--bs-gray-300);
        margin-top: 2.875rem;
        padding: 1.25rem
    }

    .docs-nav-sidebar .navbar-toggler {
        background-color: var(--bs-white);
        color: var(--bs-primary)
    }

    .docs-wrapper {
        margin-left: 0
    }

    .docs-main-wrapper .main-content {
        margin-left: 0;
        width: auto
    }
}

.ratio {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-300);
    color: var(--bs-gray-500);
    display: inline-block;
    width: 10rem
}

.docs-content .nav-line-bottom .nav-item .nav-link {
    padding: .75rem
}

.simple-list-example-scrollspy .active {
    background-color: var(--bs-gray-200)
}

code[class*=language-],
pre[class*=language-] {
    font-size: .875rem
}

.border-box {
    background-color: var(--bs-gray-200);
    display: inline-block;
    height: 5rem;
    margin: .25rem;
    width: 5rem
}

html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
    font-weight: 700;
    letter-spacing: -.03em
}

figure {
    margin: 0
}

iframe {
    width: 100%
}

::selection {
    background: var(--bs-primary-bg-subtle);
    color: #381866
}

::-moz-selection {
    background: var(--bs-primary-bg-subtle);
    color: #381866
}

b,
strong {
    color: var(--bs-gray-800);
    font-weight: bolder
}

.blockquote {
    background-color: var(--bs-gray-100);
    border-radius: 12px;
    color: var(--bs-gray-500);
    margin: 20px auto;
    padding: 24px 24px 24px 56px;
    position: relative
}

.blockquote:before {
    color: var(--bs-gray-500);
    content: "\f6b0";
    display: block;
    font-family: bootstrap-icons;
    font-size: 3em;
    font-size: 32px;
    left: 16px;
    position: absolute;
    top: 4px
}

.avatar {
    display: inline-block;
    height: 3rem;
    position: relative;
    width: 3rem
}

.avatar-xs {
    height: 1.5rem;
    width: 1.5rem
}

.avatar-sm {
    height: 2rem;
    width: 2rem
}

.avatar-md {
    height: 2.5rem;
    width: 2.5rem
}

.avatar-lg {
    height: 3.5rem;
    width: 3.5rem
}

.avatar-xl {
    height: 5rem;
    width: 5rem
}

.avatar-xxl {
    height: 7rem;
    width: 7rem
}
h4.mb-1{
    font-size: 1.5rem;
    font-weight: 500;
}
.column-direction{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.zoom-img {
    border-radius: .75rem;
    overflow: hidden;
    transform: translateZ(0);
    
    height: 250px;
}

.zoom-img img {
    display: block;
    transition: transform .35s ease-in-out;
 object-fit: cover;
}

.zoom-img:hover img {
    transform: scale(1.05)
}

.img-overlay,
.img-overlay .img-color {
    position: relative;
    transition: .5s ease
}

.img-overlay .img-color {
    background-color: var(--bs-gray-600);
    border-radius: .75rem
}

.img-overlay .img-color:hover img {
    mix-blend-mode: luminosity;
    opacity: .4;
    transition: .2s ease
}

.marquee {
    height: 65px;
    line-height: 4.5;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    width: 100vw
}

.marquee .track {
    animation: marquee 100s linear infinite
}

.marquee .track,
.marquee .track-2 {
    white-space: nowrap;
    will-change: transform
}

.marquee .track-2 {
    animation: marquee-left 100s linear infinite
}

@keyframes marquee {
    0% {
        transform: translateX(0)
    }

    to {
        transform: translateX(-50%)
    }
}

@keyframes marquee-left {
    to {
        transform: translateX(0)
    }

    0% {
        transform: translateX(-50%)
    }
}

.lift {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    border-radius: .75rem;
    transform: translateY(0);
    transition: all .3s ease
}

.lift:hover {
    box-shadow: 0 .25rem 1.25rem rgba(52, 63, 82, .1);
    transform: translateY(-.4rem)
}

.card-lift {
    border-radius: .75rem;
    transition: transform .2s ease-in-out, border-color .2s ease-in-out, background-color .2s ease-in-out, box-shadow .2s ease-in-out
}

.card-lift.active,
.card-lift:focus,
.card-lift:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05) !important;
    transform: translateY(-.25rem)
}

.card-primary {
    background-color: var(--bs-gray-100);
    cursor: pointer;
    transition: .5s ease-in-out
}

.card-primary .card-title {
    color: var(--bs-gray-800)
}

.card-primary:hover {
    background-color: var(--bs-primary)
}

.card-primary:hover .card-title {
    color: var(--bs-white)
}

.card-primary:hover .card-text {
    color: var(--bs-gray-100)
}

.card-primary:hover .card-link {
    color: var(--bs-white)
}

.card-hover {
    box-shadow: .2s ease-in-out;
    cursor: pointer;
    display: block;
    transition: .3s ease-in-out
}

.card-hover svg {
    fill: var(--bs-gray-400)
}

.card-hover:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05) !important;
    transform: translateY(-.25rem)
}

.card-hover:hover span {
    border-color: var(--bs-primary) !important
}

.card-hover:hover .card-text {
    color: var(--bs-primary)
}

.card-hover:hover svg {
    fill: var(--bs-primary)
}

.card-grid {
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: contain;
    border-top: 2px solid var(--bs-gray-200);
    cursor: pointer;
    display: flex;
    height: 230px;
    justify-content: space-between;
    position: relative;
    transition: all .3s ease
}

.card-grid .card-grid-inner {
    background: none;
    padding: 24px;
    position: absolute;
    transition: all .3s ease;
    width: 100%;
    z-index: 2
}

@media (min-width:990px) {
    .card-grid .card-grid-heading {
        max-width: 50%
    }
}

.card-grid .card-grid-text {
    opacity: 0;
    transition: all .3s ease;
    visibility: hidden
}

.card-grid .card-grid-image {
    height: auto;
    margin: 24px 0;
    position: absolute;
    right: 0;
    width: 210px
}

@media (min-width:990px) {
    .card-grid:hover {
        border-top-color: var(--bs-primary);
        border-width: 2px;
        z-index: 3
    }

    .card-grid:hover .card-grid-inner {
        background-color: var(--bs-white);
        box-shadow: 0 .5rem 1rem rgba(2, 6, 23, .15)
    }

    .card-grid:hover .card-grid-image {
        opacity: 0;
        visibility: hidden
    }

    .card-grid:hover .card-grid-text {
        opacity: 1;
        visibility: visible
    }
}

@media (max-width:990px) {
    .card-grid {
        height: auto
    }

    .card-grid .card-grid-inner {
        position: relative
    }

    .card-grid .card-grid-image,
    .card-grid .card-grid-text {
        display: none;
        opacity: 0;
        visibility: hidden
    }
}

@media (max-width:576px) {
    .nav-lb-tab {
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden
    }

    .nav-lb-tab::-webkit-scrollbar {
        display: none
    }
}

.nav-lb-tab .nav-item {
    margin: 0 1.5rem
}

.nav-lb-tab .nav-item .nav-link {
    border-radius: 0;
    color: var(--bs-gray-500);
    font-weight: 500;
    margin-bottom: -1px;
    padding: 8px 0;
    white-space: nowrap
}

.nav-lb-tab .nav-item .nav-link.active,
.nav-lb-tab .nav-item .nav-link:hover {
    background-color: transparent;
    border-bottom: 2px solid var(--bs-primary);
    color: var(--bs-primary)
}

.nav-line-bottom {
    border-bottom: 3px solid var(--bs-border-color)
}

@media (max-width:576px) {
    .nav-line-bottom {
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden
    }

    .nav-line-bottom::-webkit-scrollbar {
        display: none
    }
}

.nav-line-bottom .nav-item .nav-link {
    border-radius: 0;
    color: var(--bs-gray-600);
    font-weight: 500;
    margin-bottom: -3px;
    padding: 12px 55px;
    white-space: nowrap
}

@media (max-width:576px) {
    .nav-line-bottom .nav-item .nav-link {
        padding: .8rem
    }
}

.nav-line-bottom .nav-item .nav-link.active {
    background-color: transparent;
    border-bottom: 3px solid var(--bs-primary);
    color: var(--bs-primary)
}

@media (max-width:576px) {
    .nav-line-bottom .nav-item .nav-link.active {
        padding: .747rem
    }
}

.nav-line-bottom .nav-item .nav-link:hover {
    background-color: transparent;
    border-bottom: 3px solid var(--bs-primary);
    color: var(--bs-primary)
}

.nav-line-bottom .nav-item .nav-link .badge {
    line-height: 1.4
}

@media (max-width:990px) {
    .nav-primary {
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden
    }

    .nav-primary::-webkit-scrollbar {
        display: none
    }
}

.nav-primary .nav-item .nav-link {
    align-items: flex-start;
    background-color: var(--bs-light);
    color: var(--bs-gray-500);
    display: flex;
    flex-direction: row;
    font-size: .875rem;
    font-weight: 600;
    padding: 8px 24px;
    white-space: nowrap
}

.nav-primary .nav-item .nav-link.active {
    background-color: var(--bs-primary);
    color: var(--bs-white)
}

.nav-primary .nav-item .nav-link:hover {
    background-color: var(--bs-primary);
    color: var(--bs-light)
}

.nav-account .nav-item {
    margin: .125rem 0
}

.nav-account .nav-item .nav-link {
    background-color: transparent;
    border-radius: .5rem;
    color: var(--bs-gray-500);
    font-weight: 500;
    line-height: 1;
    padding: 12px 16px;
    transition: .2s ease-in-out
}

.nav-account .nav-item .nav-link svg {
    vertical-align: top
}

.nav-account .nav-item .nav-link:hover {
    background-color: var(--bs-gray-200);
    color: var(--bs-gray-800)
}

.nav-account .nav-item .nav-link.active {
    background-color: var(--bs-primary);
    color: #fff
}

.nav-custom-pill {
    background-color: var(--bs-gray-200);
    border-radius: .25rem;
    font-size: .875rem;
    font-weight: 600;
    padding: .35rem
}

.nav-custom-pill .nav-link {
    align-items: center;
    border-radius: .25rem;
    color: var(--bs-gray-500);
    display: flex;
    padding: 4px 12px;
    transition: color .2s ease 0s
}

.nav-custom-pill .nav-link.active {
    background-color: var(--bs-white);
    box-shadow: 0 .0625rem .1875rem rgba(0, 0, 0, .05), 0 .0625rem .125rem rgba(0, 0, 0, .1);
    color: var(--bs-dark);
    transition: transform .2s ease 0s, width .1s ease 0s
}

@media (max-width:990px) {
    .nav-logo {
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        padding-bottom: 26px
    }
}

.nav-logo .nav-item .nav-link {
    opacity: .5
}

@media (max-width:990px) {
    .nav-logo .nav-item .nav-link {
        padding: 0
    }
}

.nav-logo .nav-item .nav-link.active {
    opacity: 1
}

.pattern-square {
    position: relative
}

.pattern-square:after {
    background-image: url(../images/pattern/pattern-square.svg);
    background-position: top;
    bottom: 0;
    content: "";
    height: 312px;
    left: 0;
    -webkit-mask-image: linear-gradient(0deg, transparent 35%, #000 75%);
    mask-image: linear-gradient(0deg, transparent 35%, #000 75%);
    padding: 40px 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1
}

.text-pattern-line {
    position: relative
}
.img-50 img{
    width: 50px;
}
.text-pattern-line:after {
    background-image: url(../images/pattern/curve.svg);
    background-repeat: no-repeat;
    bottom: -22px;
    content: "";
    height: 20px;
    position: absolute;
    right: 0;
    width: 201px
}

.bg-pattern {
    position: relative
}

.bg-pattern:after {
    background-image: url(../images/pattern/pattern-white.svg);
    background-position: top;
    background-repeat: no-repeat;
    bottom: 0;
    content: "";
    left: 0;
    -webkit-mask-image: linear-gradient(0deg, transparent 55%, #000 75%);
    mask-image: linear-gradient(0deg, transparent 55%, #000 75%);
    opacity: .3;
    position: absolute;
    right: 0;
    top: 0
}

.line-pattern {
    position: relative
}

.line-pattern:after {
    background-image: url(../images/pattern/line-pattern.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.right-slant-shape {
    position: relative
}

.right-slant-shape:after {
    background-color: var(--bs-body-bg);
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -1px;
    -webkit-clip-path: polygon(100% 81%, 0 100%, 100% 100%);
    clip-path: polygon(100% 81%, 0 100%, 100% 100%);
    content: "";
    height: 380px;
    position: absolute;
    width: 100%
}

.custom-popover {
    --bs-popover-max-width: 200px;
    --bs-popover-border-color: var(--bs-primary);
    --bs-popover-header-bg: var(--bs-primary);
    --bs-popover-header-color: var(--bs-white);
    --bs-popover-body-padding-x: 1rem;
    --bs-popover-body-padding-y: 0.5rem
}

.custom-tooltip {
    --bs-tooltip-bg: var(--bs-primary)
}

.accordions-icon>a:before {
    color: var(--bs-primary);
    content: "\f2ea";
    font-family: bootstrap-icons;
    font-size: 1.25rem;
    padding-right: 16px
}

.accordions-icon>a.collapsed:before {
    content: "\f4fe"
}

.chevron-arrow {
    display: inline-block;
    transition: .5s ease-in-out
}

[aria-expanded=true] .chevron-arrow {
    transform: rotate(-180deg)
}

.hero-agency {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 768px;
    overflow: hidden
}

@media (max-width:768px) {
    .hero-agency {
        min-height: 500px
    }
}

video {
    height: 100%;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%
}

.btn-icon {
    align-items: center;
    display: inline-flex;
    flex-shrink: 0;
    font-size: .92969rem;
    font-weight: 400;
    height: 2.5rem;
    justify-content: center;
    padding: 0;
    position: relative;
    width: 2.5rem
}

.btn-icon.btn-xs {
    font-size: .75rem;
    height: 1.53125rem;
    width: 1.53125rem
}

.btn-group-sm>.btn-icon.btn,
.btn-icon.btn-sm {
    font-size: .875rem;
    height: 2.1875rem;
    width: 2.1875rem
}

.btn-group-lg>.btn-icon.btn,
.btn-icon.btn-lg {
    font-size: 1rem;
    height: 3.36875rem;
    width: 3.36875rem
}

.btn-shadow {
    transition-duration: .4s
}

.btn-shadow:hover {
    box-shadow: 0 .25rem 1rem rgba(55, 53, 58, .3)
}

@media (max-width:990px) {
    .btn-logo {
        align-items: center;
        border-radius: 50%;
        display: inline-flex;
        height: 56px;
        justify-content: center;
        width: 56px
    }
}

.btn-light {
    --bs-btn-color: #64748b;
    --bs-btn-bg: #f1f5f9;
    --bs-btn-border-color: #f1f5f9;
    --bs-btn-hover-color: #334155;
    --bs-btn-hover-bg: #e2e8f0;
    --bs-btn-hover-border-color: #e2e8f0;
    --bs-btn-active-color: #334155;
    --bs-btn-active-bg: #e2e8f0;
    --bs-btn-active-border-color: #e2e8f0;
    --bs-btn-disabled-color: #334155;
    --bs-btn-disabled-bg: #f1f5f9;
    --bs-btn-disabled-border-color: #f1f5f9
}

.btn-white {
    --bs-btn-color: #334155;
    --bs-btn-bg: #fff;
    --bs-btn-border-color: #fff;
    --bs-btn-hover-color: #ff7a59;
    --bs-btn-hover-bg: #e8d8ff;
    --bs-btn-hover-border-color: #e8d8ff;
    --bs-btn-active-color: #ff7a59;
    --bs-btn-active-bg: #e8d8ff;
    --bs-btn-active-border-color: #e8d8ff;
    --bs-btn-disabled-color: #ff7a59;
    --bs-btn-disabled-bg: #f1f5f9;
    --bs-btn-disabled-border-color: #f1f5f9
}

.btn-secondary {
    --bs-btn-color: #1e293b;
    --bs-btn-bg: #e2e8f0;
    --bs-btn-border-color: #e2e8f0;
    --bs-btn-hover-color: #1e293b;
    --bs-btn-hover-bg: #cbd5e1;
    --bs-btn-hover-border-color: #cbd5e1;
    --bs-btn-active-color: #1e293b;
    --bs-btn-active-bg: #cbd5e1;
    --bs-btn-active-border-color: #cbd5e1;
    --bs-btn-disabled-color: #1e293b;
    --bs-btn-disabled-bg: #f1f5f9;
    --bs-btn-disabled-border-color: #f1f5f9
}

.btn-lift {
    transition: transform .2s ease-in-out, border-color .2s ease-in-out, background-color .2s ease-in-out, box-shadow .2s ease-in-out
}

.btn-lift:hover {
    border-color: var(--bs-primary);
    box-shadow: 0 .125rem .25rem rgba(2, 6, 23, .075);
    transform: translateY(-.25rem)
}

.price-wrapper .price-switcher-wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.price-wrapper .price-switcher-wrapper .price-switchers {
    background-color: var(--bs-gray-200);
    border: .2rem solid transparent;
    border-radius: 1.5rem;
    box-sizing: content-box;
    clear: both;
    height: 1rem;
    position: relative;
    text-align: center;
    width: 2rem
}

.price-wrapper .price-switcher-wrapper .price-switchers .price-switcher {
    cursor: pointer;
    float: left;
    height: 1rem;
    line-height: 1rem;
    position: relative;
    text-transform: uppercase;
    transition: .3s ease-in-out;
    width: 100%;
    z-index: 888
}

.price-wrapper .price-switcher-wrapper .price-switchers .price-switcher:first-child.price-switcher-active~.switcher-button {
    left: 0
}

.price-wrapper .price-switcher-wrapper .price-switchers .price-switcher:nth-child(2).price-switcher-active~.switcher-button {
    left: 50%
}

.price-wrapper .price-switcher-wrapper .price-switchers .switcher-button {
    border: none;
    border-radius: 100%;
    display: block;
    height: 1rem;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0;
    transition: .3s ease-in-out;
    width: 1rem;
    z-index: 555
}

.price-wrapper .price-switcher-wrapper .price-switchers .price-switcher.price-switcher-active {
    color: var(--bs-white)
}

.price-wrapper .price-text {
    height: 2.3rem;
    position: relative
}

.price-wrapper .price-text .price {
    display: flex;
    justify-content: center;
    position: absolute
}

.price-wrapper .price-text .price-hidden {
    display: none
}

.price-wrapper .price-text .price-show {
    animation: priceAnimationShow .6s forwards
}

.price-wrapper .price-text .price-hide {
    animation: priceAnimationFade .6s forwards
}

@keyframes priceAnimationFade {
    0% {
        opacity: 1;
        transform: translateY(0)
    }

    to {
        opacity: 0;
        transform: translateY(80%)
    }
}

@keyframes priceAnimationShow {
    0% {
        opacity: 0;
        transform: translateY(-80%)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

.btn-arrow {
    position: relative
}

.btn-arrow .icon-arrow {
    bottom: 0;
    color: var(--bs-gray-800);
    left: 0;
    margin: 1rem;
    opacity: 0;
    position: absolute;
    transition: opacity .35s ease
}

.btn-arrow:hover .icon-arrow {
    opacity: 1
}

.btn-scroll-top {
    background-color: var(--bs-white);
    border-radius: 10px;
    bottom: 1.5rem;
    box-shadow: inset 0 0 0 .1rem rgba(227, 229, 233, .25);
    opacity: 0;
    position: fixed;
    right: 1.5rem;
    transform: translateY(.75rem);
    transition: all .2s linear, margin-right 0s;
    visibility: hidden;
    z-index: 1010
}

.btn-scroll-top,
.btn-scroll-top:after {
    cursor: pointer;
    display: block;
    height: 3rem;
    width: 3rem
}

.btn-scroll-top:after {
    color: var(--bs-primary);
    content: "\f145";
    font-family: bootstrap-icons !important;
    font-size: 1.5rem;
    left: 0;
    line-height: 3rem;
    position: absolute;
    text-align: center;
    top: 0;
    transition: all .2s linear;
    z-index: 1
}

.btn-scroll-top.active-progress {
    transform: translateY(0);
    visibility: visible
}

@media (min-width:320px) {
    .btn-scroll-top.active-progress {
        opacity: 1
    }
}

.btn-scroll-top svg path {
    fill: none
}

.btn-scroll-top svg.progress-square path {
    stroke: var(--bs-primary);
    stroke-width: 2;
    box-sizing: border-box;
    transition: all .2s linear
}

.play-btn {
    background-color: hsla(0, 0%, 100%, .85);
    font-size: 24px;
    line-height: 32px;
    position: relative
}

.play-btn:before {
    animation-delay: 0s;
    animation: pulsate1 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    border: 5px solid var(--bs-border-color);
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .3);
    content: "";
    height: 150%;
    opacity: 1;
    position: absolute;
    width: 150%
}

@keyframes pulsate1 {
    0% {
        opacity: 1;
        transform: scale(.8)
    }

    to {
        box-shadow: none;
        opacity: 0;
        transform: scale(1)
    }
}

.btn-airbnb {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-airbnb:active,
.btn-airbnb:focus,
.btn-airbnb:hover {
    background-color: #fd5c63 !important;
    border-color: #fd5c63 !important;
    color: #fff !important
}

.btn-behance {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-behance:active,
.btn-behance:focus,
.btn-behance:hover {
    background-color: #1769ff !important;
    border-color: #1769ff !important;
    color: #fff !important
}

.btn-blogger {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-blogger:active,
.btn-blogger:focus,
.btn-blogger:hover {
    background-color: #f57d00 !important;
    border-color: #f57d00 !important;
    color: #fff !important
}

.btn-codepen {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-codepen:active,
.btn-codepen:focus,
.btn-codepen:hover {
    background-color: #0ebeff !important;
    border-color: #0ebeff !important;
    color: #fff !important
}

.btn-discord {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-discord:active,
.btn-discord:focus,
.btn-discord:hover {
    background-color: #7289da !important;
    border-color: #7289da !important;
    color: #fff !important
}

.btn-dribbble {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-dribbble:active,
.btn-dribbble:focus,
.btn-dribbble:hover {
    background-color: #ea4c89 !important;
    border-color: #ea4c89 !important;
    color: #fff !important
}

.btn-dropbox {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-dropbox:active,
.btn-dropbox:focus,
.btn-dropbox:hover {
    background-color: #007ee5 !important;
    border-color: #007ee5 !important;
    color: #fff !important
}

.btn-facebook {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-facebook:active,
.btn-facebook:focus,
.btn-facebook:hover {
    background-color: #1877f2 !important;
    border-color: #1877f2 !important;
    color: #fff !important
}

.btn-flickr {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-flickr:active,
.btn-flickr:focus,
.btn-flickr:hover {
    background-color: #0063dc !important;
    border-color: #0063dc !important;
    color: #fff !important
}

.btn-foursquare {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-foursquare:active,
.btn-foursquare:focus,
.btn-foursquare:hover {
    background-color: #f94877 !important;
    border-color: #f94877 !important;
    color: #fff !important
}

.btn-gitlab {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-gitlab:active,
.btn-gitlab:focus,
.btn-gitlab:hover {
    background-color: #fc6d26 !important;
    border-color: #fc6d26 !important;
    color: #fff !important
}

.btn-github {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-github:active,
.btn-github:focus,
.btn-github:hover {
    background-color: #4078c0 !important;
    border-color: #4078c0 !important;
    color: #fff !important
}

.btn-google {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-google:active,
.btn-google:focus,
.btn-google:hover {
    background-color: #ea4335 !important;
    border-color: #ea4335 !important;
    color: #fff !important
}

.btn-instagram {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-instagram:active,
.btn-instagram:focus,
.btn-instagram:hover {
    background-color: #405de6 !important;
    border-color: #405de6 !important;
    color: #fff !important
}

.btn-kickstarter {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-kickstarter:active,
.btn-kickstarter:focus,
.btn-kickstarter:hover {
    background-color: #2bde73 !important;
    border-color: #2bde73 !important;
    color: #fff !important
}

.btn-linkedin {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-linkedin:active,
.btn-linkedin:focus,
.btn-linkedin:hover {
    background-color: #0077b5 !important;
    border-color: #0077b5 !important;
    color: #fff !important
}

.btn-medium {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-medium:active,
.btn-medium:focus,
.btn-medium:hover {
    background-color: #00ab6c !important;
    border-color: #00ab6c !important;
    color: #fff !important
}

.btn-messenger {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-messenger:active,
.btn-messenger:focus,
.btn-messenger:hover {
    background-color: #0084ff !important;
    border-color: #0084ff !important;
    color: #fff !important
}

.btn-ok {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-ok:active,
.btn-ok:focus,
.btn-ok:hover {
    background-color: #ed812b !important;
    border-color: #ed812b !important;
    color: #fff !important
}

.btn-patreon {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-patreon:active,
.btn-patreon:focus,
.btn-patreon:hover {
    background-color: #f96854 !important;
    border-color: #f96854 !important;
    color: #fff !important
}

.btn-pinterest {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-pinterest:active,
.btn-pinterest:focus,
.btn-pinterest:hover {
    background-color: #e60023 !important;
    border-color: #e60023 !important;
    color: #fff !important
}

.btn-product-hunt {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-product-hunt:active,
.btn-product-hunt:focus,
.btn-product-hunt:hover {
    background-color: #da552f !important;
    border-color: #da552f !important;
    color: #fff !important
}

.btn-reddit {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-reddit:active,
.btn-reddit:focus,
.btn-reddit:hover {
    background-color: #ff4500 !important;
    border-color: #ff4500 !important;
    color: #fff !important
}

.btn-skype {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-skype:active,
.btn-skype:focus,
.btn-skype:hover {
    background-color: #00aff0 !important;
    border-color: #00aff0 !important;
    color: #fff !important
}

.btn-slack {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-slack:active,
.btn-slack:focus,
.btn-slack:hover {
    background-color: #611f69 !important;
    border-color: #611f69 !important;
    color: #fff !important
}

.btn-snapchat {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-snapchat:active,
.btn-snapchat:focus,
.btn-snapchat:hover {
    background-color: #fffc00 !important;
    border-color: #fffc00 !important;
    color: #fff !important
}

.btn-stack-overflow {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-stack-overflow:active,
.btn-stack-overflow:focus,
.btn-stack-overflow:hover {
    background-color: #f48024 !important;
    border-color: #f48024 !important;
    color: #fff !important
}

.btn-steam {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-steam:active,
.btn-steam:focus,
.btn-steam:hover {
    background-color: #00adee !important;
    border-color: #00adee !important;
    color: #fff !important
}

.btn-telegram {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-telegram:active,
.btn-telegram:focus,
.btn-telegram:hover {
    background-color: #08c !important;
    border-color: #08c !important;
    color: #fff !important
}

.btn-tiktok {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-tiktok:active,
.btn-tiktok:focus,
.btn-tiktok:hover {
    background-color: #fe2c55 !important;
    border-color: #fe2c55 !important;
    color: #fff !important
}

.btn-trip-advisor {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-trip-advisor:active,
.btn-trip-advisor:focus,
.btn-trip-advisor:hover {
    background-color: #00af87 !important;
    border-color: #00af87 !important;
    color: #fff !important
}

.btn-tumblr {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-tumblr:active,
.btn-tumblr:focus,
.btn-tumblr:hover {
    background-color: #35465c !important;
    border-color: #35465c !important;
    color: #fff !important
}

.btn-twitch {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-twitch:active,
.btn-twitch:focus,
.btn-twitch:hover {
    background-color: #9146ff !important;
    border-color: #9146ff !important;
    color: #fff !important
}

.btn-twitter {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-twitter:active,
.btn-twitter:focus,
.btn-twitter:hover {
    background-color: #1da1f2 !important;
    border-color: #1da1f2 !important;
    color: #fff !important
}

.btn-vimeo {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-vimeo:active,
.btn-vimeo:focus,
.btn-vimeo:hover {
    background-color: #1ab7ea !important;
    border-color: #1ab7ea !important;
    color: #fff !important
}

.btn-vk {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-vk:active,
.btn-vk:focus,
.btn-vk:hover {
    background-color: #45668e !important;
    border-color: #45668e !important;
    color: #fff !important
}

.btn-whatsapp {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-whatsapp:active,
.btn-whatsapp:focus,
.btn-whatsapp:hover {
    background-color: #128c7e !important;
    border-color: #128c7e !important;
    color: #fff !important
}

.btn-youtube {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-youtube:active,
.btn-youtube:focus,
.btn-youtube:hover {
    background-color: red !important;
    border-color: red !important;
    color: #fff !important
}

.btn-zoom {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    color: var(--bs-gray-500)
}

.btn-zoom:active,
.btn-zoom:focus,
.btn-zoom:hover {
    background-color: #2d8cff !important;
    border-color: #2d8cff !important;
    color: #fff !important
}

button:focus {
    outline: none
}

.table-lg>:not(caption)>*>* {
    padding: 1rem
}

.table-centered td,
.table-centered th {
    vertical-align: middle !important
}

.table-responsive-lg::-webkit-scrollbar,
.table-responsive-md::-webkit-scrollbar,
.table-responsive-xl::-webkit-scrollbar {
    height: 3px
}

.table-responsive-lg::-webkit-scrollbar-track,
.table-responsive-md::-webkit-scrollbar-track,
.table-responsive-xl::-webkit-scrollbar-track {
    background: var(--bs-gray-200);
    border-radius: .75rem
}

.table-responsive-lg::-webkit-scrollbar-thumb,
.table-responsive-md::-webkit-scrollbar-thumb,
.table-responsive-xl::-webkit-scrollbar-thumb {
    background: #ff7a59;
    border-radius: .75rem
}

.table-responsive-lg::-webkit-scrollbar-thumb:hover,
.table-responsive-md::-webkit-scrollbar-thumb:hover,
.table-responsive-xl::-webkit-scrollbar-thumb:hover {
    background: var(--bs-gray-500)
}

.dropdown-menu {
    border: none;
    border-radius: .5rem;
    box-shadow: var(--bs-box-shadow);
    line-height: 1.5rem;
    padding: 1rem 0;
    z-index: 1021
}

.dropdown-header {
    color: var(--bs-gray-800);
    font-size: .75rem;
    font-weight: 700;
    letter-spacing: 1px;
    padding: .25rem 1.5rem;
    text-transform: uppercase
}

.dropdown-text {
    color: var(--bs-gray-500);
    font-size: .75rem;
    line-height: 1.4;
    padding: .25rem 1.2rem
}

.dropdown-item {
    align-items: center;
    display: flex;
    font-weight: 500;
    padding: .25rem 1.5rem
}

.dropdown-menu-xs {
    border-radius: .5rem;
    min-width: 8rem !important
}

.dropdown-menu-md {
    border-radius: .5rem;
    min-width: 17rem !important
}

.dropdown-menu-lg {
    border-radius: .5rem;
    min-width: 22rem !important
}

.dropdown-menu-xl {
    border-radius: .5rem;
    min-width: 24rem !important
}

.dropdown-menu-xxl {
    border-radius: .5rem;
    min-width: 40rem !important
}

.dropdown-mega-menu {
    border-radius: .5rem;
    min-width: 1140px !important
}


.dropdown-toggle:after,




.dropstart .dropdown-toggle:before {
    /* border: 0;
    content: "\e92f";
    display: inline-block;
    font-family: Feather !important;
    margin-right: .255em */
}

.navbar {
    background-color: var(--bs-body-bg);
    box-shadow: 0 .125rem .25rem rgba(2, 6, 23, .075);
    padding: .5rem
}

@media (max-width:990px) {
    .navbar {
        padding: .75rem .5rem
    }
}

.navbar .navbar-brand {
    padding-bottom: .2rem;
    padding-top: .2rem
}

.navbar .navbar-toggler {
    border: 2px solid var(--bs-dark);
    color: var(--bs-dark);
    font-size: 1.5rem;
    padding: .25rem .5rem
}

.navbar .navbar-toggler:focus {
    box-shadow: none;
    outline: 0
}

.navbar .dropdown .dropdown-toggle:after:hover {
    transform: rotate(180deg)
}

.navbar .dropdown-menu {
    border: none;
    border-radius: .5rem;
    box-shadow: var(--bs-box-shadow);
    color: var(--bs-gray-800);
    margin-top: 0;
    min-width: 12rem;
    top: 100%
}

@media (max-width:992px) {
    .navbar .dropdown-menu {
        background-color: transparent;
        box-shadow: none;
        min-width: 0 !important
    }
}

.navbar .dropdown-menu .dropdown-submenu a:after {
    position: absolute;
    right: 1rem;
    transform: rotate(-90deg)
}

.navbar .dropdown-menu .dropdown-submenu.dropend .dropdown-menu {
    border-radius: .5rem;
    box-shadow: var(--bs-box-shadow);
    left: 100%;
    min-width: 12rem;
    right: auto;
    top: -18px
}

@media (max-width:992px) {
    .navbar .dropdown-menu .dropdown-submenu.dropend .dropdown-menu {
        box-shadow: none
    }
}

.navbar .dropdown-fullwidth {
    position: static
}

.navbar .dropdown-fullwidth .dropdown-menu {
    left: 0 !important;
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
    right: 0 !important;
    top: 100%;
    transform: none !important;
    width: 100%
}

@media (min-width:990px) {
    .navbar .navbar-nav .dropdown>.dropdown-menu {
        left: 50%;
        transform: translate(-50%, 10px)
    }
}

.navbar .navbar-nav .dropend>.dropdown-menu {
    transform: translateY(10px)
}

.navbar .navbar-nav .nav-item .nav-link {
    border-radius: .25rem;
    color: var(--bs-gray-500);
    font-size: .9375rem;
    font-weight: 500;
    padding: .5rem 1rem
}

@media (max-width:992px) {
    .navbar .navbar-nav .nav-item .nav-link {
        border-bottom: 1px solid var(--bs-gray-200);
        border-radius: 0;
        padding: .75rem 0 !important
    }

    .navbar .navbar-nav .nav-item .nav-link:hover {
        background-color: transparent !important;
        color: var(--bs-dark)
    }
}

.navbar .navbar-nav .nav-item .nav-link:focus-visible {
    box-shadow: none
}

.navbar .navbar-nav .nav-item .nav-link.active,
.navbar .navbar-nav .nav-item .nav-link:hover {
    color: var(--bs-primary)
}

@media (min-width:1200px) {
    .navbar .dropdown-menu.show {
        display: none
    }

    .navbar .dropdown-menu {
        display: block;
        opacity: 0;
        transform: translate(-50%);
        transition: all .3s ease-in-out;
        visibility: hidden
    }

    .navbar .dropdown:hover>.dropdown-menu {
        opacity: 1;
        visibility: visible
    }

    .navbar .dropdown-submenu:hover>.dropdown-menu {
        opacity: 1;
        transform: translateY(10px);
        visibility: visible
    }
}

.navbar-dark {
    transition: all .3s ease-in-out
}

.navbar-dark .navbar-brand {
    filter: brightness(0) invert(1)
}

.navbar-dark .navbar-nav .nav-link {
    color: var(--bs-gray-300) !important
}

@media (max-width:990px) {
    .navbar-dark .navbar-nav .nav-link {
        color: var(--bs-gray-500) !important
    }
}



@media (max-width:990px) {

}

.navbar-light {
    border-bottom: 1px solid var(--bs-gray-100)
}

.navbar-light .navbar-nav .nav-item .nav-link {
    color: var(--bs-gray-500);
    font-weight: 500
}

.navbar-light .navbar-nav .nav-item .nav-link.active,
.navbar-light .navbar-nav .nav-item .nav-link:hover {
    background-color: var(--bs-gray-100);
    color: var(--bs-gray-800)
}

.navbar-transparent {
    background-color: transparent;
    box-shadow: none;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1009
}

.navbar-transparent .navbar-toggler {
    border-color: #fff;
    color: #fff
}

@media (max-width:990px) {
    .navbar-transparent.navbar-clone .navbar-brand {
        filter: brightness(0) invert(1)
    }

    .navbar-transparent.navbar-clone .navbar-toggler {
        border-color: #0f172a;
        color: #0f172a
    }

    .navbar-transparent.navbar-stick .navbar-brand {
        filter: brightness(1) invert(0)
    }
}

.navbar-clone {
    box-shadow: none;
    left: 0;
    position: fixed !important;
    top: 0;
    transform: translateY(-100%);
    transition: all .3s ease-in-out;
    z-index: 1008
}

.navbar-stick {
    border-bottom: 1px solid var(--bs-gray-100);
    transform: translateY(0)
}

.navbar-stick:not(.navbar-dark) {
    background: var(--bs-body-bg);
    box-shadow: 0 .125rem .25rem rgba(2, 6, 23, .075)
}

.navbar-stick:not(.navbar-dark).navbar-brand {
    filter: brightness(0) invert(1)
}

.offcanvas.show:not(.hiding),
.offcanvas.showing {
    transform: none
}

.offcanvas {
    height: 100vh
}

.inputpass-code {
    color: var(--bs-gray-800);
    font-size: 32px;
    height: 64px;
    margin: 0 auto;
    text-align: center;
    width: 64px
}

.form-control.is-invalid+span .passwordToggler,
.was-validated .form-control:invalid+span .passwordToggler {
    right: 37px !important;
    top: 33% !important
}

.form-control.is-valid+span .passwordToggler,
.was-validated .form-control:valid+span .passwordToggler {
    right: 40px
}

.passwordToggler {
    color: var(--bs-gray-800);
    cursor: pointer;
    font-size: 16px;
    line-height: 1;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%)
}

.was-validated .form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: inherit
}

.is-valid:not(:required):not(.btn),
.was-validated :valid:not(:required):not(.btn) {
    background-image: none;
    border-color: var(--bs-gray-300) !important
}

.gallery .gallery-item {
    padding: 15px;
    width: 50%
}

@media (max-width:991px) {
    .gallery .gallery-item {
        width: 50%
    }
}

@media (max-width:767px) {
    .gallery .gallery-item {
        width: 100%
    }

    .gallery .gallery-item .gallery-filter .filter-item {
        margin-bottom: 10px
    }
}

.gallery .gallery-item.show {
    animation: fadeIn .5s ease;
    transition: all .5s ease
}

.gallery .gallery-item.hide {
    display: none
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.filter-badge {
    background-color: var(--bs-white);
    border: 1px solid var(--bs-border-color);
    border-radius: 50px;
    color: var(--bs-gray-800);
    display: inline-block;
    font-size: .875rem;
    padding: 4px 16px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

.filter-badge.active,
.filter-badge:hover {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
    color: #fff
}

pre[class*=language-] {
    border-radius: .5rem !important;
    margin-bottom: 0;
    margin-top: 0;
    overflow: auto;
    padding: 1em
}

:not(pre)>code[class*=language-],
pre[class*=language-] {
    background: var(--bs-gray-900)
}

div.code-toolbar {
    position: relative
}

div.code-toolbar>.toolbar {
    opacity: 0;
    position: absolute;
    right: .2em;
    top: .3em;
    transition: opacity .3s ease-in-out
}

div.code-toolbar:focus-within>.toolbar,
div.code-toolbar:hover>.toolbar {
    opacity: 1
}

div.code-toolbar>.toolbar>.toolbar-item {
    display: inline-block
}

div.code-toolbar>.toolbar>.toolbar-item>a {
    cursor: pointer
}

div.code-toolbar>.toolbar>.toolbar-item>button {
    background: none;
    border: 0;
    color: var(--bs-dark);
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0
}

div.code-toolbar>.toolbar>.toolbar-item>a,
div.code-toolbar>.toolbar>.toolbar-item>button,
div.code-toolbar>.toolbar>.toolbar-item>span {
    background: var(--bs-white);
    border-radius: .25rem;
    color: var(--bs-dark);
    font-size: .75rem;
    font-weight: 600;
    padding: .25rem .5rem
}

div.code-toolbar>.toolbar>.toolbar-item>a:focus,
div.code-toolbar>.toolbar>.toolbar-item>a:hover,
div.code-toolbar>.toolbar>.toolbar-item>button:focus,
div.code-toolbar>.toolbar>.toolbar-item>button:hover {
    color: var(--bs-dark);
    text-decoration: none
}

div.code-toolbar>.toolbar>.toolbar-item>span:focus,
div.code-toolbar>.toolbar>.toolbar-item>span:hover {
    color: inherit;
    text-decoration: none
}

:root {
    --swiper-theme-color: #ff7a59
}

.swiper-pagination-bullet {
    background: transparent;
    border: 2px solid #ff7a59;
    border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
    display: inline-block;
    height: 10px;
    opacity: .3;
    width: 10px
}

.swiper-pagination-bullet-active {
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    border-color: #ff7a59;
    opacity: var(--swiper-pagination-bullet-opacity, 1)
}

@keyframes plyr-progress {
    to {
        background-position: var(--plyr-progress-loading-size, 25px) 0
    }
}

@keyframes plyr-popup {
    0% {
        opacity: .5;
        transform: translateY(10px)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes plyr-fade-in {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.plyr {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    align-items: center;
    direction: ltr;
    display: flex;
    flex-direction: column;
    font-family: var(--plyr-font-family, inherit);
    font-variant-numeric: tabular-nums;
    font-weight: var(--plyr-font-weight-regular, 400);
    line-height: var(--plyr-line-height, 1.7);
    max-width: 100%;
    min-width: 200px;
    position: relative;
    text-shadow: none;
    transition: box-shadow .3s ease;
    z-index: 0
}

.plyr audio,
.plyr iframe,
.plyr video {
    display: block;
    height: 100%;
    width: 100%
}

.plyr button {
    font: inherit;
    line-height: inherit;
    width: auto
}

.plyr:focus {
    outline: 0
}

.plyr--full-ui {
    box-sizing: border-box
}

.plyr--full-ui *,
.plyr--full-ui :after,
.plyr--full-ui :before {
    box-sizing: inherit
}

.plyr--full-ui a,
.plyr--full-ui button,
.plyr--full-ui input,
.plyr--full-ui label {
    touch-action: manipulation
}

.plyr__badge {
    background: var(--plyr-badge-background, #334155);
    border-radius: var(--plyr-badge-border-radius, 2px);
    color: var(--plyr-badge-text-color, #fff);
    font-size: var(--plyr-font-size-badge, 9px);
    line-height: 1;
    padding: 3px 4px
}

.plyr--full-ui ::-webkit-media-text-track-container {
    display: none
}

.plyr__captions {
    animation: plyr-fade-in .3s ease;
    bottom: 0;
    display: none;
    font-size: var(--plyr-font-size-small, 13px);
    left: 0;
    padding: var(--plyr-control-spacing, 10px);
    position: absolute;
    text-align: center;
    transition: transform .4s ease-in-out;
    width: 100%
}

.plyr__captions span:empty {
    display: none
}

@media (min-width:480px) {
    .plyr__captions {
        font-size: var(--plyr-font-size-base, 15px);
        padding: calc(var(--plyr-control-spacing, 10px)*2)
    }
}

@media (min-width:768px) {
    .plyr__captions {
        font-size: var(--plyr-font-size-large, 18px)
    }
}

.plyr--captions-active .plyr__captions {
    display: block
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty)~.plyr__captions {
    transform: translateY(calc(var(--plyr-control-spacing, 10px)*-4))
}

.plyr__caption {
    background: var(--plyr-captions-background, rgba(0, 0, 0, .8));
    border-radius: 2px;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    color: var(--plyr-captions-text-color, #fff);
    line-height: 185%;
    padding: .2em .5em;
    white-space: pre-wrap
}

.plyr__caption div {
    display: inline
}

.plyr__control {
    background: transparent;
    border: 0;
    border-radius: var(--plyr-control-radius, 4px);
    color: inherit;
    cursor: pointer;
    flex-shrink: 0;
    overflow: visible;
    padding: calc(var(--plyr-control-spacing, 10px)*.7);
    position: relative;
    transition: all .3s ease
}

.plyr__control svg {
    fill: currentColor;
    display: block;
    height: var(--plyr-control-icon-size, 18px);
    pointer-events: none;
    width: var(--plyr-control-icon-size, 18px)
}

.plyr__control:focus {
    outline: 0
}

.plyr__control:focus-visible {
    outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, #ff7a59));
    outline-offset: 2px
}

a.plyr__control {
    text-decoration: none
}

.plyr__control.plyr__control--pressed .icon--not-pressed,
.plyr__control.plyr__control--pressed .label--not-pressed,
.plyr__control:not(.plyr__control--pressed) .icon--pressed,
.plyr__control:not(.plyr__control--pressed) .label--pressed,
a.plyr__control:after,
a.plyr__control:before {
    display: none
}

.plyr--full-ui ::-webkit-media-controls {
    display: none
}

.plyr__controls {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    text-align: center
}

.plyr__controls .plyr__progress__container {
    flex: 1;
    min-width: 0
}

.plyr__controls .plyr__controls__item {
    margin-left: calc(var(--plyr-control-spacing, 10px)/4)
}

.plyr__controls .plyr__controls__item:first-child {
    margin-left: 0;
    margin-right: auto
}

.plyr__controls .plyr__controls__item.plyr__progress__container {
    padding-left: calc(var(--plyr-control-spacing, 10px)/4)
}

.plyr__controls .plyr__controls__item.plyr__time {
    padding: 0 calc(var(--plyr-control-spacing, 10px)/2)
}

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child,
.plyr__controls .plyr__controls__item.plyr__time+.plyr__time,
.plyr__controls .plyr__controls__item.plyr__time:first-child {
    padding-left: 0
}

.plyr [data-plyr=airplay],
.plyr [data-plyr=captions],
.plyr [data-plyr=fullscreen],
.plyr [data-plyr=pip],
.plyr__controls:empty {
    display: none
}

.plyr--airplay-supported [data-plyr=airplay],
.plyr--captions-enabled [data-plyr=captions],
.plyr--fullscreen-enabled [data-plyr=fullscreen],
.plyr--pip-supported [data-plyr=pip] {
    display: inline-block
}

.plyr__menu {
    display: flex;
    position: relative
}

.plyr__menu .plyr__control svg {
    transition: transform .3s ease
}

.plyr__menu .plyr__control[aria-expanded=true] svg {
    transform: rotate(90deg)
}

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
    display: none
}

.plyr__menu__container {
    animation: plyr-popup .2s ease;
    background: var(--plyr-menu-background, hsla(0, 0%, 100%, .9));
    border-radius: var(--plyr-menu-radius, 8px);
    bottom: 100%;
    box-shadow: var(--plyr-menu-shadow, 0 1px 2px rgba(0, 0, 0, .15));
    color: var(--plyr-menu-color, #334155);
    font-size: var(--plyr-font-size-base, 15px);
    margin-bottom: 10px;
    position: absolute;
    right: -3px;
    text-align: left;
    white-space: nowrap;
    z-index: 3
}

.plyr__menu__container>div {
    overflow: hidden;
    transition: height .35s cubic-bezier(.4, 0, .2, 1), width .35s cubic-bezier(.4, 0, .2, 1)
}

.plyr__menu__container:after {
    border-top-color: transparent;
    border: var(--plyr-menu-arrow-size, 4px) solid transparent;
    border-top-color: var(--plyr-menu-background, hsla(0, 0%, 100%, .9));
    content: "";
    height: 0;
    position: absolute;
    right: calc(var(--plyr-control-icon-size, 18px)/2 + var(--plyr-control-spacing, 10px)*.7 - var(--plyr-menu-arrow-size, 4px)/2);
    top: 100%;
    width: 0
}

.plyr__menu__container [role=menu] {
    padding: calc(var(--plyr-control-spacing, 10px)*.7)
}

.plyr__menu__container [role=menuitem],
.plyr__menu__container [role=menuitemradio] {
    margin-top: 2px
}

.plyr__menu__container [role=menuitem]:first-child,
.plyr__menu__container [role=menuitemradio]:first-child {
    margin-top: 0
}

.plyr__menu__container .plyr__control {
    align-items: center;
    color: var(--plyr-menu-color, #334155);
    display: flex;
    font-size: var(--plyr-font-size-menu, var(--plyr-font-size-small, 13px));
    padding: calc(var(--plyr-control-spacing, 10px)*.7/1.5) calc(var(--plyr-control-spacing, 10px)*.7*1.5);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 100%
}

.plyr__menu__container .plyr__control>span {
    align-items: inherit;
    display: flex;
    width: 100%
}

.plyr__menu__container .plyr__control:after {
    border: var(--plyr-menu-item-arrow-size, 4px) solid transparent;
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}

.plyr__menu__container .plyr__control--forward {
    padding-right: calc(var(--plyr-control-spacing, 10px)*.7*4)
}

.plyr__menu__container .plyr__control--forward:after {
    border-left-color: var(--plyr-menu-arrow-color, #64748b);
    right: calc(var(--plyr-control-spacing, 10px)*.7*1.5 - var(--plyr-menu-item-arrow-size, 4px))
}

.plyr__menu__container .plyr__control--forward:focus-visible:after,
.plyr__menu__container .plyr__control--forward:hover:after {
    border-left-color: currentColor
}

.plyr__menu__container .plyr__control--back {
    font-weight: var(--plyr-font-weight-regular, 400);
    margin: calc(var(--plyr-control-spacing, 10px)*.7);
    margin-bottom: calc(var(--plyr-control-spacing, 10px)*.7/2);
    padding-left: calc(var(--plyr-control-spacing, 10px)*.7*4);
    position: relative;
    width: calc(100% - var(--plyr-control-spacing, 10px)*.7*2)
}

.plyr__menu__container .plyr__control--back:after {
    border-right-color: var(--plyr-menu-arrow-color, #64748b);
    left: calc(var(--plyr-control-spacing, 10px)*.7*1.5 - var(--plyr-menu-item-arrow-size, 4px))
}

.plyr__menu__container .plyr__control--back:before {
    background: var(--plyr-menu-back-border-color, #f1f5f9);
    box-shadow: 0 1px 0 var(--plyr-menu-back-border-shadow-color, #fff);
    content: "";
    height: 1px;
    left: 0;
    margin-top: calc(var(--plyr-control-spacing, 10px)*.7/2);
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 100%
}

.plyr__menu__container .plyr__control--back:focus-visible:after,
.plyr__menu__container .plyr__control--back:hover:after {
    border-right-color: currentColor
}

.plyr__menu__container .plyr__control[role=menuitemradio] {
    padding-left: calc(var(--plyr-control-spacing, 10px)*.7)
}

.plyr__menu__container .plyr__control[role=menuitemradio]:after,
.plyr__menu__container .plyr__control[role=menuitemradio]:before {
    border-radius: 100%
}

.plyr__menu__container .plyr__control[role=menuitemradio]:before {
    background: rgba(0, 0, 0, .1);
    content: "";
    display: block;
    flex-shrink: 0;
    height: 16px;
    margin-right: var(--plyr-control-spacing, 10px);
    transition: all .3s ease;
    width: 16px
}

.plyr__menu__container .plyr__control[role=menuitemradio]:after {
    background: #fff;
    border: 0;
    height: 6px;
    left: 12px;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%) scale(0);
    transition: transform .3s ease, opacity .3s ease;
    width: 6px
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]:before {
    background: var(--plyr-control-toggle-checked-background, var(--plyr-color-main, #ff7a59))
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]:after {
    opacity: 1;
    transform: translateY(-50%) scale(1)
}

.plyr__menu__container .plyr__control[role=menuitemradio]:focus-visible:before,
.plyr__menu__container .plyr__control[role=menuitemradio]:hover:before {
    background: rgba(15, 23, 42, .1)
}

.plyr__menu__container .plyr__menu__value {
    align-items: center;
    display: flex;
    margin-left: auto;
    margin-right: calc(var(--plyr-control-spacing, 10px)*.7*-1 - -2px);
    overflow: hidden;
    padding-left: calc(var(--plyr-control-spacing, 10px)*.7*3.5);
    pointer-events: none
}

.plyr--full-ui input[type=range] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    border-radius: calc(var(--plyr-range-thumb-height, 13px)*2);
    color: var(--plyr-range-fill-background, var(--plyr-color-main, #ff7a59));
    display: block;
    height: calc(var(--plyr-range-thumb-active-shadow-width, 3px)*2 + var(--plyr-range-thumb-height, 13px));
    margin: 0;
    min-width: 0;
    padding: 0;
    transition: box-shadow .3s ease;
    width: 100%
}

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
    background: transparent;
    background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0));
    border: 0;
    border-radius: calc(var(--plyr-range-track-height, 5px)/2);
    height: var(--plyr-range-track-height, 5px);
    -webkit-transition: box-shadow .3s ease;
    transition: box-shadow .3s ease;
    -webkit-user-select: none;
    user-select: none
}

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: var(--plyr-range-thumb-background, #fff);
    border: 0;
    border-radius: 100%;
    box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(15, 23, 42, .15), 0 0 0 1px rgba(15, 23, 42, .2));
    height: var(--plyr-range-thumb-height, 13px);
    margin-top: calc((var(--plyr-range-thumb-height, 13px) - var(--plyr-range-track-height, 5px))/2*-1);
    position: relative;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    width: var(--plyr-range-thumb-height, 13px)
}

.plyr--full-ui input[type=range]::-moz-range-track {
    background: transparent;
    border: 0;
    border-radius: calc(var(--plyr-range-track-height, 5px)/2);
    height: var(--plyr-range-track-height, 5px);
    -moz-transition: box-shadow .3s ease;
    transition: box-shadow .3s ease;
    -moz-user-select: none;
    user-select: none
}

.plyr--full-ui input[type=range]::-moz-range-thumb {
    background: var(--plyr-range-thumb-background, #fff);
    border: 0;
    border-radius: 100%;
    box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(15, 23, 42, .15), 0 0 0 1px rgba(15, 23, 42, .2));
    height: var(--plyr-range-thumb-height, 13px);
    position: relative;
    -moz-transition: all .2s ease;
    transition: all .2s ease;
    width: var(--plyr-range-thumb-height, 13px)
}

.plyr--full-ui input[type=range]::-moz-range-progress {
    background: currentColor;
    border-radius: calc(var(--plyr-range-track-height, 5px)/2);
    height: var(--plyr-range-track-height, 5px)
}

.plyr--full-ui input[type=range]::-ms-track {
    color: transparent
}

.plyr--full-ui input[type=range]::-ms-fill-upper,
.plyr--full-ui input[type=range]::-ms-track {
    background: transparent;
    border: 0;
    border-radius: calc(var(--plyr-range-track-height, 5px)/2);
    height: var(--plyr-range-track-height, 5px);
    -ms-transition: box-shadow .3s ease;
    transition: box-shadow .3s ease;
    user-select: none
}

.plyr--full-ui input[type=range]::-ms-fill-lower {
    background: transparent;
    background: currentColor;
    border: 0;
    border-radius: calc(var(--plyr-range-track-height, 5px)/2);
    height: var(--plyr-range-track-height, 5px);
    -ms-transition: box-shadow .3s ease;
    transition: box-shadow .3s ease;
    user-select: none
}

.plyr--full-ui input[type=range]::-ms-thumb {
    background: var(--plyr-range-thumb-background, #fff);
    border: 0;
    border-radius: 100%;
    box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(15, 23, 42, .15), 0 0 0 1px rgba(15, 23, 42, .2));
    height: var(--plyr-range-thumb-height, 13px);
    margin-top: 0;
    position: relative;
    -ms-transition: all .2s ease;
    transition: all .2s ease;
    width: var(--plyr-range-thumb-height, 13px)
}

.plyr--full-ui input[type=range]::-ms-tooltip {
    display: none
}

.plyr--full-ui input[type=range]::-moz-focus-outer {
    border: 0
}

.plyr--full-ui input[type=range]:focus {
    outline: 0
}

.plyr--full-ui input[type=range]:focus-visible::-webkit-slider-runnable-track {
    outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, #ff7a59));
    outline-offset: 2px
}

.plyr--full-ui input[type=range]:focus-visible::-moz-range-track {
    outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, #ff7a59));
    outline-offset: 2px
}

.plyr--full-ui input[type=range]:focus-visible::-ms-track {
    outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, #ff7a59));
    outline-offset: 2px
}

.plyr__poster {
    background-color: var(--plyr-video-background, #fff);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity .2s ease;
    width: 100%;
    z-index: 1
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
    opacity: 1
}

.plyr--youtube.plyr--paused.plyr__poster-enabled:not(.plyr--stopped) .plyr__poster {
    display: none
}

.plyr__time {
    font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px))
}

.plyr__time+.plyr__time:before {
    content: "⁄";
    margin-right: var(--plyr-control-spacing, 10px)
}

@media (max-width:767px) {
    .plyr__time+.plyr__time {
        display: none
    }
}

.plyr__tooltip {
    background: var(--plyr-tooltip-background, #fff);
    border-radius: var(--plyr-tooltip-radius, 5px);
    bottom: 100%;
    box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px rgba(0, 0, 0, .15));
    color: var(--plyr-tooltip-color, #334155);
    font-size: var(--plyr-font-size-small, 13px);
    font-weight: var(--plyr-font-weight-regular, 400);
    left: 50%;
    line-height: 1.3;
    margin-bottom: calc(var(--plyr-control-spacing, 10px)/2*2);
    opacity: 0;
    padding: calc(var(--plyr-control-spacing, 10px)/2) calc(var(--plyr-control-spacing, 10px)/2*1.5);
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, 10px) scale(.8);
    transform-origin: 50% 100%;
    transition: transform .2s ease .1s, opacity .2s ease .1s;
    white-space: nowrap;
    z-index: 2
}

.plyr__tooltip:before {
    border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
    border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
    border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #fff);
    bottom: calc(var(--plyr-tooltip-arrow-size, 4px)*-1);
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
    z-index: 2
}

.plyr .plyr__control:focus-visible .plyr__tooltip,
.plyr .plyr__control:hover .plyr__tooltip,
.plyr__tooltip--visible {
    opacity: 1;
    transform: translate(-50%) scale(1)
}

.plyr .plyr__control:hover .plyr__tooltip {
    z-index: 3
}

.plyr__controls>.plyr__control:first-child .plyr__tooltip,
.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip {
    left: 0;
    transform: translateY(10px) scale(.8);
    transform-origin: 0 100%
}

.plyr__controls>.plyr__control:first-child .plyr__tooltip:before,
.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip:before {
    left: calc(var(--plyr-control-icon-size, 18px)/2 + var(--plyr-control-spacing, 10px)*.7)
}

.plyr__controls>.plyr__control:last-child .plyr__tooltip {
    left: auto;
    right: 0;
    transform: translateY(10px) scale(.8);
    transform-origin: 100% 100%
}

.plyr__controls>.plyr__control:last-child .plyr__tooltip:before {
    left: auto;
    right: calc(var(--plyr-control-icon-size, 18px)/2 + var(--plyr-control-spacing, 10px)*.7);
    transform: translateX(50%)
}

.plyr__controls>.plyr__control:first-child .plyr__tooltip--visible,
.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip--visible,
.plyr__controls>.plyr__control:first-child+.plyr__control:focus-visible .plyr__tooltip,
.plyr__controls>.plyr__control:first-child+.plyr__control:hover .plyr__tooltip,
.plyr__controls>.plyr__control:first-child:focus-visible .plyr__tooltip,
.plyr__controls>.plyr__control:first-child:hover .plyr__tooltip,
.plyr__controls>.plyr__control:last-child .plyr__tooltip--visible,
.plyr__controls>.plyr__control:last-child:focus-visible .plyr__tooltip,
.plyr__controls>.plyr__control:last-child:hover .plyr__tooltip {
    transform: translate(0) scale(1)
}

.plyr__progress {
    left: calc(var(--plyr-range-thumb-height, 13px)*.5);
    margin-right: var(--plyr-range-thumb-height, 13px);
    position: relative
}

.plyr__progress input[type=range],
.plyr__progress__buffer {
    margin-left: calc(var(--plyr-range-thumb-height, 13px)*-.5);
    margin-right: calc(var(--plyr-range-thumb-height, 13px)*-.5);
    width: calc(100% + var(--plyr-range-thumb-height, 13px))
}

.plyr__progress input[type=range] {
    position: relative;
    z-index: 2
}

.plyr__progress .plyr__tooltip {
    left: 0;
    max-width: 120px;
    overflow-wrap: break-word
}

.plyr__progress__buffer {
    -webkit-appearance: none;
    background: transparent;
    border: 0;
    border-radius: 100px;
    height: var(--plyr-range-track-height, 5px);
    left: 0;
    margin-top: calc((var(--plyr-range-track-height, 5px)/2)*-1);
    padding: 0;
    position: absolute;
    top: 50%
}

.plyr__progress__buffer::-webkit-progress-bar {
    background: transparent
}

.plyr__progress__buffer::-webkit-progress-value {
    background: currentColor;
    border-radius: 100px;
    min-width: var(--plyr-range-track-height, 5px);
    -webkit-transition: width .2s ease;
    transition: width .2s ease
}

.plyr__progress__buffer::-moz-progress-bar {
    background: currentColor;
    border-radius: 100px;
    min-width: var(--plyr-range-track-height, 5px);
    -moz-transition: width .2s ease;
    transition: width .2s ease
}

.plyr__progress__buffer::-ms-fill {
    border-radius: 100px;
    -ms-transition: width .2s ease;
    transition: width .2s ease
}

.plyr--loading .plyr__progress__buffer {
    animation: plyr-progress 1s linear infinite;
    background-image: linear-gradient(-45deg, var(--plyr-progress-loading-background, rgba(15, 23, 42, .6)) 25%, transparent 25%, transparent 50%, var(--plyr-progress-loading-background, rgba(15, 23, 42, .6)) 50%, var(--plyr-progress-loading-background, rgba(15, 23, 42, .6)) 75%, transparent 75%, transparent);
    background-repeat: repeat-x;
    background-size: var(--plyr-progress-loading-size, 25px) var(--plyr-progress-loading-size, 25px);
    color: transparent
}

.plyr--video.plyr--loading .plyr__progress__buffer {
    background-color: var(--plyr-video-progress-buffered-background, hsla(0, 0%, 100%, .25))
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
    background-color: var(--plyr-audio-progress-buffered-background, rgba(226, 232, 240, .6))
}

.plyr__progress__marker {
    background-color: var(--plyr-progress-marker-background, #fff);
    border-radius: 1px;
    height: var(--plyr-range-track-height, 5px);
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: var(--plyr-progress-marker-width, 3px);
    z-index: 3
}

.plyr__volume {
    align-items: center;
    display: flex;
    position: relative
}

.plyr__volume input[type=range] {
    margin-left: calc(var(--plyr-control-spacing, 10px)/2);
    margin-right: calc(var(--plyr-control-spacing, 10px)/2);
    max-width: 90px;
    min-width: 60px;
    position: relative;
    z-index: 2
}

.plyr--audio {
    display: block
}

.plyr--audio .plyr__controls {
    background: var(--plyr-audio-controls-background, #fff);
    border-radius: inherit;
    color: var(--plyr-audio-control-color, #334155);
    padding: var(--plyr-control-spacing, 10px)
}

.plyr--audio .plyr__control:focus-visible,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded=true] {
    background: var(--plyr-audio-control-background-hover, var(--plyr-color-main, #ff7a59));
    color: var(--plyr-audio-control-color-hover, #fff)
}

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
    background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(226, 232, 240, .6)))
}

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
    background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(226, 232, 240, .6)))
}

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
    background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(226, 232, 240, .6)))
}

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
    box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(15, 23, 42, .15), 0 0 0 1px rgba(15, 23, 42, .2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(15, 23, 42, .1))
}

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
    box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(15, 23, 42, .15), 0 0 0 1px rgba(15, 23, 42, .2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(15, 23, 42, .1))
}

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
    box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(15, 23, 42, .15), 0 0 0 1px rgba(15, 23, 42, .2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(15, 23, 42, .1))
}

.plyr--audio .plyr__progress__buffer {
    color: var(--plyr-audio-progress-buffered-background, rgba(226, 232, 240, .6))
}

.plyr--video {
    overflow: hidden
}

.plyr--video.plyr--menu-open {
    overflow: visible
}

.plyr__video-wrapper {
    background: var(--plyr-video-background, #fff);
    border-radius: inherit;
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%
}

.plyr__video-embed,
.plyr__video-wrapper--fixed-ratio {
    aspect-ratio: 16/9
}

@supports not (aspect-ratio:16/9) {

    .plyr__video-embed,
    .plyr__video-wrapper--fixed-ratio {
        height: 0;
        padding-bottom: 56.25%;
        position: relative
    }
}

.plyr__video-embed iframe,
.plyr__video-wrapper--fixed-ratio video {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.plyr--full-ui .plyr__video-embed>.plyr__video-embed__container {
    padding-bottom: 240%;
    position: relative;
    transform: translateY(-38.28125%)
}

.plyr--video .plyr__controls {
    background: var(--plyr-video-controls-background, linear-gradient(transparent, rgba(0, 0, 0, .75)));
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    bottom: 0;
    color: var(--plyr-video-control-color, #fff);
    left: 0;
    padding: calc(var(--plyr-control-spacing, 10px)/2);
    padding-top: calc(var(--plyr-control-spacing, 10px)*2);
    position: absolute;
    right: 0;
    transition: opacity .4s ease-in-out, transform .4s ease-in-out;
    z-index: 3
}

@media (min-width:480px) {
    .plyr--video .plyr__controls {
        padding: var(--plyr-control-spacing, 10px);
        padding-top: calc(var(--plyr-control-spacing, 10px)*3.5)
    }
}

.plyr--video.plyr--hide-controls .plyr__controls {
    opacity: 0;
    pointer-events: none;
    transform: translateY(100%)
}

.plyr--video .plyr__control:focus-visible,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
    background: var(--plyr-video-control-background-hover, var(--plyr-color-main, #ff7a59));
    color: var(--plyr-video-control-color-hover, #fff)
}

.plyr__control--overlaid {
    background: var(--plyr-video-control-background-hover, var(--plyr-color-main, #ff7a59));
    border: 0;
    border-radius: 100%;
    color: var(--plyr-video-control-color, #fff);
    display: none;
    left: 50%;
    opacity: .9;
    padding: calc(var(--plyr-control-spacing, 10px)*1.5);
    top: 50%;
    transform: translate(-50%, -50%);
    transition: .3s;
    z-index: 2
}

.plyr__control--overlaid svg {
    left: 2px;
    position: relative
}

.plyr__control--overlaid:focus,
.plyr__control--overlaid:hover {
    opacity: 1
}

.plyr--playing .plyr__control--overlaid {
    opacity: 0;
    visibility: hidden
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
    display: block
}

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
    background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, hsla(0, 0%, 100%, .25)))
}

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
    background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, hsla(0, 0%, 100%, .25)))
}

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
    background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, hsla(0, 0%, 100%, .25)))
}

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
    box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(15, 23, 42, .15), 0 0 0 1px rgba(15, 23, 42, .2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, hsla(0, 0%, 100%, .5))
}

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
    box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(15, 23, 42, .15), 0 0 0 1px rgba(15, 23, 42, .2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, hsla(0, 0%, 100%, .5))
}

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
    box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(15, 23, 42, .15), 0 0 0 1px rgba(15, 23, 42, .2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, hsla(0, 0%, 100%, .5))
}

.plyr--video .plyr__progress__buffer {
    color: var(--plyr-video-progress-buffered-background, hsla(0, 0%, 100%, .25))
}

.plyr:-webkit-full-screen {
    background: #000;
    border-radius: 0 !important;
    height: 100%;
    margin: 0;
    width: 100%
}

.plyr:fullscreen {
    background: #000;
    border-radius: 0 !important;
    height: 100%;
    margin: 0;
    width: 100%
}

.plyr:-webkit-full-screen video {
    height: 100%
}

.plyr:fullscreen video {
    height: 100%
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
    display: block
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
    display: block
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen+svg {
    display: none
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen+svg {
    display: none
}

.plyr:-webkit-full-screen.plyr--hide-controls {
    cursor: none
}

.plyr:fullscreen.plyr--hide-controls {
    cursor: none
}

@media (min-width:1024px) {
    .plyr:-webkit-full-screen .plyr__captions {
        font-size: var(--plyr-font-size-xlarge, 21px)
    }

    .plyr:fullscreen .plyr__captions {
        font-size: var(--plyr-font-size-xlarge, 21px)
    }
}

.plyr--fullscreen-fallback {
    background: #000;
    border-radius: 0 !important;
    bottom: 0;
    height: 100%;
    left: 0;
    margin: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10000000
}

.plyr--fullscreen-fallback video {
    height: 100%
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
    display: block
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen+svg {
    display: none
}

.plyr--fullscreen-fallback.plyr--hide-controls {
    cursor: none
}

@media (min-width:1024px) {
    .plyr--fullscreen-fallback .plyr__captions {
        font-size: var(--plyr-font-size-xlarge, 21px)
    }
}

.plyr__ads {
    border-radius: inherit;
    bottom: 0;
    cursor: pointer;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1
}

.plyr__ads>div,
.plyr__ads>div iframe {
    height: 100%;
    position: absolute;
    width: 100%
}

.plyr__ads:after {
    background: #0f172a;
    border-radius: 2px;
    bottom: var(--plyr-control-spacing, 10px);
    color: #fff;
    content: attr(data-badge-text);
    font-size: 11px;
    padding: 2px 6px;
    pointer-events: none;
    position: absolute;
    right: var(--plyr-control-spacing, 10px);
    z-index: 3
}

.plyr__ads:empty:after {
    display: none
}

.plyr__cues {
    background: currentColor;
    display: block;
    height: var(--plyr-range-track-height, 5px);
    left: 0;
    opacity: .8;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    z-index: 3
}

.plyr__preview-thumb {
    background-color: var(--plyr-tooltip-background, #fff);
    border-radius: var(--plyr-menu-radius, 8px);
    bottom: 100%;
    box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px rgba(0, 0, 0, .15));
    margin-bottom: calc(var(--plyr-control-spacing, 10px)/2*2);
    opacity: 0;
    padding: 3px;
    pointer-events: none;
    position: absolute;
    transform: translateY(10px) scale(.8);
    transform-origin: 50% 100%;
    transition: transform .2s ease .1s, opacity .2s ease .1s;
    z-index: 2
}

.plyr__preview-thumb--is-shown {
    opacity: 1;
    transform: translate(0) scale(1)
}

.plyr__preview-thumb:before {
    border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
    border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
    border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #fff);
    bottom: calc(var(--plyr-tooltip-arrow-size, 4px)*-1);
    content: "";
    height: 0;
    left: calc(50% + var(--preview-arrow-offset));
    position: absolute;
    transform: translateX(-50%);
    width: 0;
    z-index: 2
}

.plyr__preview-thumb__image-container {
    background: #e2e8f0;
    border-radius: calc(var(--plyr-menu-radius, 8px) - 1px);
    overflow: hidden;
    position: relative;
    z-index: 0
}

.plyr__preview-thumb__image-container img,
.plyr__preview-thumb__image-container:after {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.plyr__preview-thumb__image-container:after {
    border-radius: inherit;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .15);
    content: "";
    pointer-events: none
}

.plyr__preview-thumb__image-container img {
    max-height: none;
    max-width: none
}

.plyr__preview-thumb__time-container {
    background: var(--plyr-video-controls-background, linear-gradient(transparent, rgba(0, 0, 0, .75)));
    border-bottom-left-radius: calc(var(--plyr-menu-radius, 8px) - 1px);
    border-bottom-right-radius: calc(var(--plyr-menu-radius, 8px) - 1px);
    bottom: 0;
    left: 0;
    line-height: 1.1;
    padding: 20px 6px 6px;
    position: absolute;
    right: 0;
    z-index: 3
}

.plyr__preview-thumb__time-container span {
    color: #fff;
    font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px))
}

.plyr__preview-scrubbing {
    bottom: 0;
    filter: blur(1px);
    height: 100%;
    left: 0;
    margin: auto;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .3s ease;
    width: 100%;
    z-index: 1
}

.plyr__preview-scrubbing--is-shown {
    opacity: 1
}

.plyr__preview-scrubbing img {
    height: 100%;
    left: 0;
    max-height: none;
    max-width: none;
    -o-object-fit: contain;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%
}

.plyr--no-transition {
    transition: none !important
}

.plyr__sr-only {
    clip: rect(1px, 1px, 1px, 1px);
    border: 0 !important;
    height: 1px !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important
}

.plyr [hidden] {
    display: none !important
}

.plyr__control--overlaid {
    font-size: 24px;
    line-height: 32px;
    position: relative;
    position: absolute
}

.plyr__control--overlaid:before {
    animation-delay: 0s;
    animation: pulsate1 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .3);
    content: "";
    height: 150%;
    left: -12px;
    opacity: 1;
    position: absolute;
    top: -12px;
    width: 150%
}

.plyr__poster {
    background-size: cover
}

.plyr__video-wrapper {
    border: 2px solid #e2e8f0;
    border-radius: .75rem
}

.plyr--video .plyr__controls {
    background-color: #1e293b;
    border-radius: 0 0 .75rem .75rem;
    opacity: .75;
    padding-top: .75rem
}

[data-bs-theme=dark] {
    --bs-gray-100: #1e293b;
    --bs-gray-200: #334155;
    --bs-gray-800: #cbd5e1;
    --bs-light: #1e293b;
    --bs-dark: #fff;
    --bs-white: #1e293b;
    --bs-nav-pills-link-active-color: #475569;
    --bs-nav-pills-link-active-bg: #1e293b
}

[data-bs-theme=dark] .btn-light {
    --bs-btn-color: #fff;
    --bs-btn-bg: #1e293b;
    --bs-btn-border-color: #1e293b;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #1a2332;
    --bs-btn-hover-border-color: #18212f;
    --bs-btn-focus-shadow-rgb: 64, 73, 88;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #18212f;
    --bs-btn-active-border-color: #171f2c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #1e293b;
    --bs-btn-disabled-border-color: #1e293b
}

[data-bs-theme=dark] .btn-dark {
    --bs-btn-color: #020617;
    --bs-btn-bg: #fff;
    --bs-btn-border-color: #fff;
    --bs-btn-hover-color: #020617;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #fff;
    --bs-btn-focus-shadow-rgb: 217, 218, 220;
    --bs-btn-active-color: #020617;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #fff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #020617;
    --bs-btn-disabled-bg: #fff;
    --bs-btn-disabled-border-color: #fff
}

[data-bs-theme=dark] .btn-white {
    --bs-btn-color: #fff;
    --bs-btn-bg: #1e293b;
    --bs-btn-border-color: #1e293b;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #1a2332;
    --bs-btn-hover-border-color: #18212f;
    --bs-btn-focus-shadow-rgb: 64, 73, 88;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #18212f;
    --bs-btn-active-border-color: #171f2c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #1e293b;
    --bs-btn-disabled-border-color: #1e293b
}

[data-bs-theme=dark] .btn-outline-light {
    --bs-btn-color: #1e293b;
    --bs-btn-border-color: #1e293b;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #1e293b;
    --bs-btn-hover-border-color: #1e293b;
    --bs-btn-focus-shadow-rgb: 30, 41, 59;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #1e293b;
    --bs-btn-active-border-color: #1e293b;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #1e293b;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #1e293b;
    --bs-gradient: none
}

[data-bs-theme=dark] .btn-outline-dark {
    --bs-btn-color: #fff;
    --bs-btn-border-color: #fff;
    --bs-btn-hover-color: #020617;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #fff;
    --bs-btn-focus-shadow-rgb: 255, 255, 255;
    --bs-btn-active-color: #020617;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #fff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #fff;
    --bs-gradient: none
}

[data-bs-theme=dark] .btn-outline-white {
    --bs-btn-color: #1e293b;
    --bs-btn-border-color: #1e293b;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #1e293b;
    --bs-btn-hover-border-color: #1e293b;
    --bs-btn-focus-shadow-rgb: 30, 41, 59;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #1e293b;
    --bs-btn-active-border-color: #1e293b;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, .125);
    --bs-btn-disabled-color: #1e293b;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #1e293b;
    --bs-gradient: none
}

[data-bs-theme=dark] .text-inherit {
    color: #475569
}

[data-bs-theme=dark] .text-inherit:hover {
    color: var(--bs-primary) !important
}

[data-bs-theme=dark] .blockquote {
    background-color: var(--bs-gray-900)
}

[data-bs-theme=dark] .bg-light,
[data-bs-theme=dark] .text-bg-light {
    background-color: rgba(30, 41, 59, var(--bs-bg-opacity, 1)) !important;
    color: #fff !important
}

[data-bs-theme=dark] .text-light {
    color: #1e293b !important
}

[data-bs-theme=dark] .bg-dark,
[data-bs-theme=dark] .text-bg-dark {
    background-color: rgba(255, 255, 255, var(--bs-bg-opacity, 1)) !important;
    color: #020617 !important
}

[data-bs-theme=dark] .text-dark {
    color: #fff !important
}

[data-bs-theme=dark] .bg-white,
[data-bs-theme=dark] .text-bg-white {
    background-color: rgba(30, 41, 59, var(--bs-bg-opacity, 1)) !important;
    color: #fff !important
}

[data-bs-theme=dark] .text-white {
    color: #1e293b !important
}

[data-bs-theme=dark] .bg-gray-100 {
    background-color: #1e293b !important
}

[data-bs-theme=dark] .text-gray-100 {
    color: #1e293b !important
}

[data-bs-theme=dark] .bg-gray-200 {
    background-color: #334155 !important
}

[data-bs-theme=dark] .text-gray-200 {
    color: #334155 !important
}

[data-bs-theme=dark] .bg-gray-800 {
    background-color: #cbd5e1 !important
}

[data-bs-theme=dark] .text-gray-800 {
    color: #cbd5e1 !important
}

[data-bs-theme=dark] .navbar-brand,
[data-bs-theme=dark] .text-inverse {
    filter: brightness(0) invert(1)
}

[data-bs-theme=dark] .btn-light {
    --bs-btn-color: #94a3b8;
    --bs-btn-bg: #334155;
    --bs-btn-border-color: #334155;
    --bs-btn-hover-color: #cbd5e1;
    --bs-btn-hover-bg: #475569;
    --bs-btn-hover-border-color: #475569;
    --bs-btn-active-color: #cbd5e1;
    --bs-btn-active-bg: #475569;
    --bs-btn-active-border-color: #475569;
    --bs-btn-disabled-color: #334155;
    --bs-btn-disabled-bg: #f1f5f9;
    --bs-btn-disabled-border-color: #f1f5f9
}

@media (max-width:990px) {
    [data-bs-theme=dark] .navbar-transparent.navbar-clone .navbar-brand {
        filter: brightness(1) invert(0)
    }

    [data-bs-theme=dark] .navbar-transparent.navbar-clone .navbar-toggler {
        border-color: #fff;
        color: #fff
    }

    [data-bs-theme=dark] .navbar-transparent.navbar-stick .navbar-brand {
        filter: brightness(0) invert(1)
    }
}

[data-bs-theme=dark] .table-light {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: var(--bs-light);
    --bs-table-border-color: currentcolor
}