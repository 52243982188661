@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@layer base {
  html {
    font-family: "Poppins", sans-serif;
  }
}

@layer components {
  .main-container {
    @apply w-full px-2 md:px-4;
  }
}

body {
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-primary-container {
  background: url("../src/assets/images/bg.svg") 50rem -24rem no-repeat;
  background-size: auto;
}

.about-section-bg, .moreinfo-section-bg {
  background: url("../src/assets/images/new\ wave.jpeg") 0 no-repeat;
  background-size: cover;
}

#hubspot-form {
  max-width: 80rem;
  padding: 0 0.5rem;
  margin: auto;
  margin-top: 3rem;
}
ol{
  list-style-type: disc;
}
@media (max-width: 1320px) {
  .bg-primary-container {
    background-position: right -20rem top -8rem;
    background-size: cover;
  }
}

@media (max-width: 640px) {
  .bg-primary-container {
    background-position: left -5rem top -10rem;
    background-size: cover;
  }
}



.nav-link:hover,.nav-item a:hover{
  color:rgb(255 122 89 / var(--tw-border-opacity)) !important;
  }